import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import '../BetSlip.scss';

const SingleBetWagerInput = ({ bet, pickState, setPickState, bankrollExceeded }) => {
    console.log("BET IN INPUT", bet);
    const juice = bet?.juice;
    const [wagerAmount, setWagerAmount] = useState('');
    const [toWinAmount, setToWinAmount] = useState('');

    useEffect(() => {
        if (!bet?.betId) return; // Add this guard clause to prevent unnecessary execution
        const currentBet = pickState?.find((b) => b.betId === bet?.betId);
        if (currentBet) {
            setWagerAmount(Number(currentBet?.wager) || '');
            setToWinAmount(Number(currentBet?.toWin) || '');
        }
    }, [bet.betId, pickState]);
    

   // Handle wager input changes and update toWinAmount in real-time
    const handleWagerChange = (event) => {
        let wagerValue = event.target.value;
        console.log("wagerValue!!!", wagerValue);

        setWagerAmount(wagerValue); // Store value as string to keep trailing zeros

        // Calculate payout and update state if the input is a valid number and doesn't end with a period
        if (wagerValue !== '' && !isNaN(wagerValue) && !wagerValue.endsWith('.')) {
            const payout = calculatePayout(juice, parseFloat(wagerValue));
            console.log("Payout!!!", payout);
            setToWinAmount(payout.toFixed(2)); // Format the payout amount
            updateBetInState(Number(wagerValue).toFixed(2), payout);
        } else if (wagerValue === '') {
            setToWinAmount('');
            updateBetInState(0, 0);
        }
        
    };



    // Handle toWin input changes and update wagerAmount in real-time
    const handleToWinChange = (event) => {
        let toWinValue = event.target.value;
        console.log("toWinValue!!!", toWinValue);
    
        setToWinAmount(toWinValue); // Keep the input as a string to preserve trailing zeros
    
        // Calculate wager if input is a valid number and doesn't end with a period
        if (toWinValue !== '' && !isNaN(toWinValue) && !toWinValue.endsWith('.')) {
            const requiredWager = calculateWager(juice, parseFloat(toWinValue));
            console.log("requiredWager!!!", requiredWager);
            setWagerAmount(requiredWager.toFixed(2)); // Format the wager amount
            updateBetInState(requiredWager.toFixed(2), parseFloat(toWinValue).toFixed(2)); // Use the updated requiredWager
        } else if (toWinValue === '') {
            setWagerAmount('');
            updateBetInState(0, 0);
        }
    };
    

    // Format the input values to include two decimal places only if needed, or retain trailing zeros on blur
    const handleBlur = (field) => {
        if (field === 'wager') {
            setWagerAmount((prev) => formatDecimalOnBlur(prev));
        }
        if (field === 'toWin') {
            setToWinAmount((prev) => formatDecimalOnBlur(prev));
        }
    };

    const formatDecimalOnBlur = (value) => {
        console.log("value!!!", value);
        
        // Convert the value to a string to safely use endsWith
        const stringValue = String(value);
    
        if (stringValue === '' || stringValue.endsWith('.')) return stringValue; 
        
        const numValue = parseFloat(value);
        if (isNaN(numValue)) return ''; // Handle invalid numbers
        
        return numValue.toFixed(2); 
    };
    
    

    // Format decimal to always show two decimal places
    const formatDecimal = (value) => {
        const numValue = parseFloat(value);
        if (!isNaN(numValue)) {
            return numValue.toFixed(2); // Ensure always 2 decimal places
        }
        return '';
    };

    const updateBetInState = (wager, toWin) => {
        setPickState((prevPickState) =>
            prevPickState.map((existingBet) => {
                if (existingBet.betId === bet.betId) {
                    return {
                        ...existingBet,
                        wager: Number(wager).toFixed(2),
                        toWin: Number(toWin).toFixed(2),
                    };
                }
                return existingBet;
            })
        );
    };

    const calculatePayout = (juice, wager) => {
        let payout;
        if (juice > 0) {
            payout = (wager * juice) / 100;
        } else {
            payout = (wager * 100) / Math.abs(juice);
        }
        return payout;
    };

    const calculateWager = (juice, desiredProfit) => {
        let wager;
        if (juice > 0) {
            wager = (desiredProfit * 100) / juice;
        } else {
            wager = (desiredProfit * Math.abs(juice)) / 100;
        }
        return wager;
    };

    return (
        <Box className="sportsbook-wager-boxes-container">
            {/* Wager Input */}
            <Box className={`sportsbook-wager-box ${bankrollExceeded ? 'sportsbook-wager-box-exceeded' : ''}`}>
                <Typography className={`sportsbook-wager-box-label ${bankrollExceeded ? 'sportsbook-wager-box-label-exceeded' : ''}`}>
                    {!bankrollExceeded ? 'Wager' : 'Insufficient Funds'}
                </Typography>
                <TextField
                    variant="outlined"
                    type="number" // Keep as text to handle custom formatting
                    size="small"
                    value={wagerAmount}
                    onChange={handleWagerChange}
                    onBlur={() => handleBlur('wager')}
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <Typography className="sportsbook-wager-currency">
                                $
                            </Typography>
                        ),
                    }}
                    className="sportsbook-wager-input"
                />
            </Box>

            {/* To Win Input */}
            <Box className={`sportsbook-wager-box ${bankrollExceeded ? 'sportsbook-wager-box-exceeded' : ''}`}>
                <Typography className={`sportsbook-wager-box-label ${bankrollExceeded ? 'sportsbook-wager-box-label-exceeded' : ''}`}>
                    To Win
                </Typography>
                <TextField
                    variant="outlined"
                    type="number" // Keep as text to handle custom formatting
                    size="small"
                    value={toWinAmount}
                    onChange={handleToWinChange}
                    onBlur={() => handleBlur('toWin')}
                    autoComplete="off"
                    InputProps={{
                        startAdornment: (
                            <Typography className="sportsbook-wager-currency">
                                $
                            </Typography>
                        ),
                    }}
                    className="sportsbook-wager-input"
                />
            </Box>
        </Box>
    );
};

export default SingleBetWagerInput;
