import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNFLTeams } from '../../../hooks/nfl';
import CloseIcon from '@mui/icons-material/Close';

const NFLSurvivorDisplay = ({ gameName, partyInfo, league, gameType, logo, currentWeek, userPicks }) => {

    const { mutate: fetchNFLTeams, data: NFLTeams, isLoading: NFLTeamsLoading, isError: NFLTeamsError, error: NFLTeamsErrorData } = useNFLTeams();

    useEffect(() => {
        if (league === "NFL") {
            fetchNFLTeams();
        }
    }, [fetchNFLTeams, league]);


    const doesUserHaveALoss = userPicks?.picks?.some((pick) => pick.result === "Loss");  
    let lossWeek = null;
    let losingGame = null;
    if (doesUserHaveALoss) {
        losingGame = userPicks?.picks.find(pick => pick.result === "Loss");
        lossWeek = losingGame?.week;
    }
    const currentWeekPick = userPicks?.picks?.find(pick => pick.week === currentWeek); 
    //console.log("Current Week Pick", currentWeekPick);
    const currentWeekResult = currentWeekPick?.result;
    //console.log(currentWeekResult);
    const currentPickLogo = NFLTeams?.find((team) => team.GlobalTeamID === currentWeekPick?.teamId)?.WikipediaLogoURL;
    const losingPickLogo = NFLTeams?.find((team) => team.GlobalTeamID === losingGame?.teamId)?.WikipediaLogoURL;

    
    


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'stretch',
            maxWidth: '1102px',
            margin: '0 auto',
            width:'90%',
            gap: isMobile ? '32px' : '48px'
          }}>
            {isMobile ? (
              // These items will be displayed on mobile devices
              <>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4'>MY STATUS</Typography>
                    {!doesUserHaveALoss ? (
                    <Typography className={"pick-em-header-values"} variant='h3' sx={{ color: '#00AA72', fontWeight: '800', marginTop: '5px'}}>ALIVE</Typography>
                    ) : (
                    <Typography className={"pick-em-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '800', marginTop: '9px'}}>ELIMINATED</Typography>
                    )}
                  </Box>
                {!doesUserHaveALoss && (
                        <Box>
                            <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {currentWeek} PICK</Typography>
                            <Box className="pick-em-header-values">
                                {currentWeekPick ? (
                                    <img 
                                        src={currentPickLogo}
                                        alt="Team Logo" 
                                        style={{ width: '24px', height: '24px', margin: '0 auto', marginTop: '1px', border: currentWeekResult === "Win" ? '1px solid #00AA72' : '', backgroundColor: currentWeekResult === "Win" ? '#CCEEE3' : ''}} 
                                    />
                                ) : (
                                    <Typography className={"pick-em-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '800', marginTop: '5px'}}>NO PICK</Typography>
                                )}
                            </Box>
                        </Box>
                    )}
                    {doesUserHaveALoss && (
                        <Box>
                        <Typography className={"pick-em-header-headings"} variant='h4'>ELIMINATION</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '5px' }}>
                          <Typography className={"pick-em-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '800', marginRight: '8px' }}>
                            WEEK {lossWeek}
                          </Typography>
                          {losingPickLogo ? (
                            <Box
                              sx={{
                                border: '1px solid #CC293C',
                                backgroundColor: 'rgba(204, 41, 60, 0.20)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '24px',
                                height: '24px'
                              }}
                            >
                              <img 
                                src={losingPickLogo} 
                                alt="Losing Team Logo" 
                                style={{ width: '24px', height: '24px' }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                border: '1px solid #CC293C',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '24px',
                                height: '24px'
                              }}
                            >
                              <CloseIcon sx={{ fontSize: '24px', color: '#CC293C' }} />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      
                    )}
              </>
            ) : (
              // These items will be displayed on desktop devices
              <>
                <Box>
                    <Typography className={"pick-em-header-headings"} variant='h4'>MY STATUS</Typography>
                    {!doesUserHaveALoss ? (
                    <Typography className={"pick-em-header-values"} variant='h4' style={{ color: '#00AA72', fontWeight: '700', marginTop: '9px'}}>ALIVE</Typography>
                    ) : (
                    <Typography className={"pick-em-header-values"} variant='h4' style={{color: '#CC293C', fontWeight: '700', marginTop: '9px'}}>ELIMINATED</Typography>
                    )}
                  </Box>
                {!doesUserHaveALoss && (
                        <Box>
                            <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {currentWeek} PICK</Typography>
                            <Box className="pick-em-header-values">
                                {currentWeekPick ? (
                                    <img 
                                        src={currentPickLogo}
                                        alt="Team Logo" 
                                        style={{ width: '44px', height: '44px', margin: '0 auto'}} 
                                    />
                                ) : (
                                    <Typography className={"pick-em-header-values"} variant='h4' style={{ color: '#CC293C', marginTop: '9px', fontWeight: '700'}}>NO PICK</Typography>
                                )}

                        </Box>
                        </Box>
                    )}
                    {doesUserHaveALoss && (
                        <Box>
                        <Typography className={"pick-em-header-headings"} variant='h4'>ELIMINATION</Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '4px' }}>
                          <Typography className={"pick-em-header-values"} variant='h3' sx={{ color: '#CC293C', fontWeight: '700', marginRight: '8px' }}>
                            WEEK {lossWeek}
                          </Typography>
                          {losingPickLogo ? (
                            <Box
                              sx={{
                                border: '1px solid #CC293C',
                                backgroundColor: 'rgba(204, 41, 60, 0.20)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '32px',
                                height: '32px'
                              }}
                            >
                              <img 
                                src={losingPickLogo} 
                                alt="Losing Team Logo" 
                                style={{ width: '32px', height: '32px' }}
                              />
                            </Box>
                          ) : (
                            <Box
                              sx={{
                                border: '1px solid #CC293C',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: '32px',
                                height: '32px'
                              }}
                            >
                              <CloseIcon sx={{ fontSize: '32px', color: '#CC293C' }} />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      
                    )}
              </>
            )}
          </Box>
    );

}

export default NFLSurvivorDisplay;