import React, { useState } from "react";
import { Box, Modal, Button } from '@mui/material';
import '../NFLPickEmStyles/PickEmNav.scss';
import { useNFLPickEmContext } from "../context/NFLPickEmContext";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import PicksNotSaved from "../../PicksNotSaved/PicksNotSaved";


const PickEmNav = (props) => {
    const { clickState, setClickState, setSignUpState, doesUserBelong } = props;
    const {
        pickEmState,
        setPickEmState,
    } = useNFLPickEmContext();

    const [pickNotSavedModal, setPickNotSavedModal] = useState(false);
    const [pendingPage, setPendingPage] = useState(null);

    const handleNavBarClick = (page) => {
        if (pickEmState.length === 0) {
            setClickState(page);
            if (page !== 'MY_PARTY') {
                setSignUpState(false);
            }
        } else {
            setPickNotSavedModal(true);
            setPendingPage(page);
        }
    }



    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <>
            <Box className={"bracket-nav"} style={{ margin: '0 auto'}}>
                <Box className={"bracket-nav-container"} style={{ maxWidth: doesUserBelong ? '960px' : isMobile ? '300px' : '650px' }}>
                    {doesUserBelong && (
                    <Box
                        className={`bracket-nav-item ${clickState === 'MY_PICKS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('MY_PICKS');}}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap', color: clickState === 'MY_PICKS' ? '#FFC60A' : 'white' }}
                    >
                        My Picks
                    </Box>
                    )}
                    <Box
                        className={`bracket-nav-item ${clickState === 'LEADERBOARD' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('LEADERBOARD'); }}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap', color: clickState === 'LEADERBOARD' ? '#FFC60A' : 'white' }}
                    >
                        Leaderboard
                    </Box>
                    <Box
                        className={`bracket-nav-item ${clickState === 'POOL_PICKS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('POOL_PICKS'); }}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap',  color: clickState === 'POOL_PICKS' ? '#FFC60A' : 'white' }}
                    >
                        Pool Picks
                    </Box>
                    <Box
                        className={`bracket-nav-item ${clickState === 'TEAM_STATS' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('TEAM_STATS'); }}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap',  color: clickState === 'TEAM_STATS' ? '#FFC60A' : 'white' }}
                    >
                        Team Stats
                    </Box>
                    {doesUserBelong && (
                    <Box
                        className={`bracket-nav-item ${clickState === 'MY_PARTY' ? 'active' : ''}`}
                        onClick={() => { handleNavBarClick('MY_PARTY'); }}
                        style={{ cursor: 'pointer', whiteSpace: 'nowrap', color: clickState === 'MY_PARTY' ? '#FFC60A' : 'white' }}
                    >
                        My Party
                    </Box>
                    )}
                </Box>
            </Box>
            <Modal
                open={pickNotSavedModal}
                onClose={() => setPickNotSavedModal(false)}
                aria-labelledby="picks-not-saved-modal-title"
                aria-describedby="picks-not-saved-modal-description"
            >
                <Box>
                    <PicksNotSaved>
                        <Box sx={{ display: 'flex', gap: '10px' }}>
                            <Button
                                variant="contained"
                                onClick={() => setPickNotSavedModal(false)}
                            >
                                GO BACK
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => {
                                    setPickEmState([]);
                                    setClickState(pendingPage);
                                    setPickNotSavedModal(false);
                                }}
                            >
                                LEAVE PAGE
                            </Button>
                        </Box>
                    </PicksNotSaved>
                </Box>
            </Modal>
        </>
    );
}

export default PickEmNav;
