import { useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import StarIcon from "@mui/icons-material/Star";
import homeBanner from '../assets/images/hero_background.png';
import trophyImage from '../assets/images/Layer_1.svg';
import bannerContent from '../assets/images/Hero_Contents_Desktop.png';
import { useTheme } from '@mui/material/styles';
import mobileBannerContent from '../assets/images/nba_super_picke_em_hero_text.svg'
import poolPartyLogoStacked from '../assets/images/pool_party_gaming_stacked_logo.svg';
import { Link } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { getUserDataFromLocalStorage } from "../redux/actions/loginImport";
import { useGetUserData } from "../components/BirdiePool/hooks";
import useNavigateToTop from "../hooks/general/useNavigateToTop";
import NFLPickEmForHomePage from "../components/HomePageGames/NFLPickEmForHomePage";
import NFLSurvivorForHomePage from "../components/HomePageGames/NFLSurvivorForHomePage";
import CFBSurvivorForHomePage from "../components/HomePageGames/CFBSurvivorForHomePage";
import MikeAndTonysForHomePage from "../components/HomePageGames/MikeAndTonysForHomePage";

export default function HomePage() {
  const userData = getUserDataFromLocalStorage();
  const userDataId = getUserDataFromLocalStorage()?.user_id;
  const { mutate: fetchUserData, data: userGamesData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
  //console.log("UserGames Data:", userGamesData);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  useEffect(() => {
    if (userDataId) {
        fetchUserData(userDataId);
    }
}, [fetchUserData, userDataId]);

const gameIDToFind = '5cfa584e-36c0-4b3f-a9ca-0fe45228295a';
const inBirdiePool = userGamesData?.Games?.find(game => game.gameID === gameIDToFind);
//console.log("In Birdie Pool??", inBirdiePool);
  const descriptionStyles = {
    color: 'var(--color-text)',
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '170%',
    letterSpacing: '-0.266px',
    mt: 2,
  };
  const homeBtnStyle = {
    padding: '1rem 1.5rem',
    height: '3.2rem',
  };

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  return (
    <Box sx={{ minHeight: 'calc(100vh - 64px)', width: '100%' }}>
      <Box
        sx={{
          height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
          display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' },
          alignItems: 'center',
          minHeight: '300px', // Adjust the height as needed
        }}
      >
        <Link to={`/nfl-survivor/${uriEncoded('f03ee2a7-bd82-44ee-a749-927839b678d0')}/HOME/HOME`} style={{ display: 'block', flex: '1' }}>
          <Box
            sx={{
              display: 'flex',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
              flexDirection: { xs: 'column', sm: 'column' },
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: !isMobile ? `url(https://poolpartyfilestorage.blob.core.windows.net/media/MTBackgroundHeroDesktop.png)`: `url(https://poolpartyfilestorage.blob.core.windows.net/media/MTHeroBackgroundMobile.svg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: '300px',
              height: { xs: '506px', sm: '506px', md: '450px', lg: '450px' },
              padding: '32px',
            }}
          >
            <Box sx={{ width: '100%' }}>
              {!isMobile ? (
                <>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      padding: '32px',
                      margin: '0 auto',
                      maxWidth: '1206px'
                    }}
                  >
                    <img
                      src='https://poolpartyfilestorage.blob.core.windows.net/media/MTHeroTextDesktop.png'
                      alt='Logo'
                      style={{ width: '100%', height: 'auto' }}
                    />
                     <Button
                    sx={{
                      backgroundColor: 'var(--color-yellow)',
                      color: 'var(--primary-color)',
                      '&:hover': {
                        opacity: 0.95,
                        backgroundColor: 'var(--color-yellow)',
                        color: 'var(--primary-color)',
                      },
                      display: 'block',
                      margin: '25px auto 0 0',
                      textDecoration: 'none',
                    }}
                    variant='text'
                  >
                    Click To Join Pool
                  </Button>
                  </Box>

                </>
              ) : (
                <>
                  <Box
                    sx={{
                      margin: '0 auto',
                      padding: '16px',
                      paddingTop:'0px'
                    }}
                  >
                    <img
                      src="https://poolpartyfilestorage.blob.core.windows.net/media/MTHeroTextMobile.png"
                      alt='Logo'
                      style={{
                        width: '100%',
                        height: 'auto',
                        margin: '0px auto 25px auto',
                        maxWidth: '253px',
                      }}
                    />
                    {/* <hr style={{ borderTop: '2px solid #fff' }} />
                    <img
                      src={poolPartyLogoStacked}
                      alt='Logo'
                      style={{
                        maxWidth: '161px',
                        display: 'block',
                        width: '90%',
                        margin: '25px auto 0px auto',
                      }}
                    /> */}
                  </Box>
                  {/* <Typography
                    sx={{
                      fontSize:'15px',
                      fontWeight:'700',
                      color:"#fff",
                      textAlign:'center',
                      marginBottom:'15px'
                    }}

                  >Experience Football Like Never Before</Typography> */}
                  <Button
                    sx={{
                      backgroundColor: 'var(--color-yellow)',
                      color: 'var(--primary-color)',
                      display: 'block',
                      margin: '0 auto',
                      textDecoration: 'none',
                      fontSize: '14px',
                    }}
                    variant='text'
                  >
                    Click To Join Pool
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Link>
        {/* <Link
          style={{ display: 'block', flex: '1' }}
          to={!userData ? '/login/private-pools' : '/private-pools'}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: { xs: 'column', sm: 'column' },
              alignItems: 'center',
              justifyContent: 'center',
              backgroundImage: `url(https://poolpartyfilestorage.blob.core.windows.net/media/PPG_Dual_Hero_Desktop.svg)`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              minHeight: '300px',
              height: '55vh',
              padding: '32px',
              width: { xs: '100%', sm: '100%', md: '100%', lg: '100%', xl: '100%' },
            }}
          >
            <Box sx={{ width: '100%' }}>
              {!isMobile ? (
                <>
                  <Box
                    sx={{
                      flex: 1,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      padding: '32px',
                      margin: '0 auto',
                    }}
                  >
                    <img
                      src='https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyBirdiePool.svg'
                      alt='Logo'
                      style={{ width: '100%', height: 'auto', maxWidth: '700px' }}
                    />
                  </Box>
                  <Button
                    sx={{
                      backgroundColor: 'var(--color-yellow)',
                      color: 'var(---primary-color)',
                      '&:hover': {
                        opacity: 0.95,
                        backgroundColor: 'var(--color-yellow)',
                        color: 'var(--primary-color)',
                      },
                      display: 'block',
                      margin: '0px auto',
                      textDecoration: 'none',
                    }}
                    variant='text'
                  >
                    Enter Birdie pool!
                  </Button>
                </>
              ) : (
                <>
                  <Box
                    sx={{
                      margin: '0 auto',
                      padding: '16px',
                    }}
                  >
                    <img
                      src='https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyBirdiePoolMobile.svg'
                      alt='Logo'
                      style={{
                        display: 'block',
                        width: '90%',
                        maxWidth: '324px',
                        margin: '0 auto 25px auto',
                      }}
                    />
                    <hr style={{ borderTop: '5px solid #fff' }} />
                    <img
                      src={poolPartyLogoStacked}
                      alt='Logo'
                      style={{
                        maxWidth: '125px',
                        display: 'block',
                        width: '90%',
                        margin: '25px auto 10px auto',
                      }}
                    />
                  </Box>
                  <Button
                    sx={{
                      backgroundColor: 'var(--color-yellow)',
                      color: 'var(--primary-color)',
                      display: 'block',
                      margin: '0 auto',
                      textDecoration: 'none',
                      fontSize: '14px',
                    }}
                    variant='text'
                  >
                    Enter Birdie pool!
                  </Button>
                </>
              )}
            </Box>
          </Box>
        </Link> */}
      </Box>
      <Typography
        variant='h1'
        sx={{
          color: 'var(--color-black)',
          fontStyle: 'normal',
          fontWeight: '800',
          lineHeight: '150%',
          textAlign: 'center',
          margin: '0 auto',
          marginTop: isMobile ? '32px' : '60px',
          marginBottom: '32px',
          letterSpacing: '-0.87px',
          maxWidth: isMobile ? '300px' : '650px',
          fontSize: isMobile ? '28px' : '46px',
        }}
      >
        {isMobile ? (
          <>
            The Best Sports Pools.{' '}
            <span style={{ textDecoration: 'none', position: 'relative', fontSize: '24px' }}>
              For the Best Sports Fans.
              {/* <span
                style={{
                  position: 'absolute',
                  content: '',
                  width: '100%',
                  height: '3px',
                  backgroundColor: 'black',
                  bottom: '-2px',
                  left: '0',
                }}
              /> */}
            </span>
          </>
        ) : (
          <span style={{ textDecoration: 'none', position: 'relative' }}>
            The Best Sports Pools.<br/> For the Best Sports Fans.
            {/* <span
              style={{
                position: 'absolute',
                content: '',
                width: '100%',
                height: '3px',
                backgroundColor: 'black',
                bottom: '-2px',
                left: '0',
              }}
            /> */}
          </span>
        )}
      </Typography>
      <Box
        sx={{
          width: { xs: '100%', lg: '75%' },
          margin: 'auto',
          textAlign: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ maxWidth: isMobile ? '290px' : '575px', margin: '0 auto' }}>
          <Typography
            variant='h3'
            sx={{
              color: 'var(--color-black)',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '170%',
              letterSpacing: '-0.266px',
              textAlign: 'center',
              fontSize: isMobile ? '14px' : '14px',
            }}
          >
            Pool Party Gaming is the premier sports pool platform where
            sports fans compete in the most exciting and highest quality
            sports pools. In addition to the timeless classic pools, we provide
            enhanced versions of these pools and brand new never before seen
            innovative pools and formats.
          </Typography>
        </Box>
        <Box sx={{ maxWidth: isMobile ? '290px' : '544px', margin: '0 auto' }}>
          <Typography
            variant='h3'
            sx={{
              color: 'var(--color-black)',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: '150%',
              letterSpacing: '-0.87px',
              textAlign: 'center',
              fontSize: isMobile ? '18px' : '30px',
              marginTop: isMobile ? '32px' : '60px',
            }}
          >
            Elite Competition Awaits
          </Typography>
          <Typography variant='body1' sx={descriptionStyles}>
          Sports pools are much more than just games. In fact,
          they bring people together through competition, entertainment,
          and the shared love of sports. Pool Party Gaming enables two
          exciting competition settings:
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Conditional flex direction
            alignItems: isMobile ? 'center' : 'flex-start', // Center items vertically on mobile
            justifyContent: 'center', // Center items horizontally
            maxWidth: '100%', // Full width container
            margin: '0 auto',
            gap: isMobile ? '20px' : '40px', // Spacing between pairs
            padding: '20px', // Optional padding for the entire box
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', // Column layout for text pairs
              alignItems: 'center', // Center text horizontally
              textAlign: 'center',
              maxWidth: isMobile ? '100%' : '50%', // Adjust width for mobile and desktop
              gap: '10px', // Spacing between title and description
              padding: '10px', // Padding inside each pair box
            }}
          >
            <Typography
              variant='h3'
              sx={{
                color: 'var(--color-black)',
                fontStyle: 'normal',
                fontWeight: '800',
                lineHeight: '150%',
                letterSpacing: '-0.87px',
                fontSize: isMobile ? '14px' : '18px',
                marginTop: isMobile ? '20px' : '0px',
                textDecoration: 'underline',
              }}
            >
              Pool Party Sitewide Competitions
            </Typography>
            <Box
              sx={{
                width: '290px'
              }}
            >
              <Typography variant='body1' sx={descriptionStyles}>
                Compete against the entire Pool Party Universe to earn and be crowned The Pool Party Champion.
              </Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column', // Column layout for text pairs
              alignItems: 'center', // Center text horizontally
              textAlign: 'center',
              maxWidth: isMobile ? '100%' : '50%', // Adjust width for mobile and desktop
              gap: '10px', // Spacing between title and description
              padding: '10px', // Padding inside each pair box
            }}
          >
            <Typography
              variant='h3'
              sx={{
                color: 'var(--color-black)',
                fontStyle: 'normal',
                fontWeight: '800',
                lineHeight: '150%',
                letterSpacing: '-0.87px',
                fontSize: isMobile ? '14px' : '18px',
                marginTop: isMobile ? '20px' : '0px',
                textDecoration: 'underline',
              }}
            >
              My Party Competitions
            </Typography>
            <Box
              sx={{
                width: '290px'
              }}
            >
              <Typography variant='body1' sx={descriptionStyles}>
                Create custom group competitions with your invite-only participants, allowing you to conquer your friends and family.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          marginTop: isMobile ? '20px' : '60px',
          width: isMobile ? '290px' : '544px',
          margin: '0 auto',
        }}
        >
          <Typography variant='body1' sx={descriptionStyles}>
            All pool entrants are automatically entered into the Pool Party universe competitions.
            Our unique platform easily allows for seamless transitions between competitions.
          </Typography>
      </Box>

      <Box>
        <Typography
          sx={{
            fontSize: isMobile ? '32px' : '46px',
            fontWeight: 800,
            marginTop: '32px'
          }}
        >
          NEW POOL
        </Typography>
        <Typography
          sx={{
            fontSize: isMobile ? '20px' : '32px',
            fontWeight: 700,
            textAlign: 'center',
            marginBottom: '24px',
          }}
        >
          FREE to Enter.  $500 Prize.
        </Typography>
      </Box>

      <MikeAndTonysForHomePage />

      <Box>
        <Typography
          sx={{
            fontSize: isMobile ? '32px' : '46px',
            fontWeight: 800,
            marginTop: '64px'
          }}
        >
          ONGOING POOLS
        </Typography>
      </Box>

      <NFLPickEmForHomePage />
      <NFLSurvivorForHomePage />
      <CFBSurvivorForHomePage />

      <Box>
        <Typography
          variant='h1'
          sx={{
            color: 'var(--color-black)',
            fontStyle: 'normal',
            fontWeight: '900',
            lineHeight: '150%',
            textAlign: 'center',
            fontSize: isMobile ? '32px' : '46px',
            margin: '75px auto 10px auto',
            letterSpacing: '-0.87px',
          }}
        >
          Upcoming Pools
        </Typography>
        <Typography
          sx={{
            margin: '0 auto',
            textAlign: 'center',
            color: 'var(--color-black)',
            fontStyle: 'normal',
            fontWeight: '500',
            lineHeight: '150%',
            letterSpacing: '-0.87px',
            marginBottom: '35px',
            fontSize: isMobile ? '16px' : '29px',
          }}
        >
          The Next Wave
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexWrap: isMobile ? 'nowrap' : 'wrap',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '16px', // Adjust the gap as needed
          margin: '0 auto',
          maxWidth: '750px', // Maximum width for larger screens
        }}
      >
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            NFL In Season
          </Typography>
        </Box>
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            CFB Playoffs
          </Typography>
        </Box>
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
            marginBottom: isMobile? '0' : '50px',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            NFL Playoffs
          </Typography>
        </Box>
        <Box
          sx={{
            width: isMobile ? '295px' : 'calc(50% - 16px)',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: isMobile ? 'left' : 'center',
            margin: '0 auto',
            marginBottom: '50px',
          }}
        >
          <Box
            component='img'
            sx={{
              display: 'block',
              width: 35,
              marginRight: '10px',
              marginLeft: isMobile ? '25px' : '0px',
            }}
            alt='Pool Party Trophy'
            src={trophyImage}
          />
          <Typography
            sx={{
              fontWeight: 800,
              fontSize: isMobile ? '24px' : '32px',
            }}
          >
            And More...
          </Typography>
        </Box>
      </Box>
    </Box>













  );
}