import React from 'react';
import { useState } from 'react';
import { Box, Button, Typography, Modal, TextField, Link } from '@mui/material';
import { usePublicPartyContext } from './context/PublicPartyContext';
import { useNavigate, useLocation } from 'react-router-dom';
import '../PrivateParty/PrivatePartyInviteStyles.scss';
import { useJoinPublicPool } from '../PublicPools/hooks/useJoinPublicPools';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";

const NotInPrivateParty = () => {
  const { partyData, joinParty, inviteeDisplayName, clubLogo, foundSubgroupName, clubDisplayName, poolStartDate, poolEndDate, isUserInThisGame, isUserInThisSubgroup, userData, gameId, gameType, gameAddress } = usePublicPartyContext();
  const { mutate: joinPool } = useJoinPublicPool();

  const navigate = useNavigate();
  const navigateToTop = useNavigateToTop();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [open, setOpen] = useState(false);
  const [passcode, setPasscode] = useState(new Array(4).fill(''));

  const [status, setStatus] = useState(null);//UI Purposes
  const [errorMessage, setErrorMessage] = useState([]);//UI Purposes

  const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'long', day: 'numeric' };
      const date = new Date(dateString);
      return date.toLocaleDateString('en-US', options);
  };

    // Extract the entire path after the base URL
  const pathAfterBaseUrl = location.pathname;

  //console.log('Path after base URL:', pathAfterBaseUrl);

  const handleJoinPool = async () => {
    let data = {
      gameID: gameId,
      userID: userData.user_id,
      gameType: gameType
    };

    joinPool(data, {
      onSuccess: (data) => {
        setStatus("success");
        //console.log("Join pool success:", data);
        joinParty();
        // window.location.href = `/birdie-pool/${gameId}/SCORE_CARD/${foundSubgroupName}`;
      },
      onError: (error) => {
        setStatus("fail");
        setErrorMessage(error.response?.data?.message || 'Invalid passcode. Please try again.');
        console.error("Join pool failed:", error);
        // Clear the error message after 5 seconds
        setTimeout(() => {
          setErrorMessage('');
          setStatus(null);
        }, 5000);
      }
    });
  };

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  const handleClick = async () => {
      //console.log("handleClick");
      if (!userData) {
          //console.log("User not logged in");
          navigateToTop(`/login${pathAfterBaseUrl}`);
          return;
      }  else if (!isUserInThisGame) {
          //console.log("User is not in the game. Let them join the game first");
          handleJoinPool();
      }   else if (!isUserInThisSubgroup) {
          //console.log("User is in the game,but not in the subgroup. Let them join the private party");
          await joinParty();
          // window.location.href = `/birdie-pool/${gameId}/SCORE_CARD`;
      } else {
          //console.log("NONE OF THE CONDITIONS MET. ERROR????");
      }
  };

  return (
    <>
        <Box className="container" style={{ maxWidth: '1116px', margin: '0 auto'}}>
          <Typography className="typography-main-message" style={{ padding: '15px 0', margin: '0 auto', fontSize: isMobile ? '20px' : '24px', fontWeight: '700', maxWidth: '90%'}}>
            Pool Host {inviteeDisplayName} <br/>invites you to the <br/>{foundSubgroupName} Party
          </Typography>
          <img src={clubLogo} alt="club logo" className="club-logo" style={{ display: 'block', margin: '0 auto', width: isMobile ? '94px' : '150px' }}/>
          <Typography className="typography-club-name" style={{ padding: '15px 0', fontSize: isMobile ? '20px' : '24px', fontWeight: '700'}}>
            {clubDisplayName}
          </Typography>
          <Button variant="contained" sx={{ mb: 2, fontSize: isMobile ? '16px' : '20px', marginTop: '10px' }} onClick={() => handleClick()}>
            JOIN PARTY
          </Button>
          <Typography className="dont-join" style={{ fontSize: isMobile ? '20px' : '24px', fontWeight: '600', marginTop: '10px' }}>
                <Link href="/pool-hall/">Not yet?</Link>
          </Typography>
        </Box>

    </>
  );
};

export default NotInPrivateParty;
