import React from "react";
import { Box, Typography } from "@mui/material";
import TraditionalBetCard from "../../SportsbookComponents/TraditionalBetCard/TraditionalBetCard";
import BetSlip from "../../SportsbookComponents/BetSlip/BetSlip";
import { useFantasySportsbookContext } from "../context/FantasySportsbookContext";
import { DateTime } from "luxon";

const MyBets = () => {
    const { 
        myUserId,
        pickState,
        setPickState,
        //NFLGameOddsByWeek,
        NFLSportsbookOdds,
        NFLTeams,
        currentGameInterval,
    } = useFantasySportsbookContext();
    //console.log("myUserId: ", myUserId, NFLSportsbookOdds, NFLTeams);


    function nflBetInfo(GlobalGameId) {
        let game = NFLSportsbookOdds?.find(game => game.GlobalGameId === GlobalGameId);
        console.log("game: ", game);
        let odds = game?.PregameOdds?.find(odds => odds.Sportsbook === "DraftKings" && odds.Unlisted === null) || null;
        console.log("odds: ", odds);
        let homeTeam = NFLTeams?.find(team => team.GlobalTeamID === game?.GlobalHomeTeamId);
        let awayTeam = NFLTeams?.find(team => team.GlobalTeamID === game?.GlobalAwayTeamId);
        //console.log("homeTeam: ", homeTeam, "awayTeam: ", awayTeam);
        const homeLogo = homeTeam?.WikipediaLogoURL;
        const awayLogo = awayTeam?.WikipediaLogoURL;
        const homeKey = homeTeam?.Key;
        const awayKey = awayTeam?.Key;
        const homeName = homeTeam?.Name;
        const awayName = awayTeam?.Name;
        return {
            gameId: game?.GlobalGameId,
            dateTime: game?.DateTime,
            homeLogo,
            awayLogo,
            homeDisplay: `${homeKey} ${homeName}`,
            awayDisplay: `${awayKey} ${awayName}`,
            odds,
            sport: "Football",
        }
    }

    // Sort NFLSportsbookOdds by DateTime
    const sortedGames = NFLSportsbookOdds?.slice().sort((a, b) => {
        const dateTimeA = DateTime.fromISO(a.DateTime, { zone: 'America/New_York' });
        const dateTimeB = DateTime.fromISO(b.DateTime, { zone: 'America/New_York' });
        return dateTimeA - dateTimeB; // Ascending order
    });

    return (
        <>
            {sortedGames?.map((game) => {
                const betInfo = nflBetInfo(game.GlobalGameId);
                return (
                    <React.Fragment key={game.GlobalGameId}>
                        <TraditionalBetCard 
                            pickState={pickState} 
                            setPickState={setPickState} 
                            betInfo={betInfo} 
                        />
                        <Box sx={{ height: '10px' }} />
                    </React.Fragment>
                );
            })}
            <BetSlip pickState={pickState} setPickState={setPickState} currentGameInterval={currentGameInterval}/>
        </>
    );
    }

export default MyBets;