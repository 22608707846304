import React from 'react';
import { useState, useEffect } from 'react';
import CFBSurvivorCard from './CFBSurvivorCard';
import WeeklyPicksBar from './WeeklyPicksBar';
import UserSubmitBar from './UserSubmitBar';
import CFBSurvivorWeekNav from './CFBSurvivorWeekNav';
import SortByDropdown from './SortByDropdown';
import ConferenceDropdown from '../DropdownMenus/ConferenceDropdown';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Typography, Grid, Box, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { DateTime } from 'luxon';
import LockIcon from '@mui/icons-material/Lock';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useSaveSurvivorPicks } from '../../../hooks/pools/useSaveSurvivorPicks';
import useNavigateToTop from '../../../hooks/general/useNavigateToTop';
import CFBRemovePickModal from '../../GameModals/SurvivorModals/CFBRemovePickModal';
import PickRemovedTooLateModal from '../../GameModals/SurvivorModals/PickRemovedTooLateModal';
import CFBSurvivorUserStatsModal from '../../GameModals/SurvivorModals/CFBSurvivorUserStats/CFBSurvivorUserStatsModal';
import CFBMoreInfoModal from '../../GameModals/MoreInfoModals/Football/CFBMoreInfoModal';
import SurvivorSubmitErrorModal from '../../GameModals/SurvivorModals/SurvivorSubmitErrorModal';
import { set } from 'lodash';

const MyPicks = () => {
    const {
        CFBTeams,
        CFBNames,
        CFBStadiums,
        CFBGamesByWeek,
        week,
        userPicksFromDB,
        pickEmState,
        teamFilter,
        doesUserHaveACCLoss,
        doesUserHaveBigTenLoss,
        doesUserHaveBig12Loss,
        doesUserHaveSECLoss,
        lossWeekACC,
        lossWeekBigTen,
        lossWeekBig12,
        lossWeekSEC,
        myUserId,
        mainGameId,
        gameAddress,
        setTeamFilter,
        rankingsToUse,
        setRankingsToUse,
        teamsToInclude,
        setTeamsToInclude,
        doesUserBelong,
        myUsername,
        startingWeekState,
        currentWeeksDeadlineDateTime,
    } = useCFBSurvivorContext();

    //console.log("CFBGamesByWeek:", CFBGamesByWeek);


    //console.log("Team Filter:", teamFilter);
    const [sortBy, setSortBy] = useState("Game Time");
    const [removeModalOpen, setRemoveModalOpen] = useState(false);
    const [removeTooLateOpen, setRemoveTooLateOpen] = useState(false);
    const [removeTooLateMessage, setRemoveTooLateMessage] = useState("");
    const [doesUserHaveALoss, setDoesUserHaveALoss] = useState(false);
    const [lossWeek, setLossWeek] = useState(null);
    const [statsModalOpen, setStatsModalOpen] = useState(false); // Modal state
    const [moreInfoOpen, setMoreInfoOpen] = useState(false);
    const [selectedGame, setSelectedGame] = useState(null);
    const [thisWeeksPickLocked, setThisWeeksPickLocked] = useState(false);
    const [submitError, setSubmitError] = useState(null);
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    //console.log("THIS WEEKS PICK LOCKED:", thisWeeksPickLocked);

    const handleChange = (event) => {
        setSortBy(event.target.value);
      };

    useEffect(() => {
        if (teamFilter === "ACC") {
            setDoesUserHaveALoss(doesUserHaveACCLoss);
            setLossWeek(lossWeekACC);
            setThisWeeksPickLocked(thisWeeksACCPickLocked);
        } else if (teamFilter === "Big Ten") {
            setDoesUserHaveALoss(doesUserHaveBigTenLoss);
            setLossWeek(lossWeekBigTen);
            setThisWeeksPickLocked(thisWeeksBigTenPickLocked);
        } else if (teamFilter === "Big 12") {
            setDoesUserHaveALoss(doesUserHaveBig12Loss);
            setLossWeek(lossWeekBig12);
            setThisWeeksPickLocked(thisWeeksBig12PickLocked);
        } else if (teamFilter === "SEC") {
            setDoesUserHaveALoss(doesUserHaveSECLoss);
            setLossWeek(lossWeekSEC);
            setThisWeeksPickLocked(thisWeeksSECPickLocked);
        }
    }, [teamFilter, week]);

    const { mutate, isLoading, isError, error, isSuccess } = useSaveSurvivorPicks();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    if (!myUserId)  {
        navigateToTop(`login/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/HOME/HOME`);
    }


    //const weeklySchedule = NFLScoresBySeason?.filter(game => game.Week === week);
    //const sortedSchedule = weeklySchedule?.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
    let weeklySchedule = CFBGamesByWeek?.filter(game => {
        return game.Week === week && game.Status !== "Canceled" &&
            (teamsToInclude?.length > 0 ? (teamsToInclude.includes(game.GlobalHomeTeamID) || teamsToInclude.includes(game.GlobalAwayTeamID)) : true);
    });
    //console.log("MY PICKS Weekly Schedule:", weeklySchedule);


    let sortedSchedule = weeklySchedule?.sort((a, b) => {
        const dateA = a.DateTime ? new Date(a.DateTime) : new Date(a.Day);
        const dateB = b.DateTime ? new Date(b.DateTime) : new Date(b.Day);
        return dateA - dateB;
    });

    if (sortBy === "Largest Spread") {
        const spreadSortedSchedule = weeklySchedule?.sort((a, b) => Math.abs(b.PointSpread) - Math.abs(a.PointSpread));
        sortedSchedule = spreadSortedSchedule;
    }

    //console.log("MY PICKS Weekly Schedule:", weeklySchedule, sortedSchedule);


    // Getting the User Picks by Week state
    const userPicks = userPicksFromDB?.filter(pick => pick.week === week && pick.conference === teamFilter) || [];
    //console.log("USER PICKS:####################", userPicks);
    //console.log("MY PICKS User Picks:", userPicksFromDB, userPicks);
    const userPickTeamId = userPicks?.length > 0 ? userPicks[0].teamId : null;
    //console.log("MY PICKS User Pick Team ID:", userPickTeamId);

    let usersSelectedGame = sortedSchedule?.find(game => userPicks.length > 0 && game.GlobalGameID === userPicks[0].gameId);
    //console.log("MY PICKS User's Selected Game:", usersSelectedGame);
    //console.log("MY PICKS User's Selected Game:", usersSelectedGame);
    // if (doesUserHaveALoss && lossWeek < week) {
    //     usersSelectedGame = null;
    // }

    if (usersSelectedGame) {
        weeklySchedule = weeklySchedule?.filter(game => game.GlobalGameID !== usersSelectedGame.GlobalGameID);
        sortedSchedule = sortedSchedule?.filter(game => game.GlobalGameID !== usersSelectedGame.GlobalGameID);
    }
    //console.log("MY PICKS Weekly Schedule After Filter:", weeklySchedule, sortedSchedule);

    // Getting the Satuday Noon ET Deadline
    const saturdayGame = sortedSchedule?.find(game => {
        const gameDate = DateTime.fromISO(game.Day).setZone('America/New_York');
        return gameDate.weekday === 6;
    });
    //console.log("Saturday Game:", saturdayGame);

    let deadline = null;

    if (saturdayGame) {
        const gameDate = DateTime.fromISO(saturdayGame.Day).setZone('America/New_York');
        if (week === 1) {
            const specificDate = DateTime.fromObject({ year: 2024, month: 8, day: 31, hour: 12, minute: 0, second: 0 }, { zone: 'America/New_York' });
            deadline = specificDate.toISO();
        } else {
        deadline = gameDate.set({ hour: 12, minute: 0, second: 0 }).toISO();
        }
    }

    //console.log("MY PICKS Saturday Noon Deadline:", deadline);

    const formatDateString = (dateString) => {
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });
        const formattedDate = date.toFormat("ccc M/d");
        const formattedTime = date.toFormat("h:mma 'ET'").replace('AM', 'am').replace('PM', 'pm');

        // Combine the date and time parts with an "@" symbol
        const formattedDateTime = `${formattedDate} @ ${formattedTime}`;

        return formattedDateTime;
    };
    // Formatting the Deadline for display
    const formattedDeadline = formatDateString(deadline);
    //console.log("MY PICKS Formatted Deadline:", formattedDeadline);

     // Determining if the current Week's pick is locked.  Constructing the time in NY, the deadline time in NY,
    // and the current week's game time in NY.  IF either the deadline or the game time has passed, the picks are locked.
    const timeInNy = DateTime.now().setZone('America/New_York');
    const deadlineTimeInNy = DateTime.fromISO(deadline).setZone('America/New_York');
    const currentWeeksGametime = pickEmState?.find(pick => pick.week === week && pick.conference === teamFilter)?.dateTime;  //FIX THIS TO BE DYNAMIC!!!!!!!!!!!!!!!!!!!!!!!!!!!

    let thisWeeksACCPickLocked = false;
    let thisWeeksBigTenPickLocked = false;
    let thisWeeksBig12PickLocked = false;
    let thisWeeksSECPickLocked = false;
    const currentACCGameTime = pickEmState?.find(pick => pick.week === week && pick.conference === "ACC")?.dateTime;
    const currantACCGameId = pickEmState?.find(pick => pick.week === week && pick.conference === "ACC")?.gameId;
    const currentACCGameStatus = CFBGamesByWeek?.find(game => game.GlobalGameID === currantACCGameId)?.Status;
    //console.log("MY PICKS Current ACC Game Time:", currentACCGameTime);
    const currentBigTenGameTime = pickEmState?.find(pick => pick.week === week && pick.conference === "Big Ten")?.dateTime;
    const currentBigTenGameId = pickEmState?.find(pick => pick.week === week && pick.conference === "Big Ten")?.gameId;
    const currentBigTenGameStatus = CFBGamesByWeek?.find(game => game.GlobalGameID === currentBigTenGameId)?.Status;
    const currentBig12GameTime = pickEmState?.find(pick => pick.week === week && pick.conference === "Big 12")?.dateTime;
    const currentBig12GameId = pickEmState?.find(pick => pick.week === week && pick.conference === "Big 12")?.gameId;
    const currentBig12GameStatus = CFBGamesByWeek?.find(game => game.GlobalGameID === currentBig12GameId)?.Status;
    const currentSECGameTime = pickEmState?.find(pick => pick.week === week && pick.conference === "SEC")?.dateTime;
    const currentSECGameId = pickEmState?.find(pick => pick.week === week && pick.conference === "SEC")?.gameId;
    const currentSECGameStatus = CFBGamesByWeek?.find(game => game.GlobalGameID === currentSECGameId)?.Status;
    const currentACCGameTimeInNy = DateTime.fromISO(currentACCGameTime).setZone('America/New_York');
    //console.log("MY PICKS Current ACC Game Status:", currentACCGameStatus, "Current Big Ten Game Status:", currentBigTenGameStatus, "Current Big 12 Game Status:", currentBig12GameStatus, "Current SEC Game Status:", currentSECGameStatus);
    //console.log("MY PICKS Current ACC Game Time in NY:", currentACCGameTimeInNy);   
    //console.log("TIME CHECK************************************", timeInNy > currentACCGameTimeInNy);
    const currentBigTenGameTimeInNy = DateTime.fromISO(currentBigTenGameTime).setZone('America/New_York');
    const currentBig12GameTimeInNy = DateTime.fromISO(currentBig12GameTime).setZone('America/New_York');
    const currentSECGameTimeInNy = DateTime.fromISO(currentSECGameTime).setZone('America/New_York');
    if (timeInNy > currentACCGameTimeInNy || currentACCGameStatus === "InProgress" || currentACCGameStatus === "Final" || currentACCGameStatus === "F/OT") {
        thisWeeksACCPickLocked = true;
    }
    if (timeInNy > currentBigTenGameTimeInNy || currentBigTenGameStatus === "InProgress" || currentBigTenGameStatus === "Final" || currentBigTenGameStatus === "F/OT") {
        thisWeeksBigTenPickLocked = true;
    }
    if (timeInNy > currentBig12GameTimeInNy || currentBig12GameStatus === "InProgress" || currentBig12GameStatus === "Final" || currentBig12GameStatus === "F/OT") {
        thisWeeksBig12PickLocked = true;
    }
    if (timeInNy > currentSECGameTimeInNy || currentSECGameStatus === "InProgress" || currentSECGameStatus === "Final" || currentSECGameStatus === "F/OT") {
        thisWeeksSECPickLocked = true;
    }
    if (timeInNy > deadlineTimeInNy) {
        thisWeeksACCPickLocked = true;
        thisWeeksBigTenPickLocked = true;
        thisWeeksBig12PickLocked = true;
        thisWeeksSECPickLocked = true;
    }
    if (doesUserHaveACCLoss) {
        thisWeeksACCPickLocked = true;
    }
    if (doesUserHaveBigTenLoss) {
        thisWeeksBigTenPickLocked = true;
    }
    if (doesUserHaveBig12Loss) {
        thisWeeksBig12PickLocked = true;
    }
    if (doesUserHaveSECLoss) {
        thisWeeksSECPickLocked = true;
    }
    //console.log("MY PICKS This Week's ACC Pick Locked:", thisWeeksACCPickLocked, "This Week's Big Ten Pick Locked:", thisWeeksBigTenPickLocked, "This Week's Big 12 Pick Locked:", thisWeeksBig12PickLocked, "This Week's SEC Pick Locked:", thisWeeksSECPickLocked);



    const handleRemovePick = async (gameId) => {
        const newYorkTime = DateTime.now().setZone('America/New_York');
        const deadlineInNy = DateTime.fromISO(deadline).setZone('America/New_York');
        const currentPickTimeInNy = DateTime.fromISO(currentWeeksGametime).setZone('America/New_York');
        //const testValue = true
        //console.log("MY PICKS Remove Pick Times IN REMOVE:", newYorkTime, deadlineInNy, currentPickTimeInNy);
        if (newYorkTime > deadlineInNy || newYorkTime > currentPickTimeInNy) {
            setRemoveTooLateOpen(true);
            if (newYorkTime > deadlineInNy) {
                setRemoveTooLateMessage("The pick deadline has passed.");
            } else if (newYorkTime > currentPickTimeInNy) {
                setRemoveTooLateMessage("The game has already started.");
            }
            return;
        }

        //console.log("Remove Pick:", gameId);
        const removedPick = pickEmState?.find(pick => pick.gameId === gameId);
        const updatedPickEmState = pickEmState?.filter(pick => pick.gameId !== gameId);
        //console.log("Remove Pick Updated PickEm State:", updatedPickEmState);
        const picksData = {
            picks: updatedPickEmState,
            userID: myUserId,
            gameType: "Survivor",
            gameId: mainGameId,
            league: "CFB",
            newPick: removedPick,
            removal: true,
        }
        //console.log("Remove Pick Data:", picksData);
        try {
            await mutate(picksData, {
                onSuccess: async (response) => {
                    //console.log("Success Removing Pick:", response);
                    const { removal, deadlinePassed, removedGame } = response;
                    //console.log("Removal:", removal, "Deadline Passed:", deadlinePassed, "Removed Game:", removedGame);
                    if (removedGame) {
                        setRemoveModalOpen(true);
                    } else if (deadlinePassed) {
                        //console.log("Deadline Passed Modal Should open");
                        setRemoveTooLateOpen(true);
                        setRemoveTooLateMessage("The pick deadline has passed.");
                    } else if (removal) {
                        setRemoveTooLateOpen(true);
                        setRemoveTooLateMessage("The game has already started.");
                    }
                },
                onError: (error) => {
                    console.log("Error Submitting Picks:", error);
                    setErrorModalOpen(true);
                    setSubmitError(error);
                }
            });
        } catch (error) {
            console.log("Error Submitting Picks:", error);
            setErrorModalOpen(true);
            setSubmitError(error);
        }
    };




    let myPickText = "Pending";
    let myPickColor = "#00AAD1";

    if (usersSelectedGame) {
        const userResult = userPicks[0].result;
        //console.log("USER RESULT!!!!!!", userResult, userPicks);
        if (userResult === "Pending" && thisWeeksPickLocked) {
            myPickText = "Locked";
            myPickColor = "#002129";
        } else if (userResult === "Win") {
            myPickText = "Survived";
            myPickColor = "#00AA72";
        } else if (userResult === "Loss") {
            myPickText = "Eliminated";
            myPickColor = "#CC293C";
        } else if (userResult === "No Pick") {
            myPickText = "No Pick";
            myPickColor = "#CC293C";
        }
    }

    return (
        <>
        <div>
            <WeeklyPicksBar
                weeklySchedule={sortedSchedule}
                userPicks={userPicks}
                deadline={deadline}
            />
            {!thisWeeksPickLocked && (
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column', // Ensures items are stacked vertically
                            alignItems: 'center',    // Centers items horizontally
                            padding: '16px 0',       // Vertical padding around the content
                            backgroundColor: '#FFC60A',
                            borderTop: '2px solid #002129',
                            height: isMobile ? '44px' : '64px',
                            justifyContent: 'center',
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                color: '#002129',
                                fontSize: isMobile ? '16px' : '22px',
                                fontWeight: '700',
                            }}
                        >
                            Pick Deadline: {formattedDeadline}
                        </Typography>
                    </Box>
                )}
            <CFBSurvivorWeekNav />
            <ConferenceDropdown />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column', // Ensures items are stacked vertically
                    alignItems: 'center',    // Centers items horizontally
                    padding: '16px 0',       // Vertical padding around the content
                }}
            >
                {doesUserHaveALoss && lossWeek < week && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: '#CC293C', marginLeft: '4px' }}>Eliminated</span> <CloseIcon sx={{ color: '#CC293C', marginLeft: '4px' }} />
                    </Typography>
                )}
                {!usersSelectedGame && !doesUserHaveALoss && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: '#CC293C', marginLeft: '4px' }}>No Pick</span>
                    </Typography>
                )}
                {!usersSelectedGame && doesUserHaveALoss && lossWeek === week && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: '#CC293C', marginLeft: '4px' }}>Eliminated <CloseIcon sx={{ color: '#CC293C', marginLeft: '4px' }} /></span>
                    </Typography>
                )}
                {usersSelectedGame && (
                    <Typography
                        variant="h6"
                        sx={{
                        color: '#002129',        // Default text color for the second typography
                        fontSize: isMobile ? '16px' : '22px',
                        fontWeight: '700',
                        display: 'flex',
                        alignItems: 'center'
                        }}
                    >
                        My Pick: <span style={{ color: myPickColor, marginLeft: '4px' }}>{myPickText}</span>

                        {myPickText === 'Pending' && (
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="15"
                            height="15"
                            viewBox="0 0 15 15"
                            fill="none"
                            style={{ color: myPickColor, marginLeft: '4px' }}
                            >
                            <g clipPath="url(#clip0_936_9440)">
                                <path d="M4.03846 0C2.76346 0 1.66038 0.529038 0.973269 1.38808C0.287308 2.24769 0 3.36115 0 4.56115V6.34615H1.73077V4.56115C1.73077 3.64962 1.95519 2.93423 2.32558 2.46981C2.69654 2.00596 3.19673 1.73077 4.03846 1.73077C4.8825 1.73077 5.38154 1.99038 5.75135 2.45192C6.12058 2.91346 6.34615 3.64038 6.34615 4.56115V5.19231H8.07692V4.56115C8.07692 3.35423 7.77288 2.22808 7.08519 1.37019C6.39808 0.512308 5.31115 0 4.03846 0ZM5.19231 5.76923C4.23692 5.76923 3.46154 6.54462 3.46154 7.5V13.2692C3.46154 14.2246 4.23692 15 5.19231 15H13.2692C14.2246 15 15 14.2246 15 13.2692V7.5C15 6.54462 14.2246 5.76923 13.2692 5.76923H5.19231ZM9.23077 8.65385C9.53679 8.65385 9.83027 8.77541 10.0467 8.9918C10.263 9.20819 10.3846 9.50167 10.3846 9.80769C10.3846 10.2335 10.1515 10.5992 9.80769 10.7994V12.1154C9.80769 12.4333 9.54865 12.6923 9.23077 12.6923C8.91288 12.6923 8.65385 12.4333 8.65385 12.1154V10.7994C8.31 10.5992 8.07692 10.234 8.07692 9.80769C8.07692 9.50167 8.19849 9.20819 8.41488 8.9918C8.63126 8.77541 8.92475 8.65385 9.23077 8.65385Z" fill={myPickColor} />
                            </g>
                            <defs>
                                <clipPath id="clip0_936_9440">
                                <rect width="15" height="15" fill="white" />
                                </clipPath>
                            </defs>
                            </svg>
                        )}
                        {myPickText === 'Locked' && <LockIcon sx={{ color: myPickColor, marginLeft: '4px', width: '15px', height: '24px' }} />}
                        {myPickText === 'Survived' && <CheckIcon sx={{ color: myPickColor, marginLeft: '4px' }} />}
                        {myPickText === 'Eliminated' && <CloseIcon sx={{ color: myPickColor, marginLeft: '4px' }} />}

                </Typography>
                )}

            </Box>

            {userPickTeamId === "No Pick" && (
                <Box
                    sx={{
                    width: isMobile ? '310px' : '375px',
                    height: isMobile ? '102px' : '130px',
                    flexShrink: 0,
                    borderRadius: '8px', // Fallback value for border-radius
                    border: '2px solid #CC293C',
                    backgroundColor: '#FFF',
                    display: 'flex',
                    alignItems: 'center',
                    margin: '0 auto', // Center the box horizontally
                    justifyContent: 'space-between', // Ensure space between text and icon
                    padding: '0 16px', // Padding to add space around content inside the box
                    boxSizing: 'border-box', // Include padding in width calculation
                    marginBottom: '16px',
                    }}
                >
                    <Typography
                    sx={{
                        color: '#CC293C',
                        fontFamily: 'Inter, sans-serif',
                        fontSize: isMobile ? '48px' : '64px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: 'normal',
                    }}
                    >
                    NO PICK
                    </Typography>
                    <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center', // Center the icon within the container
                        width: isMobile ? '48px' : '64px',
                        height: isMobile ? '48px' : '64px',
                        border: '3px solid #CC293C',
                        flexShrink: 0,
                    }}
                    >
                    <CloseIcon sx={{ width: isMobile ? '32px' : '48px', height: isMobile ? '32px' : '48px', color: '#CC293C' }} />
                    </Box>
              </Box>
            )
            }


            {usersSelectedGame && (
                <Box
                    sx={{
                    display: 'flex',
                    flexDirection: 'column', // Ensures items are stacked vertically
                    alignItems: 'center',    // Centers items horizontally
                    marginTop: '5px',       // Vertical padding around the content
                    }}
                >
                    <CFBSurvivorCard
                        key={usersSelectedGame.GlobalGameID}
                        thisGamesId={usersSelectedGame.GlobalGameID}
                        deadline={deadline}
                        thisWeeksPickLocked={thisWeeksPickLocked}
                        teamsToInclude={teamsToInclude}
                        moreInfoOpen={moreInfoOpen}
                        setMoreInfoOpen={setMoreInfoOpen}
                        selectedGame={selectedGame}
                        setSelectedGame={setSelectedGame}
                    />
                    {!thisWeeksPickLocked && (
                    <Typography
                        variant="h6"
                        onClick={() => handleRemovePick(usersSelectedGame.GlobalGameID)}
                        sx={{
                        color: '#CC293C',
                        fontSize: '12px',
                        fontWeight: '500',
                        textDecoration: 'underline',
                        marginBottom: '8px',
                        cursor: 'pointer'
                        }}
                    >
                        Remove Pick
                    </Typography>
                    )}
                </Box>
            )}

            {/* <SortByDropdown sortBy={sortBy} setSortBy={setSortBy} /> */}
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
            }}>
                {/* <Typography variant="body1" sx={{ marginRight: 2, fontSize: isMobile ? '12px' : '16px' }}>
                Sort By:
                </Typography> */}
                <RadioGroup
                row
                name="sort-by"
                value={sortBy}
                onChange={handleChange}
                >
                <FormControlLabel
                    value="Game Time"
                    control={<Radio />}
                    label={<Typography sx={{ fontSize: isMobile ? '12px' : '16px' }}>Game Time</Typography>}
                />
                <FormControlLabel
                    value="Largest Spread"
                    control={<Radio />}
                    label={<Typography sx={{ fontSize: isMobile ? '12px' : '16px' }}>Largest Spread</Typography>}
                />
                </RadioGroup>
            </Box>
            {doesUserBelong && (
                      <Typography
                        style={{
                          color: '#00AAD1',
                          fontSize: isMobile ? '12px' : '20px',
                          fontWeight: 500,
                          textDecoration: 'underline',
                          marginTop: isMobile ? '0px' : '10px',
                          marginBottom: isMobile ? '20px' : '20px',
                          display: 'inline-flex',
                          alignItems: 'center',
                          cursor: 'pointer', // Add cursor pointer to indicate it's clickable
                        }}
                        onClick={() => setStatsModalOpen(true)}
                      >
                        My Picks
                      </Typography>
            )}

            <Box
                sx={{
                    maxWidth: '975px',
                    margin: '0 auto',
                    padding: '0 16px' // Optional padding to prevent content from touching the edges
                }}
            >
                <Grid container spacing={1}>
                    {sortedSchedule?.map(game => (
                        <Grid
                            item xs={12} sm={12} md={6}
                            key={game.GlobalGameID}
                        >
                            <CFBSurvivorCard
                                key={game.GlobalGameID}
                                thisGamesId={game.GlobalGameID}
                                userPicks={userPicks}
                                thisWeeksPickLocked={thisWeeksPickLocked}
                                deadline={deadline}
                                doesUserHaveALoss={doesUserHaveALoss}
                                lossWeek={lossWeek}
                                teamsToInclude={teamsToInclude}
                                moreInfoOpen={moreInfoOpen}
                                setMoreInfoOpen={setMoreInfoOpen}
                                selectedGame={selectedGame}
                                setSelectedGame={setSelectedGame}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Box>

            {statsModalOpen && (
                <CFBSurvivorUserStatsModal
                    statsModalOpen={statsModalOpen}
                    setStatsModalOpen={setStatsModalOpen}
                    myUsername={myUsername}
                    doesUserHaveALoss={doesUserHaveALoss}
                    lossWeek={lossWeek}
                    startingWeekState={startingWeekState}
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                    CFBTeams={CFBTeams}
                    CFBNames={CFBNames}
                    areTheseMyStats={true}
                    usersPicks={userPicksFromDB}
                    teamFilter={teamFilter}
                    doesUserHaveACCLoss={doesUserHaveACCLoss}
                    lossWeekACC={lossWeekACC}
                    doesUserHaveBig12Loss={doesUserHaveBig12Loss}
                    lossWeekBig12={lossWeekBig12}
                    doesUserHaveBigTenLoss={doesUserHaveBigTenLoss}
                    lossWeekBigTen={lossWeekBigTen}
                    doesUserHaveSECLoss={doesUserHaveSECLoss}
                    lossWeekSEC={lossWeekSEC}
                />
            )}


            {removeModalOpen && (
                <CFBRemovePickModal
                    removeModalOpen={removeModalOpen}
                    setRemoveModalOpen={setRemoveModalOpen}
                    week={week}
                    userPicksFromDB={userPicksFromDB}
                    doesUserHaveACCLoss={doesUserHaveACCLoss}
                    lossWeekACC={lossWeekACC}
                    doesUserHaveBig12Loss={doesUserHaveBig12Loss}
                    lossWeekBig12={lossWeekBig12}
                    doesUserHaveBigTenLoss={doesUserHaveBigTenLoss}
                    lossWeekBigTen={lossWeekBigTen}
                    doesUserHaveSECLoss={doesUserHaveSECLoss}
                    lossWeekSEC={lossWeekSEC}
                    teamFilter={teamFilter}
                />
            )}
            {errorModalOpen && (
                <SurvivorSubmitErrorModal
                    submitModalOpen={errorModalOpen}
                    setSubmitModalOpen={setErrorModalOpen}
                    submitError={submitError}
                    setSubmitError={setSubmitError}
                />
            )}

            {removeTooLateOpen && (
                <PickRemovedTooLateModal
                    removeTooLateOpen={removeTooLateOpen}
                    setRemoveTooLateOpen={setRemoveTooLateOpen}
                    removeTooLateMessage={removeTooLateMessage}
                    setRemoveTooLateMessage={setRemoveTooLateMessage}
                    week={week}
                />
            )}
        </div>
        </>
    );
}

export default MyPicks;
