import React, { createContext, useContext, useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import { useJoinPrivateParty } from "../../PrivateParty/hooks/useJoinPrivateParty";
import { useGetPublicPools } from "../../PublicPools/hooks/useGetPublicPools";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import { useGetUserData } from "../../BirdiePool/hooks/index";

const PublicPartyContext = createContext();

export const usePublicPartyContext = () => {
  const context = useContext(PublicPartyContext);
  if (!context) {
    throw new Error("usePublicPartyContext must be used within a PublicPartyProvider");
  }
  return context;
};

const PublicPartyProvider = ({ children }) => {
  const { gameId, privatePartyId, inviteeId } = useParams();
  const userData = getUserDataFromLocalStorage();
  const myUserId = userData?.user_id;

  const [partyData, setPartyData] = useState({gameId,privatePartyId,});
  const { mutate: joinPrivateParty } = useJoinPrivateParty();
  const { data: publicPoolsData, publicIsLoading, publicIsError, publicError } = useGetPublicPools();
  const { mutate: getUserData, data:inviteUserData } = useGetUserData();
  const { mutate: getMyUserData, data: myUserData } = useGetUserData();

  //console.log("Public Pools Data", publicPoolsData);

  let poolData = [];

// Check if the data is not loading and there's no error before using it

if (!publicIsLoading && !publicIsError && Array.isArray(publicPoolsData)) {
  poolData = [...poolData, ...publicPoolsData];
}

  const usersEntry = myUserData?.Games?.find((game) => game.gameID === gameId);
  //console.log("Users Entry in PRIVATE PARTY CONTEXT", usersEntry)
  const isUserInThisGame = !!usersEntry;
  let isUserInThisSubgroup = false;
  if (isUserInThisGame) {
    const subgroups = usersEntry?.subGroups;
    const foundSubgroup = subgroups?.find((subgroup) => subgroup.subGroupID === privatePartyId);
    //console.log("Found Subgroup", foundSubgroup);
    isUserInThisSubgroup = !!foundSubgroup;
  }
  //console.log("Is user in this game? and subgroup?", usersEntry, isUserInThisGame, isUserInThisSubgroup, privatePartyId);




  useEffect(() => {
    //console.log("Initializing PrivatePartyProvider with gameId:", gameId,privatePartyId );
    if (!gameId) {
      console.error("No party ID found");
    }
    if(!inviteeId){
      console.error("No invitee ID found");
    }
    //console.log(inviteeId)
    getUserData(inviteeId);
    getMyUserData(myUserId);
  }, [gameId, inviteeId, getUserData, privatePartyId]);

  //console.log("INVITE USER DATA", inviteUserData, "Invitee ID", inviteeId, "Pool Data:", poolData, "My User Data", myUserData);
  // const gameType = poolData?.[0]?.gameType;
 
  const inviteeFirstName = inviteUserData?.first_name;
  const inviteeLastName = inviteUserData?.last_name;
  const inviteeDisplayName = inviteeFirstName + " " + inviteeLastName;
  //console.log("Invitee Display Name", inviteeDisplayName);
  const foundGame = poolData?.find((game) => game.gameID === gameId);
  const gameType = foundGame?.gameType;
  const gameAddress = foundGame?.gameAddress;
  //console.log("Game Type", gameType);
  //console.log("Public Pool Data: FOUND GAME", foundGame);
  const subgroups = foundGame?.subGroups;
  const foundSubgroup = subgroups?.find((subgroup) => subgroup.groupID === privatePartyId);
  const restrictedUsers = foundSubgroup?.inactiveUsers;
  const amIRestricted = restrictedUsers?.includes(myUserId);
  const foundSubgroupName = foundSubgroup?.groupName;
  const clubLogo = foundGame?.clubLogo;
  const clubDisplayName = foundGame?.headerDisplayName;
  const gameName = foundGame?.gameName;
  let displayName = gameName;
  if (!gameName) {
    displayName = clubDisplayName;
  }
  const poolStartDate = foundGame?.startDate;
  const poolEndDate = foundGame?.endDate;
  //console.log("Found Subgroup Name", foundSubgroupName, restrictedUsers, amIRestricted);

  //console.log(inviteUserData);
  const joinParty = async (partyData) => {
    let foundGame = poolData?.find((game) => game.gameID === gameId);
    let subgroups = foundGame?.subGroups;
    let findSubgroup = subgroups?.find((subgroup) => subgroup.groupID === privatePartyId);

    if (!findSubgroup) {
      console.error("Subgroup not found");
      return;
    }

    const uriEncoded = (link) => {
      return encodeURI(`${encodeURIComponent(link)}`);
    }

    //console.log("Joining party", poolData, findSubgroup.groupID);

    let data = {
      groupName: findSubgroup.groupName,
      groupID: privatePartyId,
      userID: userData.user_id,
      gameID: gameId,
    };

    //console.log("Joining party", data);

    try {
      await joinPrivateParty(data, {
        onSuccess: async () => {
          try {
            //console.log("Successfully joined party");
            const foundSubgroupName = findSubgroup.groupName;
            // Conditional redirection
              window.location.href = `/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/MY_PARTY/${uriEncoded(privatePartyId)}`;
          } catch (error) {
            console.error("Error in success handling:", error);
          }
        },
        onError: (error) => {
          console.error("Error joining party:", error);
        }
      });
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };



  const PublicPartyContextValues = {
    partyData,
    setPartyData,
    joinParty,
    inviteeDisplayName,
    clubLogo,
    foundSubgroupName,
    displayName,
    poolStartDate,
    poolEndDate,
    gameId,
    gameAddress,
    isUserInThisGame,
    isUserInThisSubgroup,
    userData,
    amIRestricted,
    gameType,
    privatePartyId
  };
  //console.log("Public Party Context Values", PublicPartyContextValues);

  return (
    <PublicPartyContext.Provider value={PublicPartyContextValues}>
      {children}
    </PublicPartyContext.Provider>
  );
};

export default PublicPartyProvider;