import React, { useState, useEffect } from 'react';
import { useNFLPickEmContext } from '../context/NFLPickEmContext';
import  { DateTime } from "luxon";
import '../NFLPickEmStyles/PickEmStyles.scss'
import LockIcon from '@mui/icons-material/Lock';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InfoIcon from '@mui/icons-material/Info';
import NFLMoreInfoModal from '../../GameModals/MoreInfoModals/Football/NFLMoreInfoModal';


const NFLPickEmCard = ({ gameId, userPicks, noPicksLength, moreInfoOpen, setMoreInfoOpen, selectedGame, setSelectedGame }) => {
    //console.log("NO PICKS PICK LENGTH:", noPicksLength);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    // Finds and returns the sportsbook name of an NFL team given its GlobalTeamID
    // @param {Array} NFLTeams - The list of NFL teams
    // @param {number} globalTeamID - The GlobalTeamID of the team
    // @returns {string|null} The sportsbook name of the team or null if not found
    const nflSportsbookName = (globalTeamID) => {
        const team = NFLTeams?.find(team => team.GlobalTeamID === globalTeamID);
        const teamKey = team?.Key;
        const teamName = team?.Name;
        if (teamKey && (teamKey.startsWith("LA") || teamKey.startsWith("NY"))) {
            return teamKey.slice(0, 2) + " " + teamName;
        } 
        // else if (teamKey === "WAS") {
        //     return "WAS Command"
        // }
        else {
            return teamKey + " " + teamName;
        }
    };

    // Finds and returns the stadium information given its StadiumID
    // @param {Array} NFLStadiums - The list of NFL stadiums
    // @param {number} stadiumID - The StadiumID of the stadium
    // @returns {object|null} The stadium information object or null if not found
    const nflStadiumInfoByID = (stadiumID) => {
        const stadium = NFLStadiums?.find(stadium => stadium.StadiumID === stadiumID);
        return stadium ? stadium : null;
    };

    /**
     * Formats a date string to the format "THURS OCT 26" in EST.
     * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
     * @returns {string} - The formatted date string.
     */
    function formatDate(dateString) {
        // Create a DateTime object in EST
        const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

        // Arrays for day and month names
        const weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THURS', 'FRI', 'SAT'];
        const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

        // Get the abbreviated day of the week and month
        const dayOfWeek = weekdays[date.weekday % 7]; // % 7 to handle 1-based index of luxon
        const month = months[date.month - 1]; // Month is 1-based in luxon

        // Get the day of the month
        const day = date.day;

        // Return formatted date string
        return `${dayOfWeek} ${month} ${day}`;
    }


    /**
 * Formats a date string to the format "THURS OCT 26 - 8:20 PM ET" or "TBD" if the time is midnight.
 *
 * @param {string} dateString - The date string in ISO format (e.g., "2024-10-26T20:20:00").
 * @returns {string} - The formatted date and time string.
 */
function formatDateTime(dateString) {
    // Create a DateTime object in EST
    const date = DateTime.fromISO(dateString, { zone: 'America/New_York' });

    // Arrays for day and month names
    const weekdays = ['SUN', 'MON', 'TUE', 'WED', 'THURS', 'FRI', 'SAT'];
    const months = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];

    // Get the abbreviated day of the week and month
    const dayOfWeek = weekdays[date.weekday % 7]; // % 7 to handle 1-based index of luxon
    const month = months[date.month - 1]; // Month is 1-based in luxon

    // Get the day of the month
    const day = date.day;

    // Format the time part
    let formattedTime = date.toFormat('h:mm a');

    // Check if the time is midnight
    if (formattedTime === '12:00 AM') {
        formattedTime = 'TBD'; // Set to TBD if midnight
    }

    // Combine the date and time parts
    let dateTimeString = `${dayOfWeek} ${month} ${day}`;

    // Add the time part with "ET" suffix if not TBD
    if (formattedTime !== 'TBD') {
        dateTimeString += ` - ${formattedTime} ET`;
    } else {
        dateTimeString += ` - ${formattedTime}`;
    }

    // Return the formatted date and time string
    return dateTimeString;
}



    const { NFLTeams, 
        NFLStandings, 
        NFLStadiums, 
        NFLGameOddsByWeek,
        NFLScoresBySeason, 
        pickEmState, 
        setPickEmState,
        week,
        startingWeekState, 
        userPicksFromDB, 
        lockedBackgroundColor,
        winBackgroundColor,
        lossBackgroundColor,
        pushBackgroundColor,
        winNotSelectedBackgroundColor,
        pushNotSelectedBackgroundColor,
        finalizedMyPickBorderColor,
        finalizedPushBorderColor,
        finalizedWinBorderColor,
        lockedPickFontColor,
        notSelectedStartedFontColor,
        notSelectedPushFontColor,
        topLeftLiveColor,
        topLeftNotSelectedColor,
        topLeftSelectedColor,
        doesUserBelong
    } = useNFLPickEmContext();
    //console.log("NFL GAME ODDS BY WEEEK:", NFLGameOddsByWeek);
    
    
    const gameInfo = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
    const gameTime = gameInfo?.DateTime;
    const gameDateTime = DateTime.fromISO(gameTime, { zone: 'America/New_York' });
    const nowInNY = DateTime.now().setZone('America/New_York');
    const formattedGameDate = formatDate(gameTime);
    const formattedGameDateTime = formatDateTime(gameTime);

    const hasGameStarted = nowInNY >= gameDateTime;

    let homeSpread = null;
    let awaySpread = null;
    let modalHomeSpread = null;
    let modalAwaySpread = null;
    if (week < startingWeekState) {
        homeSpread = gameInfo?.PointSpread;
        awaySpread = -1 * homeSpread;
        modalHomeSpread = gameInfo?.PointSpread;
        modalAwaySpread = -1 * modalHomeSpread;
    } else if (week === startingWeekState || startingWeekState + 1) {
        const bettingGameInfo = NFLGameOddsByWeek?.find(game => game.GlobalGameId === gameId);
        //console.log("Betting Game Info:", bettingGameInfo);
        const consensusOdds = bettingGameInfo 
        ? bettingGameInfo.PregameOdds?.find(odd => {
            if (!hasGameStarted) {
                return odd.SportsbookId === 7 && odd.Unlisted === null;
            }
            return odd.SportsbookId === 7; // Ignore `odd.Unlisted === null` if the game has started
        }) : null;  
        //console.log("Consesus Odds:", consensusOdds)
        homeSpread = Math.round(consensusOdds?.HomePointSpread * 2) / 2;
        awaySpread = homeSpread * -1;
        modalHomeSpread = Math.round(consensusOdds?.HomePointSpread * 2) / 2;
        modalAwaySpread = modalHomeSpread * -1;
    } 
    if (week > startingWeekState + 1) {
        modalHomeSpread = gameInfo?.PointSpread;
        modalAwaySpread = -1 * modalHomeSpread;
    }


    //console.log("GAME INFO:", gameInfo, bettingGameInfo, consensusOdds);

    const [topSpreadClicked, setTopSpreadClicked] = useState(false);
    const [bottomSpreadClicked, setBottomSpreadClicked] = useState(false);
    const [topSpread, setTopSpread] = useState(null);
    const [bottomSpread, setBottomSpread] = useState(null);
    const [gameLockedIn, setGameLockedIn] = useState(false);

    
    const gameStatus = gameInfo?.Status;
    //const gameClosed = gameInfo?.IsClosed;
    const gameFinalized = ((gameStatus === "Final" || gameStatus === "F/OT") && gameInfo?.HomeScore !== null && gameInfo?.AwayScore !== null);
    //console.log("game status:", gameStatus, "game closed:", gameClosed, "game finalized:", gameFinalized, gameInfo);
    //console.log("GAME TIME:", gameTime, "GAME DATE TIME:", gameDateTime, "NOW IN NY:", nowInNY, "HAS GAME STARTED?", hasGameStarted);
    
    const homeId = gameInfo?.GlobalHomeTeamID;
    const homeLogo = NFLTeams?.find(team => team.GlobalTeamID === homeId)?.WikipediaLogoURL;
    const homeStandings = NFLStandings?.find(team => team.GlobalTeamID === homeId);
    const homeWins = homeStandings?.Wins || 0;
    const homeLosses = homeStandings?.Losses || 0;
    const homeTies = homeStandings?.Ties || 0; 
    const homeRecord = homeTies > 0 ? `${homeWins}-${homeLosses}-${homeTies}` : `${homeWins}-${homeLosses}`;
    const homeName = nflSportsbookName(homeId);
    const homeAbbreviation = gameInfo?.HomeTeam;
    const homeScore = gameInfo?.HomeScore;
    const awayId = gameInfo?.GlobalAwayTeamID;
    const awayLogo = NFLTeams?.find(team => team.GlobalTeamID === awayId)?.WikipediaLogoURL;
    const awayStandings = NFLStandings?.find(team => team.GlobalTeamID === awayId);
    const awayWins = awayStandings?.Wins || 0;
    const awayLosses = awayStandings?.Losses || 0;
    const awayTies = awayStandings?.Ties || 0;
    const awayRecord = awayTies > 0 ? `${awayWins}-${awayLosses}-${awayTies}` : `${awayWins}-${awayLosses}`;
    const awayName = nflSportsbookName(awayId);
    const awayAbbreviation = gameInfo?.AwayTeam;
    //console.log("HOME AND AWAY SPREAD:", homeSpread, awaySpread);
    const awayScore = gameInfo?.AwayScore;

    const channel = gameInfo?.Channel;
    const stadiumID = gameInfo?.StadiumID;
    const stadiumInfo = nflStadiumInfoByID(stadiumID);
    const stadiumName = stadiumInfo?.Name;
    const stadiumCity = stadiumInfo?.City;
    const stadiumState = stadiumInfo?.State;
    const stadiumCountry = stadiumInfo?.Country;
    const stadiumCapacity = stadiumInfo?.Capacity;
    const stadiumSurface = stadiumInfo?.PlayingSurface;
    const stadiumType = stadiumInfo?.Type;
    //console.log("STADIUM INFO:", stadiumID, stadiumInfo);

    let awayLockedSpread = null;
    let homeLockedSpread = null;
    // Use Effect to set picks on page load
    useEffect(() => {
        //console.log("USER PICKS Filtered:", userPicks);
        if (userPicks) {
            const isAwaySelected = userPicks?.find(pick => pick.teamId === awayId);
            const isHomeSelected = userPicks?.find(pick => pick.teamId === homeId);
            //console.log("IS AWAY SELECTED?", isAwaySelected, "IS HOME SELECTED?", isHomeSelected);
            if (isAwaySelected) {
                setTopSpreadClicked(true);
                const lockedSpread = isAwaySelected?.spread;
                awayLockedSpread = lockedSpread
                setTopSpread(awayLockedSpread);
                setGameLockedIn(true);
                setBottomSpread("Locked");
            } else {
                    setTopSpread(awaySpread);
                }
            if (isHomeSelected) {
                setBottomSpreadClicked(true);
                homeLockedSpread = userPicks?.find(pick => pick.teamId === homeId)?.spread;
                setBottomSpread(homeLockedSpread);
                setGameLockedIn(true);
                setTopSpread("Locked");
            } else {
                setBottomSpread(homeSpread);
            }
        }
       

        //console.log("Away Locked Spread:", awayLockedSpread, "Home Locked Spread:", homeLockedSpread);
    }, [userPicks, awayId, homeId]);

    useEffect(() => {
        if (!gameLockedIn) {
        const isAwayInState = pickEmState?.find(pick => pick.teamId === awayId);
        const isHomeInState = pickEmState?.find(pick => pick.teamId === homeId);
        const isTopClicked = topSpreadClicked;
        const isBottomClicked = bottomSpreadClicked;

        if (isAwayInState && !isTopClicked) {
            setTopSpreadClicked(true);
        }
        if (!isAwayInState && isTopClicked) {
            setTopSpreadClicked(false);
        }
        if (isHomeInState && !isBottomClicked) {
            setBottomSpreadClicked(true);
        }
        if (!isHomeInState && isBottomClicked) {
            setBottomSpreadClicked(false);
        }
    }
    },   [pickEmState, awayId, homeId]);


    let whoWon = null;
    let whoWonWithSpread = null;
    if (gameFinalized) {
        whoWon = (homeScore === awayScore) ? "Push" : (homeScore > awayScore) ? homeId : awayId;
        if (gameLockedIn && bottomSpreadClicked) {
            whoWonWithSpread = ((homeScore + bottomSpread) === awayScore) ? "Push" : ((homeScore + bottomSpread) > awayScore) ? homeId : awayId;
        } else if (gameLockedIn && topSpreadClicked) {
            whoWonWithSpread = ((awayScore + topSpread) === homeScore) ? "Push" : ((awayScore + topSpread) > homeScore) ? awayId : homeId;
        } else {
            whoWonWithSpread = ((homeScore + bottomSpread) === awayScore) ? "Push" : ((homeScore + bottomSpread) > awayScore) ? homeId : awayId;
        }
    }
    let myResult = null;
    if (gameFinalized && gameLockedIn) {    
        myResult = ((whoWonWithSpread === awayId && topSpreadClicked) || (whoWonWithSpread === homeId && bottomSpreadClicked)) ? "Win" : (whoWonWithSpread === "Push" ? "Push" : "Loss");
    }


    let topLeftText = formattedGameDateTime;
    if (gameStatus === "InProgress" || (hasGameStarted && !gameFinalized)) {
        topLeftText = "LIVE";
    } else if (gameFinalized) {
        topLeftText = formattedGameDate;
    }
    let topRightText = channel;
    if (gameFinalized) {
        topRightText = gameStatus;
    }
    let bottomLeftText = stadiumState ? `${stadiumCity}, ${stadiumState}` : `${stadiumCity}, ${stadiumCountry}`;
    if (gameFinalized && gameLockedIn) {
        if (myResult === "Win") {
            bottomLeftText = "My Result: WIN";
        } else if (myResult === "Loss") {
            bottomLeftText = "My Result: LOSS";
        } else if (myResult === "Push") {
            bottomLeftText = "My Result: PUSH";
        }
    }
    let bottomRightText = "";
    if (gameLockedIn && gameFinalized) {
        if (topSpreadClicked) {
            bottomRightText = "Closing Line: " + awayAbbreviation + " " + (awaySpread > 0 ? "+" : "") + (awaySpread === 0 ? "Pk" : awaySpread);
        } else if (bottomSpreadClicked) {
            bottomRightText = "Closing Line: " + homeAbbreviation + " " + (homeSpread > 0 ? "+" : "") + (homeSpread === 0 ? "Pk" : homeSpread);
        }
    } else if (!gameFinalized && gameLockedIn) {
        if (topSpreadClicked) {
            bottomRightText = "Live Line: " + awayAbbreviation + " " + (awaySpread > 0 ? "+" : "") + (awaySpread === 0 ? "Pk" : awaySpread);
        } else if (bottomSpreadClicked) {
            bottomRightText = "Live Line: " + homeAbbreviation + " " + (homeSpread > 0 ? "+" : "") + (homeSpread === 0 ? "Pk" : homeSpread);
        }
    }



    
    let buttonBackgroundColor = null;
    if (gameLockedIn) {
        if (!gameFinalized) {
            buttonBackgroundColor = lockedBackgroundColor;
        } else if (myResult === "Win") {
            buttonBackgroundColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            buttonBackgroundColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            buttonBackgroundColor = pushBackgroundColor;
        }
    }
    if (!gameLockedIn && gameFinalized) {
        if (whoWonWithSpread === "Push") {
            buttonBackgroundColor = pushNotSelectedBackgroundColor;
        }
        if (whoWonWithSpread !== "Push") {
            buttonBackgroundColor = winNotSelectedBackgroundColor;
        }
    }

    let buttonBorderColor = null;
    if (gameLockedIn) {
        buttonBorderColor = finalizedMyPickBorderColor;
    } else if(gameFinalized && whoWonWithSpread === "Push") {
        buttonBorderColor = finalizedPushBorderColor;
    } else if (gameFinalized && whoWonWithSpread !== "Push") {
        buttonBorderColor = finalizedWinBorderColor;
    }
    //console.log("Button Border Color:", buttonBorderColor);

    let buttonFontColor = null;
    if (gameLockedIn) {
        buttonFontColor = lockedPickFontColor;
    } else if (gameFinalized) {
        buttonFontColor = notSelectedPushFontColor;
    } else if ((!gameLockedIn && !topSpreadClicked && !bottomSpreadClicked && (userPicks?.length + pickEmState?.length + noPicksLength) === 5) || gameStatus === "InProgress") {
        buttonFontColor = notSelectedStartedFontColor;
    }
    //console.log("Button Font Color:", buttonFontColor);

    let topBorderColor = "#E5E5E5";
    if (!gameLockedIn && (topSpreadClicked || bottomSpreadClicked)) {
        topBorderColor = "#00AAD1";
    } else if (gameLockedIn) {
        if (!gameFinalized) {
            topBorderColor = lockedBackgroundColor;
        } else if (myResult === "Win") {
            topBorderColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            topBorderColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            topBorderColor = pushBackgroundColor;
        }
    }


    let topLeftTextColor = "#232425";
    if ((gameLockedIn && gameStatus !== "InProgress") || (topSpreadClicked || bottomSpreadClicked)) {
        topLeftTextColor = topLeftSelectedColor;
    } else if (gameStatus === "InProgress" || (hasGameStarted && !gameFinalized)) {
        topLeftTextColor = topLeftLiveColor;
    } else if (gameFinalized && !gameLockedIn) {
        topLeftTextColor = topLeftNotSelectedColor;
    }

    let topRightTextColor = "#232425";
    if (gameLockedIn || (topSpreadClicked || bottomSpreadClicked)) {
        topRightTextColor = topLeftSelectedColor;
    } else if (gameFinalized && !gameLockedIn) {
        topRightTextColor = topLeftNotSelectedColor;
    } 

    let bottomLeftTextColor = null;
    if (gameFinalized && gameLockedIn) {
        if (myResult === "Win") {
            bottomLeftTextColor = winBackgroundColor;
        } else if (myResult === "Loss") {
            bottomLeftTextColor = lossBackgroundColor;
        } else if (myResult === "Push") {
            bottomLeftTextColor = pushBackgroundColor;
        }
    } 


    const handleMoreInfoClick = () => {

        const gameInfoForModal = {
            homeLogo: homeLogo,
            awayLogo: awayLogo,
            homeName: homeAbbreviation,
            awayName: awayAbbreviation,
            homeRecord: homeRecord,
            awayRecord: awayRecord,
            homeScore: homeScore,
            awayScore: awayScore,
            gameLine: modalHomeSpread < 0 ? `(${homeAbbreviation} ${modalHomeSpread})` : modalHomeSpread === 0 ? `Pick 'Em` : `(${awayAbbreviation} ${modalAwaySpread})`,
            gameTime: gameTime,
            gameDateTime: gameDateTime,
            status: gameStatus,
            gameId: gameId,
            homeId: homeId,
            awayId: awayId,
    };

    //console.log("GAME INFO FOR MODAL:", gameInfoForModal);

    setSelectedGame(gameInfoForModal);
    setMoreInfoOpen(true);
    };

    


    const handleTopClick = () => {
        if (hasGameStarted || !awaySpread || gameLockedIn) {
            return;
        }
        //console.log('Top Spread Clicked');
        const pick = {
            gameId: gameId,
            teamId: awayId,
            spread: awaySpread,
            dateTime: gameInfo.DateTime,
            week: gameInfo.Week,
            isHomeSelected: false,
            result: "Pending"
        };
        const existingPickIndex = pickEmState.findIndex(p => p.gameId === pick.gameId && p.teamId === pick.teamId);
        const oppositePickIndex = pickEmState.findIndex(p => p.gameId === pick.gameId && p.teamId !== pick.teamId);
        if (((pickEmState?.length + userPicks?.length + noPicksLength) === 5) && existingPickIndex === -1 && oppositePickIndex === -1) {
            return;
        }
        
        
        if (oppositePickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(oppositePickIndex, 1);
            setPickEmState([...updatedPickEmState, pick]);
            setBottomSpreadClicked(false);
        } else if (existingPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(existingPickIndex, 1);
            setPickEmState(updatedPickEmState);
        } else {
            setPickEmState([...pickEmState, pick]);
        }
        setTopSpreadClicked(!topSpreadClicked);
    };

    const handleBottomClick = () => {
        if (hasGameStarted || !homeSpread || gameLockedIn) {
            return;
        }
        //console.log('Bottom Spread Clicked');
        const pick = {
            gameId: gameId,
            teamId: homeId,
            spread: homeSpread,
            dateTime: gameInfo.DateTime,
            week: gameInfo.Week,
            isHomeSelected: true,
            result: "Pending"
        };
        const existingPickIndex = pickEmState.findIndex(p => p.gameId === pick.gameId && p.teamId === pick.teamId);
        const oppositePickIndex = pickEmState.findIndex(p => p.gameId === pick.gameId && p.teamId !== pick.teamId);
        if (((pickEmState?.length + userPicks?.length + noPicksLength) === 5) && existingPickIndex === -1 && oppositePickIndex === -1) {
            return;
        }

        if (oppositePickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(oppositePickIndex, 1);
            setPickEmState([...updatedPickEmState, pick]);
            setTopSpreadClicked(false);
        } else if (existingPickIndex !== -1) {
            const updatedPickEmState = [...pickEmState];
            updatedPickEmState.splice(existingPickIndex, 1);
            setPickEmState(updatedPickEmState);
        } else {
            setPickEmState([...pickEmState, pick]);
        }
        setBottomSpreadClicked(!bottomSpreadClicked);
    };
    

    return (
        <>
        <div className={`pickem-card${!isMobile ? '-desktop' : ''}`}>
        <div className={`top-border${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: topBorderColor }}>
        <div 
                className={`info-icon${!isMobile ? '-desktop' : ''}`} 
                onClick={() => {handleMoreInfoClick()}}
                style={{ cursor: 'pointer', display: 'flex', alignItems: 'center'}}
            >
                <InfoIcon sx={{ fontSize: isMobile ? '15px' : '20px', color: topLeftTextColor}}/>
            </div>
            <div className={`top-left-text${!isMobile ? '-desktop' : ''}`} style={{ color: topLeftTextColor, width: isMobile ? '167px' : '175px', marginLeft: isMobile ? '13px' : '-24px', whiteSpace: 'nowrap' }}>
                {topLeftText}
            </div>
            <div className={`top-right-text${!isMobile ? '-desktop' : ''}`} style={{ color: topRightTextColor }}>{topRightText}</div>
        </div>
    
        <div className="middle-box">
            <div className={`team-row${!isMobile ? '-desktop' : ''}`}>
                <div className={`logo${!isMobile ? '-desktop' : ''}`}><img src={awayLogo} alt="Top Team Logo" /></div>
                <div className={`name-and-record${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWonWithSpread === awayId ? "bold" : '' }}>
                    {awayName} ({awayWins || 0}-{awayLosses || 0}{awayTies > 0 && `-${awayTies}`})
                </div>
                <div className={`score${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWonWithSpread === awayId ? "bold" : '' }}>{awayScore}</div>
                {(gameLockedIn && !gameFinalized && bottomSpreadClicked) || ((hasGameStarted || gameStatus === "InProgress") && !gameLockedIn && !gameFinalized ) || !doesUserBelong ? 
                    <div className={`pick-button${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: "#FFF" }}>
                        <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`}><LockIcon style={{ fontSize: 18 }} /></div>
                    </div> 
                    : 
                    ((!gameLockedIn && gameFinalized && whoWonWithSpread === homeId) || (gameLockedIn && gameFinalized && bottomSpreadClicked)) ? 
                    <div className={`empty-button${!isMobile ? '-desktop' : ''}`}></div> 
                    : 
                    <div className={`pick-button${!isMobile ? '-desktop' : ''} ${topSpreadClicked ? 'clicked' : ''}`} onClick={handleTopClick} style={{ backgroundColor: buttonBackgroundColor, border: `1px solid ${buttonBorderColor}`, cursor: 'pointer' }}>
                        <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`} style={{ color: buttonFontColor }}>
                            {topSpread ? (topSpread > 0 ? `+${topSpread}` : topSpread) : <LockIcon style={{ fontSize: 18, color: '#000' }} />}
                        </div>
                    </div>
                }           
            </div>
            <div className={`team-row${!isMobile ? '-desktop' : ''}`}>
                <div className={`logo${!isMobile ? '-desktop' : ''}`}><img src={homeLogo} alt="Bottom Team Logo" /></div>
                <div className={`name-and-record${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWonWithSpread === homeId ? "bold" : '' }}>
                    {homeName} ({homeWins || 0}-{homeLosses || 0}{homeTies > 0 && `-${homeTies}`})
                </div>
                <div className={`score${!isMobile ? '-desktop' : ''}`} style={{ fontWeight: whoWonWithSpread === homeId ? "bold" : '' }}>{homeScore}</div>
                {(gameLockedIn && !gameFinalized && topSpreadClicked) || ((hasGameStarted || gameStatus === "InProgress") && !gameLockedIn && !gameFinalized ) || !doesUserBelong ? 
                    <div className={`pick-button${!isMobile ? '-desktop' : ''}`} style={{ backgroundColor: "#FFF" }}>
                        <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`}><LockIcon style={{ fontSize: 18 }} /></div>
                    </div> 
                    : 
                    ((!gameLockedIn && gameFinalized && whoWonWithSpread === awayId) || (gameLockedIn && gameFinalized && topSpreadClicked)) ? 
                    <div className={`empty-button${!isMobile ? '-desktop' : ''}`}></div> 
                    : 
                    <div className={`pick-button${!isMobile ? '-desktop' : ''} ${bottomSpreadClicked ? 'clicked' : ''}`} onClick={handleBottomClick} style={{ backgroundColor: buttonBackgroundColor, border: `1px solid ${buttonBorderColor}`, cursor: 'pointer' }}>
                        <div className={`pick-button-text${!isMobile ? '-desktop' : ''}`} style={{ color: buttonFontColor }}>
                            {bottomSpread ? (bottomSpread > 0 ? `+${bottomSpread}` : bottomSpread) : <LockIcon style={{ fontSize: 18, color: '#000' }} />}
                        </div>
                    </div>
                }
            </div>
        </div>
    
        <div className={`bottom-border${!isMobile ? '-desktop' : ''}`}>
            <div className={`bottom-left-text${!isMobile ? '-desktop' : ''}`} style={{ color: bottomLeftTextColor }}>{bottomLeftText}</div>
            <div className={`bottom-right-text${!isMobile ? '-desktop' : ''}`}>{bottomRightText}</div>
        </div>
    </div>

    {moreInfoOpen && (
        <NFLMoreInfoModal 
            moreInfoOpen={moreInfoOpen} 
            setMoreInfoOpen={setMoreInfoOpen} 
            selectedGame={selectedGame} 
            setSelectedGame={setSelectedGame}
            NFLTeams={NFLTeams}
            NFLScoresBySeason={NFLScoresBySeason} 
        />
    )}
    </>




    );


  };

export default NFLPickEmCard;