import React from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Close from '@mui/icons-material/Close';
import { DateTime } from 'luxon';


const SurvivorUserStatsPickBox = ({ startingWeekState, usersPicks, NFLTeams, currentWeeksDeadlineDateTime, areTheseMyStats }) => {

    const teamLogo = (teamId) => {
        return NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
    };

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("md"));

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    //console.log("Current Week Deadline",currentWeeksDeadlineDateTime, "Time Now in New York:", timeNowInNewYork);

    //const dummyTimeForTesting = DateTime.fromISO('2024-07-29T13:00:00.000-04:00', { zone: 'America/New_York' });

    //const hasThisWeeksDeadlinePassed = currentWeeksDeadlineDateTime ? timeNowInNewYork > currentWeeksDeadlineDateTime : false;
    //const hasThisWeeksDeadlinePassedDummy = timeNowInNewYork > dummyTimeForTesting;
    //console.log("Has This Week's Deadline Passed?", hasThisWeeksDeadlinePassed, "Has This Week's Deadline Passed Dummy?", hasThisWeeksDeadlinePassedDummy);


    return (
        <Box>
        <TableContainer 
                component={Paper} 
                className="leaderboard-table-container"
                sx={{
                    maxWidth: isMobile ? '312px' : '800px',
                    margin: '0 auto',
                    borderRadius: '0',
                    minWidth: isMobile ? '144px' : '560px',
                }}
                >
                <Table>
                    <TableHead sx={{ backgroundColor: '#002129' }}>
                    <TableRow
                        sx={{
                            height: isMobile ? '17px' : '66px',
                            padding: 0,
                            margin: 0,
                        }}
                    >
                        {[...Array(startingWeekState)].map((_, index) => (
                            <TableCell 
                            key={index} 
                            className="leaderboard-table-header"
                            sx={{
                                minWidth: isMobile ? '58px' : '58px',
                                color: '#FFF',
                                fontSize: isMobile ? '14px' : '16px',
                                fontWeight: 700,
                                textAlign: 'center', // Center the text within the cell
                                whiteSpace: isMobile ? 'nowrap' : 'normal',
                                padding: 0,
                                wordBreak: isMobile ? 'normal' : 'break-word',
                            }}
                            >
                            {isMobile ? (
                                `W${startingWeekState - index}`
                            ) : (
                                <>
                                Week <br/>{startingWeekState - index}
                                </>
                            )}
                            </TableCell>
                        ))}
                    </TableRow>

                    </TableHead>
                    <TableBody>
                        <TableRow
                            sx={{
                                height: isMobile ? '52px' : '58px',
                            }}
                        >
                                {[...Array(startingWeekState)]?.map((_, weekIndex) => {
                                const weekNumber = startingWeekState - weekIndex;
                                const pick = usersPicks?.find(pick => pick.week === weekNumber);
                                //console.log("Pick", pick);
                                const pickTime = pick ? DateTime.fromISO(pick.dateTime) : null;
                                const isCurrentWeek = weekNumber === startingWeekState;
                                const hasDeadlinePassed = timeNowInNewYork > currentWeeksDeadlineDateTime;
                                const hasGameStarted = pickTime ? timeNowInNewYork > pickTime : false;
                                

                                return (
                                    <TableCell
                                        key={weekIndex}
                                        sx={{
                                            textAlign: 'center',
                                            minWidth: isMobile ? '58px' : '58px',
                                            padding: 0,
                                        }}
                                    >
                                        {pick ? (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    border: pick?.result === 'Win' ? '2px solid #00AA72' : (pick?.result === 'Loss' ? '2px solid #CC293C' : '1px solid #ccc'),
                                                    background: pick?.result === 'Win' ? '#CCEEE3' : ((pick?.result === 'Loss' && pick?.teamId !== "No Pick") ? '#F5D4D8' : '#f9f9f9'),
                                                    width: isMobile ? '32px' : '40px',
                                                    height: isMobile ? '32px' : '40px',
                                                    overflow: 'hidden',
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {isCurrentWeek && !hasDeadlinePassed && !hasGameStarted && !areTheseMyStats && pick?.teamId !== "No Pick" ? (
                                                    // If it's the current week and the deadline hasn't passed, show only the box
                                                    <Box
                                                        sx={{
                                                            width: isMobile ? '32px' : '40px',
                                                            height: isMobile ? '32px' : '40px',
                                                            backgroundColor: '#FFF',
                                                            border: '1px solid #ccc',
                                                        }}
                                                    />
                                                ) : (
                                                    // Show the logo or a placeholder if not the current week or if the deadline has passed
                                                    pick.teamId === 'No Pick' ? (
                                                        <Close
                                                            sx={{
                                                                display: 'flex',
                                                                justifyContent: 'center',
                                                                alignItems: 'center',
                                                                color: '#CC293C',
                                                                width: isMobile ? '18px' : '24px',
                                                                height: isMobile ? '18px' : '24px',
                                                                margin: '0 auto',
                                                            }}
                                                        />
                                                    ) : (
                                                        <img
                                                            src={teamLogo(pick.teamId)}
                                                            alt={`Team ${pick.teamId}`}
                                                            style={{
                                                                width: '100%',
                                                                height: 'auto',
                                                            }}
                                                        />
                                                    )
                                                )}
                                            </Box>
                                        ) : (
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: isMobile ? '32px' : '40px',
                                                    height: isMobile ? '32px' : '40px',
                                                    border: '1px solid #ccc',
                                                    margin: '0 auto',
                                                }}
                                            >
                                                {/* Blank cell or placeholder */}
                                            </Box>
                                        )}
                                    </TableCell>
                                );
                            })}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
            </Box>
    );

};

export default SurvivorUserStatsPickBox;
