import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import CFBPicksDisplayRow from './CFBPicksDisplayRow';
import TableDropdown from '../../TableDropdown/TableDropdown';
import { DateTime } from 'luxon';
import CFBSurvivorUserStatsModal from '../../GameModals/SurvivorModals/CFBSurvivorUserStats/CFBSurvivorUserStatsModal';


const CFBSurvivorPoolPicks = ({ handleClickState, leaderboardData, currentWeeksDeadlineDateTime }) => {
 
  const { 
    startingWeekState,
    CFBGamesByWeek,
    week,
    userPicksFromDB,
    myUsername,
    myUserId,
    doesUserBelong, 
    weekOptions,
    CFBTeams,
    CFBNames,
    teamFilter,
   } = useCFBSurvivorContext();

     // Current time in New York timezone
  const timeInNYNow = DateTime.now().setZone('America/New_York');
    
  //const dummyDate = DateTime.fromISO('2024-09-15T12:00:00.000-05:00').setZone('America/New_York');
  //console.log("DUMMY DATE", dummyDate);

  
  const [seasonVsWeek, setSeasonVsWeek] = useState(`Week ${startingWeekState}`);
  //console.log("Pool Picks Leaderboard Data:", leaderboardData);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const selectedWeek = seasonVsWeek?.replace(/\D/g, '');


  const weeklySchedule = CFBGamesByWeek?.filter(game => String(game.Week) === selectedWeek);
  const sortedSchedule = weeklySchedule?.sort((a, b) => new Date(a.DateTime) - new Date(b.DateTime));
  //console.log("Pool Picks Weekly Schedule:", sortedSchedule);

    // Initializing state for loss weeks
  const [lossWeekACC, setLossWeekACC] = useState(null);
  const [lossWeekBig12, setLossWeekBig12] = useState(null);
  const [lossWeekBigTen, setLossWeekBigTen] = useState(null);
  const [lossWeekSEC, setLossWeekSEC] = useState(null);
  const [userStatsOpen, setUserStatsOpen] = useState(false);
  const [userStatsInfo, setUserStatsInfo] = useState(null);
  

  // //const gamesNotStartedCount = 1;
  // const gamesNotStartedCount = weeklySchedule?.filter(game => game.Status === 'Scheduled' || game.Status === 'Delayed').length;
  // console.log("Games Not Started Count:", gamesNotStartedCount);

  // Function to calculate the true count
  const calculateTrueCount = (user) => {
    const trueCount = [
        user.doesUserHaveACCLoss,
        user.doesUserHaveBig12Loss,
        user.doesUserHaveBigTenLoss,
        user.doesUserHaveSECLoss
    ].filter(Boolean).length;
    return 4 - trueCount; // Subtract from 4 to get the desired sorting score
  };

  // Sorting function
  const sortedData = leaderboardData?.sort((a, b) => {
    if (b.totalWins !== a.totalWins) {
        return b.totalWins - a.totalWins; // Sort by totalWins first
    }

    // Calculate true counts for sorting
    const trueCountA = calculateTrueCount(a);
    const trueCountB = calculateTrueCount(b);

    if (trueCountB !== trueCountA) {
        return trueCountB - trueCountA; // Sort by trueCount second
    }

    return b.totalMargin - a.totalMargin; // If both totalWins and trueCount are the same, sort by totalMargin
  });


  //console.log("Pool Picks Sorted Data:", sortedData);
  const usersRow = doesUserBelong ? sortedData?.find(user => user.username === myUsername) : null;
  //console.log("Pool Picks Users Row:", usersRow);

  // Update loss week variables using useEffect
useEffect(() => {
  if (usersRow) {
    // Use a temporary object to extract values
    const {
      lossWeekACC: tempLossWeekACC,
      lossWeekBig12: tempLossWeekBig12,
      lossWeekBigTen: tempLossWeekBigTen,
      lossWeekSEC: tempLossWeekSEC,
    } = usersRow;

    // Set state values
    setLossWeekACC(tempLossWeekACC);
    setLossWeekBig12(tempLossWeekBig12);
    setLossWeekBigTen(tempLossWeekBigTen);
    setLossWeekSEC(tempLossWeekSEC);
  }
}, [usersRow]);
  


  const noPickConferences = [];
      if (lossWeekACC !== null && lossWeekACC < selectedWeek) noPickConferences.push("ACC");
      if (lossWeekBig12 !== null && lossWeekBig12 < selectedWeek) noPickConferences.push("Big 12");
      if (lossWeekBigTen !== null && lossWeekBigTen < selectedWeek) noPickConferences.push("Big Ten");
      if (lossWeekSEC !== null && lossWeekSEC < selectedWeek) noPickConferences.push("SEC");

     

  const usersFullPicks = userPicksFromDB ? userPicksFromDB : [];
  const userPicks = usersFullPicks?.filter(pick => String(pick.week) === selectedWeek);
    //console.log("Pool Picks Users Row:", usersRow, usersFullPicks, userPicks);

     // Add "No Pick" entries for conferences where the user has been eliminated
     noPickConferences.forEach(conf => {
      const hasNoPick = userPicks?.some(pick => pick.conference === conf && pick.teamId === "No Pick");
      // console.log(`Checking for existing 'No Pick' for ${conf} conference, User: ${username} - ${hasNoPick}`);

      // Only add "No Pick" if it doesn't exist in filteredPicks
      if (!hasNoPick) {
          // console.log(`Adding 'No Pick' for ${conf} conference, User: ${username}`);
          userPicks.push({
              teamId: "No Pick",
              week: selectedWeek,
              result: "Loss",
              conference: conf,
              dateTime: timeInNYNow.toISO()  // Ensure proper sorting
          });
      }
  });

  const userPicksSortedByDate = userPicks?.sort((a, b) => new Date(a.dateTime) - new Date(b.dateTime));

  const assignRanks = (data) => {
    let rank = 1;
    let tiedRank = 1;
    let previousPoints = data[0]?.totalWins;

    return data.map((entry, index) => {
      if (entry.totalWins === previousPoints) {
        entry.rank = tiedRank;
      } else {
        rank = index + 1;
        tiedRank = rank;
        entry.rank = rank;
      }
      previousPoints = entry.totalWins;
      return entry;
    });
  };

  const sortedDataWithRank = assignRanks(sortedData);

  function addNumberSuffix(number) {
    if (number % 100 >= 11 && number % 100 <= 13) {
      return `${number}th`;
    }
    switch (number % 10) {
      case 1:
        return `${number}st`;
      case 2:
        return `${number}nd`;
      case 3:
        return `${number}rd`;
      default:
        return `${number}th`;
    }
  }



  
//   const handleUserClick = (username, userId) => {
//     setSelectedUser(username);
//     setSelectedUserId(userId);
//     if (username === myUserName && userId === myUserId) {
//       setScorecardState(userScorecard);
//     } else {
//     const selectedUsersScorecard = getUserScorecard(username, userId);
//     setScorecardState(selectedUsersScorecard);
//     }
//     handleClickState('SCORE_CARD');
//   };








  return (
    <Box>
        <TableDropdown seasonVsWeek={seasonVsWeek} setSeasonVsWeek={setSeasonVsWeek} weekOptions={weekOptions}/>
        <TableContainer component={Paper} style={{overflowX: "clip", maxWidth:'750px', margin:'0 auto'}}>
        <Table>
            {usersRow &&
            <TableHead sx={{ backgroundColor: '#F0F1F1'}}>
                <TableRow style={{ height: '78px'}}>
                    <TableCell className="pool-picks-table-cells"  style={{ width: isMobile ? '160px' : '250px', height: isMobile ? '78px' : '96px', textAlign: 'left', fontSize: '12px' }}>
                        <Box sx={{ overflow: 'hidden', color: '#002129', textOverflow: 'ellipsis', fontFamily: 'Inter', fontSize: '12px', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>
                        <Typography 
                          style={{ 
                            fontSize: isMobile ? '12px' : '16px',
                            marginLeft: isMobile ? '2px' : '8px',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                            fontWeight: '700',
                          }}
                          onClick={() =>{
                            setUserStatsOpen(true);
                            setUserStatsInfo(usersRow);
                          }}
                        >
                          {myUsername}
                        </Typography>
                        <Typography style={{ fontSize: isMobile ? '12px' : '16px', marginLeft: isMobile ? '2px' : '8px'}}>{usersRow?.totalWins ?? 0} {usersRow?.totalWins === 1 ? "Win" : "Wins"}</Typography>
                        <Typography style={{ fontSize: isMobile ? '12px' : '16px', marginLeft: isMobile ? '2px' : '8px'}}>Win Margin: {usersRow?.totalMargin}</Typography>
                        </Box>
                    </TableCell>
                    <TableCell
                        colSpan={4} // Spans the last four columns
                        style={{
                        width: isMobile ? '216px' : '400px',
                        height: '78px',
                        fontSize: '12px',
                        padding: '0',
                        }}
                    >
                        <CFBPicksDisplayRow seasonVsWeek={seasonVsWeek} weeklySchedule={sortedSchedule} userPicks={userPicksSortedByDate} hiddenPicks={0} />
                    </TableCell>
                </TableRow>
            </TableHead>
            }

            <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
                <TableCell 
                sx={{ width: isMobile ? '160px' : '250px', fontWeight: 700, color: '#FFF', textAlign: 'left', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', paddingLeft: isMobile ? '2px' : '8px', transform: isMobile ? 'translateX(2px)' : 'translateX(8px)', }}
                >
                PLAYER
                </TableCell>
                <TableCell 
                sx={{ width: isMobile ? '54px' : '100px', fontWeight: 700, color: '#FFF', textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px' }}
                >
                ACC
                </TableCell>
                <TableCell className="pickem-leaderboard-table-cells"
                sx={{ width: isMobile ? '54px' : '100px', fontWeight: 700, color: '#FFF', textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px' }}
                >
                Big 12
                </TableCell>
                <TableCell className="pickem-leaderboard-table-cells"
                sx={{ width: isMobile ? '54px' : '100px', fontWeight: 700, color: '#FFF', textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px' }}
                >
                Big Ten
                </TableCell>
                <TableCell className="pickem-leaderboard-table-cells"
                sx={{ width: isMobile ? '54px' : '100px', fontWeight: 700, color: '#FFF', textAlign: 'center', position: 'relative', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px' }}
                >
                SEC
                </TableCell>
            </TableRow>
            </TableHead>

            <TableBody>
            {sortedDataWithRank?.map((usersEntry, index) => {
              const {
                username,
                totalWins,
                picks = [],
                rank,
                userId,
                lossWeekACC,
                lossWeekBig12,
                lossWeekBigTen,
                lossWeekSEC,
                totalMargin,
              } = usersEntry;

              const noPickConferences = [];
              if (lossWeekACC !== null && lossWeekACC < selectedWeek) noPickConferences.push("ACC");
              if (lossWeekBig12 !== null && lossWeekBig12 < selectedWeek) noPickConferences.push("Big 12");
              if (lossWeekBigTen !== null && lossWeekBigTen < selectedWeek) noPickConferences.push("Big Ten");
              if (lossWeekSEC !== null && lossWeekSEC < selectedWeek) noPickConferences.push("SEC");

              // If the entry is the current user's, use userPicksFromDB
              let filteredPicks;
              let hiddenPicks = 0;

              if (username === myUsername) {
                filteredPicks = userPicksFromDB
                  .filter(pick => String(pick.week) === selectedWeek)
                  .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));
              } else {
                // Adjust filtering based on whether the deadline has passed
                if (DateTime.now() > DateTime.fromISO(currentWeeksDeadlineDateTime)) {
                  filteredPicks = picks
                    .filter(pick => String(pick.week) === selectedWeek)
                    .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));
                  hiddenPicks = 0; // No hidden picks since the deadline has passed
                } else {
                  filteredPicks = picks
                    .filter(pick =>
                      (String(pick.week) === selectedWeek && DateTime.fromISO(pick.dateTime) < timeInNYNow) ||
                      pick.teamId === "No Pick"
                    )
                    .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));

                  const gamesToHide = picks
                    .filter(pick =>
                      (String(pick.week) === selectedWeek && DateTime.fromISO(pick.dateTime) > timeInNYNow) ||
                      pick.teamId === "No Pick"
                    )
                    .sort((a, b) => DateTime.fromISO(a.dateTime) - DateTime.fromISO(b.dateTime));

                  hiddenPicks = gamesToHide?.length;
                }
              }

              noPickConferences?.forEach(conf => {
                const hasNoPick = filteredPicks.some(pick => pick.conference === conf && pick.teamId === "No Pick");

                if (!hasNoPick) {
                  filteredPicks.push({
                    teamId: "No Pick",
                    week: selectedWeek,
                    result: "Loss",
                    conference: conf,
                    dateTime: timeInNYNow.toISO()
                  });
                }
              });
                // Final debug to check what is passed to the display component
                // console.log("Final Filtered Picks for", username, ":", filteredPicks);
                // console.log("Hidden Picks count for", username, ":", hiddenPicks);

                return (
                    <TableRow key={userId}
                        sx={{
                            backgroundColor: userId === myUserId ? '#FFF6DA' : index % 2 === 0 ? '#FFF' : '#F0F1F1',
                        }}
                    >
                        <TableCell className="pool-picks-table-cells" style={{ width: '128px', height: isMobile ? '78px' : '96px', textAlign: 'left', fontSize: isMobile ? '12px' : '14px' }}>
                            <Box sx={{ overflow: 'hidden', color: '#002129', textOverflow: 'ellipsis', fontFamily: 'Inter', fontStyle: 'normal', fontWeight: 500, lineHeight: 'normal' }}>
                                <Typography 
                                  style={{ 
                                      fontSize: isMobile ? '12px' : '16px', 
                                      marginLeft: isMobile ? '2px' : '8px',
                                      textDecoration: 'underline',
                                      cursor: 'pointer',
                                      fontWeight: '700',
                                    }}
                                  onClick={() => {
                                      setUserStatsOpen(true);
                                      setUserStatsInfo(usersEntry);
                                  }}
                                  >
                                    {username}
                                  </Typography>
                                <Typography style={{ fontSize: isMobile ? '12px' : '16px', marginLeft: isMobile ? '2px' : '8px'}}>{totalWins ?? 0} {totalWins === 1 ? "Win" : "Wins"}</Typography>
                                <Typography style={{ fontSize: isMobile ? '12px' : '16px', marginLeft: isMobile ? '2px' : '8px'}}>Win Margin: {totalMargin}</Typography>
                            </Box>
                        </TableCell>
                        <TableCell
                            colSpan={4} // Update the colSpan to span all 4 columns
                            className="pool-picks-table-cells" 
                            style={{ width:'247px', height: '78px', fontSize: '12px'}}
                        >
                            <CFBPicksDisplayRow seasonVsWeek={seasonVsWeek} weeklySchedule={sortedSchedule} userPicks={filteredPicks} hiddenPicks={hiddenPicks} />
                        </TableCell>                
                    </TableRow>
                );
            })}

            </TableBody>


        
        </Table>
      </TableContainer>
      {userStatsOpen && (
                <CFBSurvivorUserStatsModal 
                    statsModalOpen={userStatsOpen}
                    setStatsModalOpen={setUserStatsOpen}
                    myUsername={userStatsInfo.username}
                    startingWeekState={startingWeekState}
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                    CFBTeams={CFBTeams}
                    CFBNames={CFBNames}
                    areTheseMyStats={userStatsInfo.username === myUsername}
                    usersPicks={userStatsInfo.username === myUsername ? userPicksFromDB : userStatsInfo.picks}
                    teamFilter={teamFilter}
                    doesUserHaveACCLoss={userStatsInfo.doesUserHaveACCLoss}
                    lossWeekACC={userStatsInfo.lossWeekACC}
                    doesUserHaveBig12Loss={userStatsInfo.doesUserHaveBig12Loss}
                    lossWeekBig12={userStatsInfo.lossWeekBig12}
                    doesUserHaveBigTenLoss={userStatsInfo.doesUserHaveBigTenLoss}
                    lossWeekBigTen={userStatsInfo.lossWeekBigTen}
                    doesUserHaveSECLoss={userStatsInfo.doesUserHaveSECLoss}
                    lossWeekSEC={userStatsInfo.lossWeekSEC}
                />
            )}
  </Box>

  );
};

export default CFBSurvivorPoolPicks;