import React, { useState, useEffect } from 'react';
import { useNFLSurvivorContext } from '../context/NFLSurvivorContext';
import '../NFLSurvivorStyles/SurvivorWeeklyPicks.scss'
import { Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useNavigate } from 'react-router-dom';

const WeeklyPicksBar = ({ deadline, leadingPlaceholder, setLeadingPlaceholder, trailingPlaceholder, setTrailingPlaceholder }) => {
    const { NFLTeams, 
        NFLStandings, 
        NFLStadiums, 
        NFLGameOddsByWeek, 
        NFLSchedules, 
        NFLScoresBySeason, 
        pickEmState, 
        setPickEmState, 
        userPicksFromDB,
        mainGameId,
        gameAddress,
        week,
        setWeek,
        doesUserHaveALoss,
        lossWeek,
        winBoxColor,
        winBoxBackgroundColor,
        lossBoxColor,
        lossBoxBackgroundColor,
        pushBoxColor,
        pushBoxBackgroundColor,
    } = useNFLSurvivorContext();
    //console.log("Loss Week:", lossWeek, "Does User Have A Loss:", doesUserHaveALoss, "UserPicks From DB:", userPicksFromDB);
    

    //console.log("DEADLINE:", deadline);

    const navigate = useNavigate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }
    
    const [leftSideGame, setLeftSideGame] = useState([]);
    const [rightSideGame, setRightSideGame] = useState([]);
    const [middleGame, setMiddleGame] = useState([]);
    const lowerBound = 1;
    const upperBound = 18;

    useEffect(() => {
        const left = userPicksFromDB?.find(pick => pick.week === week - 1);
        const right = userPicksFromDB?.find(pick => pick.week === week + 1);
        const middle = userPicksFromDB?.find(pick => pick.week === week);
        //console.log("Left:", left, "Middle:", middle, "Right:", right, "Does User Have A Loss:", doesUserHaveALoss, "Loss Week:", lossWeek, "Week:", week);
        //console.log("DoesUser have a loss and loss weeks is less than week - 1:", doesUserHaveALoss && (lossWeek < week - 1));

        setLeftSideGame(doesUserHaveALoss && (lossWeek < (week - 1)) ? "ELIMINATED" : left || "NO PICK");
        setRightSideGame(doesUserHaveALoss && lossWeek < week + 1 ? "ELIMINATED" : right || "NO PICK");
        setMiddleGame(doesUserHaveALoss && lossWeek < week ? "ELIMINATED" : middle || "NO PICK");

    }, [week, userPicksFromDB, doesUserHaveALoss, lossWeek]);

    //console.log("Left Side Game:", leftSideGame, "Middle Game:", middleGame, "Right Side Game:", rightSideGame);

    const leftBorderColor = leftSideGame.result === "Win" ? winBoxColor : leftSideGame.result === "Loss" ? lossBoxColor : leftSideGame.result === "Push" ? pushBoxColor : "#000";
    const leftBorderBackgroundColor = leftSideGame.result === "Win" ? winBoxBackgroundColor : leftSideGame.result === "Loss" ? lossBoxBackgroundColor : leftSideGame.result === "Push" ? pushBoxBackgroundColor : "#FFF";
    const middleBorderColor = middleGame.result === "Win" ? winBoxColor : middleGame.result === "Loss" ? lossBoxColor : middleGame.result === "Push" ? pushBoxColor : "#000";
    const middleBorderBackgroundColor = middleGame.result === "Win" ? winBoxBackgroundColor : middleGame.result === "Loss" ? lossBoxBackgroundColor : middleGame.result === "Push" ? pushBoxBackgroundColor : "#FFF";
    const rightBorderColor = rightSideGame.result === "Win" ? winBoxColor : rightSideGame.result === "Loss" ? lossBoxColor : rightSideGame.result === "Push" ? pushBoxColor : "#000";
    const rightBorderBackgroundColor = rightSideGame.result === "Win" ? winBoxBackgroundColor : rightSideGame.result === "Loss" ? lossBoxBackgroundColor : rightSideGame.result === "Push" ? pushBoxBackgroundColor : "#FFF";
    
    const modifyDate = (dateString, daysToModify) => {
        const date = new Date(dateString);
        date.setDate(date.getDate() + daysToModify);
        return date.toISOString(); // Returns ISO string format
    }

    const formatDate = (dateString) => {
        //console.log("Date String:", dateString);
        const date = new Date(dateString);
        const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        const day = date.getDate();
        return `${month} ${day}`;
    }

    const nflLogo = (teamId) => {
        return NFLTeams?.find(team => team.TeamID === teamId)?.WikipediaLogoURL;
    }

    function nflKey (teamId) {
        return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Key;
    }

    function nflOpponentInfo (gameId, teamId)  {
        const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
        const isPickHome = game?.GlobalHomeTeamID === teamId;
        const opponentKey = isPickHome ? nflKey(game?.GlobalAwayTeamID) : nflKey(game?.GlobalHomeTeamID);
        const leadingSymbol = isPickHome ? 'vs' : '@';
        const displayString = `${leadingSymbol} ${opponentKey}`;
        return displayString;
    }

    const handleWeekChange = (direction) => {
        let newWeek = week;
        if (direction === 'prev') {
            newWeek -= 1;
        } else {
            newWeek += 1;
        }

        if (newWeek === 1) {
            setLeadingPlaceholder(true);
        } else if (newWeek === 18) {
            setTrailingPlaceholder(true);
        }

        if (newWeek !== 1) {
            setLeadingPlaceholder(false);
        } 
        if (newWeek !== 18) {
            setTrailingPlaceholder(false);
        }

        if (newWeek >= lowerBound && newWeek <= upperBound) {
                setWeek(newWeek);
        }
        navigate(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded('MY_PICKS')}/${newWeek}`);

    };

    function nflKey(teamId) {
        return NFLTeams?.find(team => team.TeamID === teamId)?.Key;
    }
    

    return (
        
        <div className={`pick-status-bar${!isMobile ? '-desktop' : ''}`}>
    <div
        className={`arrow${!isMobile ? '-desktop' : ''} ${leadingPlaceholder ? "placeholder" : ""}`}
        onClick={() => {
            if (!leadingPlaceholder) {
                //console.log("Arrow Hit");
                handleWeekChange('prev');
            }
        }}
    >
        <ArrowBackIosNewIcon className={`icons${!isMobile ? '-desktop' : ''}`} />
    </div>
    <div
        className={`not-selected-container${!isMobile ? '-desktop' : ''} ${leadingPlaceholder ? "placeholder" : ""}`}
        style={{ borderColor: leadingPlaceholder ? "#FFF" : "" }}
        onClick={() => {
            if (!leadingPlaceholder) {
                //console.log("Not Selected Container Hit");
                handleWeekChange('prev');
            }
        }}
    >
        <div className={`not-selected${!isMobile ? '-desktop' : ''}`}>
            <div className={`not-selected-top-text-container${!isMobile ? '-desktop' : ''}`}>
                <a className={`not-selected-top-text${!isMobile ? '-desktop' : ''}`}>Week {week - 1}</a>
            </div>
            <div className={`not-selected-logo-container${!isMobile ? '-desktop' : ''}`}>
                {(leftSideGame === "ELIMINATED" || leftSideGame?.teamId === "No Pick") ? 
                    <div
                        style={{ 
                            border: '1px solid #CC293C',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <CloseIcon style={{ margin: '0 auto', color: '#CC293C', fontSize: isMobile ? '24px' : '40px' }} />
                    </div> : 
                    (leftSideGame === "NO PICK" && week !== 1) ? 
                    <div
                        style={{ 
                            width: isMobile ? '24px' : '40px', 
                            height: isMobile ? '24px' : '40px',
                            objectFit: 'contain', 
                            border: isMobile ? `1px solid #757575` : `1px solid #757575`, 
                            backgroundColor: '#E5E5E5' 
                        }} 
                    /> :
                    <img
                        className={`not-selected-logo${!isMobile ? '-desktop' : ''}`}
                        loading="lazy"
                        alt=""
                        src={nflLogo(leftSideGame?.teamId)}
                        style={{
                            objectFit: 'contain', 
                            border: `1px solid ${leftBorderColor}`,
                            backgroundColor: leftBorderBackgroundColor 
                        }} 
                    />
                }
            </div>

            <div className={`not-selected-bottom-text-container${!isMobile ? '-desktop' : ''}`}>
                <a className={`not-selected-bottom-text${!isMobile ? '-desktop' : ''}`} style={{ color: leftSideGame === "ELIMINATED" || leftSideGame?.teamId === "No Pick" ? '#CC293C' : 'inherit' }}>
                    {leftSideGame === "ELIMINATED" 
                        ? "OUT" 
                        : leftSideGame?.teamId === "No Pick" ? "NO PICK"
                        : leftSideGame === "NO PICK" 
                            ? formatDate(modifyDate(deadline, -7)) 
                            : nflOpponentInfo(leftSideGame?.gameId, leftSideGame?.teamId)}
                </a>
            </div>
        </div>
    </div>
    <div className={`selected-container${!isMobile ? '-desktop' : ''}`}>
        <div className={`selected${!isMobile ? '-desktop' : ''}`}>
            <div className={`selected-top-text-container${!isMobile ? '-desktop' : ''}`}>
                <a className={`selected-top-text${!isMobile ? '-desktop' : ''}`}>Week {week}</a>
            </div>
            <div className={`selected-logo-container${!isMobile ? '-desktop' : ''}`}>
                {(middleGame === "ELIMINATED" || middleGame?.teamId === "No Pick") ? 
                    <div
                        style={{
                            border: '1px solid #CC293C',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CloseIcon style={{ margin: '0 auto', color: '#CC293C', fontSize: isMobile ? '32px' : '56px' }} />
                    </div> : 
                    (middleGame === "NO PICK") ? 
                    <div
                        style={{ 
                            width: isMobile ? '32px' : '56px',
                            height: isMobile ? '32px' : '56px',
                            objectFit: 'contain', 
                            border: `1px solid #757575`,
                            backgroundColor: '#E5E5E5' 
                        }} 
                    />  :
                    <img
                        className={`selected-logo${!isMobile ? '-desktop' : ''}`}
                        loading="lazy"
                        alt=""
                        src={nflLogo(middleGame?.teamId)}
                        style={{
                            objectFit: 'contain', 
                            border: `1px solid ${middleBorderColor}`,
                            backgroundColor: middleBorderBackgroundColor 
                        }} 
                    />
                }
            </div>
            <div className={`selected-bottom-text-container${!isMobile ? '-desktop' : ''}`}>
                <a className={`selected-bottom-text${!isMobile ? '-desktop' : ''}`} style={{ color: middleGame === "ELIMINATED" || middleGame?.teamId === "No Pick" ? '#CC293C' : 'inherit' }}>
                    {middleGame === "ELIMINATED"
                        ? "OUT"
                        : middleGame?.teamId === "No Pick" ? "NO PICK"
                        : middleGame === "NO PICK"
                            ? formatDate(deadline)
                            : nflOpponentInfo(middleGame?.gameId, middleGame?.teamId)}
                </a>
            </div>
        </div>
    </div>
    <div
        className={`not-selected-container${!isMobile ? '-desktop' : ''} ${trailingPlaceholder ? "placeholder" : ""}`}
        style={{ borderColor: trailingPlaceholder ? "#FFF" : "" }}
        onClick={() => {
            if (!trailingPlaceholder) {
                //console.log("Not Selected Container Hit");
                handleWeekChange('next');
            }
        }}
    >
        <div className={`not-selected${!isMobile ? '-desktop' : ''}`}>
            <div className={`not-selected-top-text-container${!isMobile ? '-desktop' : ''}`}>
                <a className={`not-selected-top-text${!isMobile ? '-desktop' : ''}`}>Week {week + 1}</a>
            </div>
            <div className={`not-selected-logo-container${!isMobile ? '-desktop' : ''}`}>
                {((rightSideGame === "ELIMINATED" || rightSideGame?.teamId === "No Pick") && !trailingPlaceholder) ? (
                    <div
                        style={{ 
                            border: '1px solid #CC293C',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <CloseIcon style={{ margin: '0 auto', color: '#CC293C', fontSize: isMobile ? '24px' : '40px' }} />
                    </div>
                ) : rightSideGame === "NO PICK" && week !== 18 ? (
                    <div
                        style={{ 
                            width: isMobile ? '24px' : '40px',
                            height: isMobile ? '24px' : '40px',
                            objectFit: 'contain', 
                            border: '1px solid #757575', 
                            backgroundColor: '#E5E5E5' 
                        }}
                    />
                ) : (
                    <img
                        className={`not-selected-logo${!isMobile ? '-desktop' : ''}`}
                        loading="lazy"
                        alt=""
                        src={nflLogo(rightSideGame?.teamId)}
                        style={{
                            objectFit: 'contain', 
                            border: `1px solid ${rightBorderColor}`,
                            backgroundColor: rightBorderBackgroundColor 
                        }}
                    />
                )}
            </div>

            <div className={`not-selected-bottom-text-container${!isMobile ? '-desktop' : ''}`}>
                <a className={`not-selected-bottom-text${!isMobile ? '-desktop' : ''}`} style={{ color: rightSideGame === "ELIMINATED" || rightSideGame?.teamId === "No Pick" ? '#CC293C' : 'inherit' }}>
                    {rightSideGame === "ELIMINATED"
                        ? "OUT"
                        : rightSideGame?.teamId === "No Pick" ? "NO PICK"
                        : (rightSideGame === "NO PICK" && week !== 18)
                            ? formatDate(modifyDate(deadline, 7))
                            : nflOpponentInfo(rightSideGame?.gameId, rightSideGame?.teamId)}
                </a>
            </div>
        </div>
    </div>
    <div
        className={`arrow${!isMobile ? '-desktop' : ''} ${trailingPlaceholder ? "placeholder" : ""}`}
        onClick={() => {
            if (!trailingPlaceholder) {
                //console.log("Arrow Hit");
                handleWeekChange('next');
            }
        }}
    >
        <ArrowBackIosNewIcon className={`icons${!isMobile ? '-desktop' : ''}`} style={{ transform: 'rotate(180deg)' }} />
    </div>
</div>


    );
}

export default WeeklyPicksBar;
