import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage } from "../../redux/actions/loginImport";
import { useJoinPublicPool } from './hooks/useJoinPublicPools';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import trophyImage from '../../assets/images/Layer_1.svg';
import SilverTrophy from '../../assets/images/SilverTrophy.svg';
import BronzeTrophy from '../../assets/images/BronzeTrophy.svg';

const PoolHallList = (props) => {
  const { pools } = props;
  const { mutate: joinPool } = useJoinPublicPool();
  const userData = getUserDataFromLocalStorage();
  const navigateToTop = useNavigateToTop();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [openPool, setOpenPool] = useState(null); // Track open pool
  const [status, setStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  };

  const joinGame = async (gameID, gameType) => {
    let data = {
      gameID: gameID,
      userID: userData.user_id,
      gameType: gameType
    };

    joinPool(data, {
      onSuccess: () => {
        window.location.href = `/nfl-pickem/${uriEncoded(gameID)}/MY_PICKS`;
      },
      onError: (error) => {
        setStatus("fail");
        const message = error.response?.data?.message || 'Sorry an error occurred. Please try again.';
        setErrorMessage(message);
        console.error("Join pool failed:", error);

        setTimeout(() => {
          setErrorMessage('');
          setStatus(null);
        }, 5000);
      }
    });
  };

  const handleSelectClick = (gameID, gameAddress) => {
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameID)}/HOME/HOME`);
  };

  return (
    <>
      {pools?.map((pool) => (
        <Box
          key={pool.gameID}
          sx={{ 
            width: '95%',
            maxWidth: '700px',
            height: 'auto',
            minHeight: isMobile ? '64px' : '68px',
            borderRadius: '8px',
            backgroundColor: '#fff',
            border: '2px solid #e8e8e8',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            padding: '16px',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            gap: '16px',
            textAlign: 'left',
            fontSize: '16px',
            color: '#000',
            fontFamily: 'Inter',
            borderBottom: '1px solid #ddd',
            margin: '0 auto',
            alignSelf: 'stretch',
            marginBottom: '16px',
          }}
        >
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Box display="flex" alignItems="center" gap="8px">
              <KeyboardArrowDownIcon 
                onClick={() => setOpenPool(openPool === pool.gameID ? null : pool.gameID)}
                sx={{ 
                  marginRight: '8px',
                  transition: 'transform 0.1s ease', // Smooth rotation transition
                  transform: openPool === pool.gameID ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate icon if pool is open
                }} 
              />
              <Typography 
                variant="h6" 
                component="div" 
                sx={{
                  fontSize: '16px',
                  fontWeight: '800'
                }}>
                {pool.gameName}
              </Typography>
            </Box>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => handleSelectClick(pool.gameID, pool.gameAddress)}
              sx={{ 
                width: '68px',
                height: '32px',
              }}
            >
              SELECT
            </Button>
          </Box>
          {openPool === pool.gameID && (
            <>
              <Box
                sx={{
                  maxWidth: isMobile ? '286px' : '628px',
                  marginLeft: '32px',
                  marginRight: '1px',
                }}
              >
                <Typography 
                  variant="h6" 
                  component="div"
                  sx={{ 
                    textAlign: 'left',
                    fontSize: isMobile ? '12px' : '16px',
                    fontWeight: '500',
                    marginLeft: isMobile ? '4px' : '5px',  
                  }}
                >
                  {pool.poolHallText}
                </Typography>
              </Box>

              {/* Parent container to hold the prize rows side by side */}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center', // Center the content horizontally
                  alignItems: 'center', // Center the content vertically
                  margin: '0 auto',
                  marginBottom: '15px',
                  flexWrap: 'wrap', // Allow wrapping if needed
                }}
              >
                {/* First prize */}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: '20px', // Add some space between prizes
                  }}
                >
                  <Box
                    component='img'
                    sx={{
                      display: 'block',
                      width: isMobile ? 16 : 24,
                      marginRight: '10px',
                    }}
                    alt='Pool Party Trophy'
                    src={trophyImage}
                  />
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: isMobile ? '12px' : '20px',
                    }}
                  >
                    1st: $250
                  </Typography>
                </Box>

                {/* Conditionally render the second, third prize, and last place text for "Pickem" */}
                {pool?.gameType === "Pickem" && (
                  <>
                    {/* Second prize */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: '20px', // Add some space between prizes
                      }}
                    >
                      <Box
                        component='img'
                        sx={{
                          display: 'block',
                          width: isMobile ? 16 : 24,
                          marginRight: '10px',
                        }}
                        alt='Pool Party Trophy'
                        src={SilverTrophy}
                      />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: isMobile ? '12px' : '20px',
                        }}
                      >
                        2nd: $100
                      </Typography>
                    </Box>

                    {/* Third prize */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        marginRight: '20px', // Add some space between prizes
                      }}
                    >
                      <Box
                        component='img'
                        sx={{
                          display: 'block',
                          width: isMobile ? 16 : 24,
                          marginRight: '10px',
                        }}
                        alt='Pool Party Trophy'
                        src={BronzeTrophy} // Add your bronze trophy image here
                      />
                      <Typography
                        sx={{
                          fontWeight: 600,
                          fontSize: isMobile ? '12px' : '20px',
                        }}
                      >
                        3rd: $50
                      </Typography>
                    </Box>
                  </>
                )}
              </Box>

              {/* Line of text for Last Place */}
              {pool?.gameType === "Pickem" && (
                <Box
                  sx={{
                    marginTop: '15px', // Add margin top to separate from prizes
                    textAlign: 'center', // Center the text
                    marginTop: '-20px', // Move the text up a bit
                  }}
                >
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: isMobile ? '12px' : '20px',
                    }}
                  >
                    Last Place*: $100
                  </Typography>
                </Box>
              )}
            </>
          )}




        </Box>
      ))}
    </>
  );
};

export default PoolHallList;
