import React, { useState, useEffect } from "react";
import { Box, Typography, TextField } from "@mui/material";
import '../BetSlip.scss';

const ParlayTeaserWagerInput = ({ wagerState, setWagerState, toWinState, setToWinState, bankrollExceeded, betOdds, pickState }) => {
    // const juice = bet?.juice;
    // console.log("Juice", juice, bet);
    console.log("BET ODDS IN INPUT", betOdds);
    const [wagerAmount, setWagerAmount] = useState(wagerState); // Initialize as an empty string
    const [toWinAmount, setToWinAmount] = useState(toWinState); // Initialize as an empty string

    // useEffect(() => {
    //     // Find the current bet from pickState
    //     const currentBet = pickState?.find((b) => b.betId === bet?.betId);
    //     if (currentBet) {
    //         setWagerAmount(currentBet?.wager?.toFixed(2) || '');
    //         setToWinAmount(currentBet?.toWin?.toFixed(2) || '');
    //     }
    // }, [bet.betId, pickState]);

    useEffect(() => {
        console.log("Running useEffect in Wager Input");
        // Find the current wager from WagerState
        setWagerAmount(wagerState);
        setToWinAmount(toWinState);
    }, [wagerState, toWinState]);

    useEffect(() => {
        console.log("Running useEffect in Wager Input for Bet Odds, Wager Amount", wagerAmount);
        // Recalculate toWinAmount based on the new betOdds and current wagerAmount
        if (wagerAmount !== '') {
            const newToWinAmount = calculatePayout(betOdds, parseFloat(wagerAmount));
            console.log("New To Win Amount", newToWinAmount);
            setToWinAmount(newToWinAmount.toFixed(2));
            setToWinState(newToWinAmount.toFixed(2));
        }
    }, [betOdds]);

    const handleWagerChange = (event) => {
        const wagerValue = event.target.value;
        setWagerAmount(wagerValue);
        setWagerState(wagerValue);
        if (wagerValue !== '') {
            const payout = calculatePayout(betOdds, parseFloat(wagerValue));
            setToWinAmount(payout.toFixed(2));
            setToWinState(payout.toFixed(2));
        } else {
            setToWinAmount('');
            setToWinState('');
        }
    };

    const handleToWinChange = (event) => {
        const toWinValue = event.target.value;
        setToWinAmount(toWinValue);
        setToWinState(toWinValue);
        if (toWinValue !== '') {
            const requiredWager = calculateWager(betOdds, parseFloat(toWinValue));
            setWagerAmount(requiredWager.toFixed(2));
            setWagerState(requiredWager.toFixed(2));
        } else {
            setWagerAmount('');
            setWagerState('');
        }
    };

    const handleBlur = () => {
        const wager = parseFloat(wagerAmount) || 0;
        const toWin = parseFloat(toWinAmount) || 0;

        setWagerAmount(wager.toFixed(2));
        setToWinAmount(toWin.toFixed(2));
        setWagerState(wager.toFixed(2));
        setToWinState(toWin.toFixed(2));
    };
    

    function calculatePayout(juice, wager) {
        let payout;
        if (juice > 0) {
            payout = (wager * juice) / 100;
        } else {
            payout = (wager * 100) / Math.abs(juice);
        }
        return payout;
    }

    function calculateWager(juice, desiredProfit) {
        let wager;
        if (juice > 0) {
            wager = (desiredProfit * 100) / juice;
        } else {
            wager = (desiredProfit * Math.abs(juice)) / 100;
        }
        return wager;
    }

    return (
        <Box className="sportsbook-wager-boxes-container">
            <Box className={`sportsbook-wager-box ${bankrollExceeded ? 'sportsbook-wager-box-exceeded' : ''}`}>
                <Typography className={`sportsbook-wager-box-label ${bankrollExceeded ? 'sportsbook-wager-box-label-exceeded' : ''}`}>
                    {!bankrollExceeded ? 'Wager' : 'Insufficient Funds'}
                </Typography>
                <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    value={wagerAmount}
                    onChange={handleWagerChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    inputProps={{ step: "0.01", min: "0" }} // Set step and min for number inputs
                    InputProps={{
                        startAdornment: (
                            <Typography className="sportsbook-wager-currency">
                                $
                            </Typography>
                        ),
                    }}
                    className="sportsbook-wager-input"
                />
            </Box>
            <Box className={`sportsbook-wager-box ${bankrollExceeded ? 'sportsbook-wager-box-exceeded' : ''}`}>
                <Typography className={`sportsbook-wager-box-label ${bankrollExceeded ? 'sportsbook-wager-box-label-exceeded' : ''}`}>
                    To Win
                </Typography>
                <TextField
                    variant="outlined"
                    type="number"
                    size="small"
                    value={toWinAmount}
                    onChange={handleToWinChange}
                    onBlur={handleBlur}
                    autoComplete="off"
                    inputProps={{ step: "0.01", min: "0" }} // Set step and min for number inputs
                    InputProps={{
                        startAdornment: (
                            <Typography className="sportsbook-wager-currency">
                                $
                            </Typography>
                        ),
                    }}
                    className="sportsbook-wager-input"
                />
            </Box>
        </Box>
    );
}

export default ParlayTeaserWagerInput;
