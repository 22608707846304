import React, { useState } from "react";
import { useCFBSurvivorContext } from "../context/CFBSurvivorContext";
import { Typography, Button, Box, Modal } from "@mui/material";
import CreatePartyInfo from "../../MyParty/CreatePartyInfo";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CFBSurvivorPoolRankingsTable from "./CFBSurvivorPoolRankingsTable";
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import { DateTime } from "luxon";
import { CardMedia } from "@mui/material";

const CFBSurvivorHome = () => {
    const { 
        setSignUpState, 
        gameAddress, 
        userData, 
        gameData, 
        setWeek, 
        startingWeekState, 
        userSubgroups, 
        doesUserBelong,
        myUserId,
        partyState,
        setPartyState,
        partyId,
        setPartyId,
        setDefaultPartyId,
        setDefaultPartyName,
        gameType,
        mainGameId,
        joinOpen,
        setJoinOpen
    } = useCFBSurvivorContext();

    const gameName = gameData?.gameName;
    //console.log("mainGameId", mainGameId, "Game Data", gameData, "User Data", userData);
    const logo = gameData?.clubLogo;
    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    }

    const handleOpenModal = (image) => {
        setShowModal(true);
        setModalImage(image);
    }

    

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleRulesClick = () => {
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/RULES/RULES`);
    }

   const handleJoinClick = () => {
        if (!userData) {
            navigateToTop(`/login/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/HOME/HOME`);
        } else {
            setJoinOpen(true);
        }
    }

    const nowInNewYork = DateTime.now().setZone('America/New_York');
    // Define the date and time in New York timezone
    const deadlineTimeInNewYork = DateTime.fromObject({
        year: 2024,
        month: 8, // August
        day: 31,
        hour: 12, // 12 PM in 24-hour format
        minute: 0,
        second: 0,
        millisecond: 0,
    }, { zone: 'America/New_York' });
    const hasJoinDeadlinePassed = nowInNewYork > deadlineTimeInNewYork;


    return (
        <div>
            <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        height: '160px', // 160px height + 32px margin top + 32px margin bottom
                        marginBottom: doesUserBelong ? '0px' : '32px',
                        marginTop: '32px'
                    }}
                >
                    <img 
                        src={logo} 
                        alt="Logo" 
                        style={{ 
                            height: '160px', 
                            width: '128px', 
                            objectFit: 'contain' 
                        }} 
                    />
                </Box>

                <Typography
                    style={{
                        color: '#002129',
                        textAlign: 'center',
                        fontSize: isMobile ? '16px' : '29px',
                        fontWeight: isMobile ? '700' : '800',
                        marginTop: doesUserBelong ? '32px' : '0px',
                        marginBottom: '16px'
                    }}
                >
                    The classic Survivor Pool fit to the {isMobile && <br />} NCAA Power 4 conferences.
                </Typography>

                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: isMobile ? 'flex-start' : 'center',
                        justifyContent: 'center',
                        width: isMobile ? '311px' : '1000px',
                        margin: '0 auto' // This centers the box horizontally
                    }}
                >
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: isMobile ? '311px' : '1000px',
                            margin: '0 auto',
                            marginBottom: '8px'
                        }}
                    >
                        <Typography 
                            variant="body1" 
                            component="span" 
                            sx={{ 
                                fontSize: isMobile ? '12px' : '14px',
                                textAlign: isMobile ? 'left' : 'center' 
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>Power 4 Picks </span>
                            -  Each week, pick one team to win from each of the Power 4 conferences (ACC, Big 12, Big Ten, and SEC). 
                        </Typography>
                    </Box>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: isMobile ? '311px' : '1000px',
                            margin: '0 auto' // This centers the box horizontally
                        }}
                    >
                        <Typography 
                            variant="body1" 
                            component="span" 
                            sx={{ 
                                fontSize: isMobile ? '12px' : '14px',
                                textAlign: isMobile ? 'left' : 'center', // Ensure left alignment for text
                                marginBottom: '16px'
                            }}
                        >
                            <span style={{ fontWeight: 700 }}>Conference Based Survival </span>
                            - A loss only knocks you out of that conference. You remain alive until you have missed in all Power 4 conferences. 
                        </Typography>
                    </Box>
                </Box>

                {doesUserBelong && (
                    <>
                        <Box 
                            sx={{ 
                                display: 'flex', 
                                flexDirection: isMobile ? 'column' : 'row', 
                                alignItems: 'center',
                                justifyContent: 'center', // Center buttons horizontally on desktop
                                gap: isMobile ? 0 : '40px', // Add space between buttons on desktop
                                mt: isMobile ? 0 : '32px' // Add top margin on desktop
                            }}
                        >
                            <Button 
                                variant="contained" 
                                sx={{ 
                                    fontSize: '14px', 
                                    fontWeight: 700, 
                                    width: '182px', 
                                    height: '36px', 
                                    mb: isMobile ? '8px' : 0 // Adjust margin bottom on mobile
                                }}
                                onClick={() => {
                                    setWeek(startingWeekState);
                                    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("MY_PICKS")}/${uriEncoded(startingWeekState)}/${uriEncoded("default")}`);
                                }}
                            >
                                MY PICKS
                            </Button>
                            <Button 
                                variant="contained"
                                onClick={() => { navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("RULES")}/${uriEncoded("RULES")}`); }}
                                sx={{ 
                                    fontSize: '14px', 
                                    fontWeight: 700, 
                                    width: '182px', 
                                    height: '36px'
                                }}
                            >
                                POOL RULES
                            </Button>
                        </Box>
                        {userSubgroups?.length > 0 && (
                            <>
                                <Box
                                    sx={{
                                        marginBottom: '16px', 
                                    }}
                                >
                                    <CFBSurvivorPoolRankingsTable 
                                        myUserId={myUserId} 
                                        gameId={mainGameId} 
                                        userSubgroups={userSubgroups} 
                                        partyState={partyState}
                                        setPartyState={setPartyState}
                                        partyId={partyId}
                                        setPartyId={setPartyId}
                                        setDefaultPartyId={setDefaultPartyId} 
                                        setDefaultPartyName={setDefaultPartyName}
                                    />
                                </Box>
                                <CreatePartyInfo 
                                    setSignUpState={setSignUpState} 
                                    gameAddress={gameAddress}
                                    doesUserBelong={doesUserBelong}
                                    gameName={gameName}
                                    setJoinOpen={setJoinOpen}
                                />
                            </>
                        )}
                    </>
                )}

            {(doesUserBelong && userSubgroups?.length === 0) && (
                <>
                    <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: isMobile ? '16px' : '24px',
                            fontWeight: 700,
                            textDecorationLine: 'underline',
                            marginTop: isMobile ? '16px' : '24px',
                            marginBottom: '8px',
                            marginTop: '32px'
                        }}
                        >
                        My Party
                    </Typography>
                    <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 600,
                            marginBottom: '16px'
                        }}
                        >
                        Create a CFB Power 4 Survivor competition <br/>with your custom group.
                    </Typography>
                    
                    <CreatePartyInfo 
                        setSignUpState={setSignUpState} 
                        gameAddress={gameAddress}
                        doesUserBelong={doesUserBelong}
                        gameName={gameName}
                        setJoinOpen={setJoinOpen}
                    />
                </>
            )}


            {!doesUserBelong && (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        gap: '16px', 
                        marginTop: '8px',
                    }}
                >
                    {!hasJoinDeadlinePassed && (
                    <Button 
                        variant="contained" 
                        onClick={() => handleJoinClick()}
                        sx={{ 
                            fontSize: '14px', 
                            fontWeight: '700', 
                            width: '100px', 
                            height: '36px', 
                            whiteSpace: 'nowrap' 
                        }}
                    >
                        JOIN POOL
                    </Button>
                    )}
                    <Button 
                        variant="contained"
                        onClick={() => { navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded("RULES")}/${uriEncoded("RULES")}`); }} 
                        sx={{ 
                            fontSize: '14px', 
                            fontWeight: '700', 
                            width: '100px', 
                            height: '36px' 
                        }}
                    >
                        RULES
                    </Button>
                </Box>
            )}

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '40px',
                    alignItems: 'start',
                    justifyItems: 'center',
                    width: isMobile ? '100%' : '1000px',
                    margin: '0 auto', // This centers the grid container horizontally
                    mt: '32px'
                }}
            >
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'centere',
                        justifyContent: 'center',
                        width: isMobile ? '311px' : '450px',
                        margin: isMobile ? '0 auto' : '0',
                    }}
                >
                    {!doesUserBelong && (
                    <>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: '#002129', 
                                textAlign: 'center', 
                                fontSize: isMobile ? '16px' : '20px',
                                fontWeight: isMobile ? '700' : '800', 
                                textDecoration: 'underline',
                                margin: isMobile ? '0 auto' : '0',
                                marginBottom: '16px',
                                marginTop: '16px'
                            }}
                        >
                            Pool Entry
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: '#002129', 
                                textAlign: 'center', 
                                fontSize: isMobile ? '12px' : '18px',
                                fontWeight: '700',
                                marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                            }}
                        >
                            FREE!
                        </Typography>
                    </>
                    )}
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'center', 
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: isMobile ? '700' : '800', 
                            textDecoration: 'underline',
                            margin: isMobile ? '0 auto' : '0',
                            marginBottom: '16px',
                            marginTop: '16px'
                        }}
                    >
                        Pool Prizes
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'center', 
                            fontSize: isMobile ? '12px' : '18px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                        }}
                    >
                        <span style={{ display: 'block',  textAlign: 'center' }}>
                            <span style={{ fontWeight: 700 }}>1st Place</span> - $250
                        </span>
                    </Typography>
                </Box>
            </Box>
            
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                    gap: '40px',
                    alignItems: 'start',
                    justifyItems: 'center',
                    width: isMobile ? '100%' : '1000px',
                    margin: '0 auto', // This centers the grid container horizontally
                }}
            >
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        width: isMobile ? '311px' : '289px',
                        margin: isMobile ? '0 auto' : '0',
                    }}
                >
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'center', 
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: isMobile ? '700' : '800', 
                            textDecoration: isMobile ? 'underline' : 'none',
                            margin: isMobile ? '0 auto' : '0',
                            marginBottom: '16px',
                            marginTop: '32px'
                        }}
                    >
                        How to Play
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                        }}
                    >
                        <span>
                            Each week during the college football (CFB) regular season, pool members will 
                            select one (1) team from each of the Power 4 conferences (ACC, Big 12, Big Ten, and SEC) 
                            to win straight up. Pool members can only use a team one time during the competition.
                        </span>
                        <span style={{ display: 'block', margin: '16px 0' }}>
                            If a selected conference team wins, the pool member moves on to the next week for that conference.
                        </span>
                        <span style={{ display: 'block', margin: '16px 0' }}>
                            If a selected conference team loses or a conference pick is not made before the deadline, 
                            the pool member is eliminated from that conference. However, they remain alive in the other 
                            conferences where their selected teams have won.
                        </span>
                        <span style={{ display: 'block', margin: '16px 0' }}>
                            If a pool member fails to make a pick before the deadline, they will be eliminated from the pool.
                        </span>
                        <span style={{ display: 'block', margin: '16px 0' }}>
                            You are not eliminated until you have lost in each of the Power 4 conferences.
                        </span>
                        <span style={{ display: 'block', margin: '16px 0' }}>
                            The goal is to be the last participant remaining in any conference by correctly picking winning teams each week.
                        </span>
                        <span style={{ display: 'block', margin: '16px 0' }}>
                        In the event of a tie, either because the final pool members are fully eliminated in 
                        the same week or because multiple pool members are still surviving after the final week 
                        of the regular season, the number of total wins across all four conferences will be the 
                        first tiebreaker. If there is still a tie, the season-long Win Margin tiebreaker will be used to determine the champion.
                        </span>
                        <span style={{ display: 'block', }}>
                            See the {' '}
                            <span 
                                style={{ 
                                    color: '#00AAD1', 
                                    textDecoration: 'underline', 
                                    cursor: 'pointer'
                                }} 
                                onClick={handleRulesClick}
                            >
                                Rules
                            </span> 
                            {' '} for full details!
                        </span>
                    </Typography>
                </Box>

                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: isMobile ? 'center' : 'left', 
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: isMobile ? '700' : '800', 
                            marginTop: isMobile ? '0px' : '32px',
                            marginBottom: '16px',
                            textDecoration: isMobile ? 'underline' : 'none',
                            alignSelf: isMobile ? 'center' : 'flex-start' 
                        }}
                    >
                        Pool Preview
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#002129',
                            textAlign: 'left',
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: '16px',
                            width: '311px'
                        }}
                    >
                        Learn how to start playing by watching this easy to follow video tutorial..
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '16px 0px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            alignSelf: 'stretch',
                            width: isMobile ? '192px' : '321px',
                            height: isMobile ? '294px' : '220px',
                            border: '1px solid #000',
                            background: '#FFF',
                            margin: '0 auto'
                        }}
                    >
                        <CardMedia
                            component="video"
                            src={"https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyCFBSurvivorVideo.MOV"}
                            allow="autoPlay"
                            controls
                            sx={{
                                width: '100%',
                                height: '100%', // This ensures it fills the height of the Box
                                objectFit: isMobile ? 'fill' : 'contain',
                            }}
                        />
                    </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: isMobile ? '10px' : '60px',
                                marginTop: '32px'
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                    }}
                                >
                                    My Picks
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorMyPicks.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorMyPicks.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                        marginTop: isMobile ? '24px' : '0px'
                                    }}
                                >
                                    Leaderboard
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorOverallLeaderboard.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorOverallLeaderboard.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: isMobile ? '10px' : '60px',
                                marginTop: isMobile ? '16px' : '32px',
                                marginBottom: isMobile ? '0px' : '32px'
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                        marginTop: isMobile ? '24px' : '0px'
                                    }}
                                >
                                    Team Stats
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorTeamStats.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorTeamStats.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                        marginTop: isMobile ? '24px' : '0px'
                                    }}
                                >
                                    Pool Picks
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        marginBottom: isMobile ? '32px' : '0',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorPoolPicks.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/cfbSurvivorPoolPicks.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            
                        </Box>
                    </Box>
        </Box>

            {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={mainGameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                />
            )}
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={handleCloseModal}
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 1rem',
                    }}
                >
                    <Box
                    sx={{
                        width: isMobile ? '300px' : '600px',
                        height: isMobile ? '500px' : '750px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxSizing: 'border-box', // Ensure padding is included in the dimensions
                    }}
                    >
                    <Box
                        sx={{
                        width: '100%',
                        height: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        }}
                    >
                        <img
                        src={modalImage}
                        alt="Image placeholder"
                        style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            maxHeight: 'calc(100% - 50px)', // Ensure button space
                        }}
                        />
                        <Button 
                            variant="contained"
                            onClick={handleCloseModal}
                            sx={{
                                marginTop: '10px',
                            }}
                        >
                            CLOSE
                        </Button>
                    </Box>
                    </Box>
                </Modal>
            )}

        </div>
    )
}

export default CFBSurvivorHome;