import React, { useEffect, useState } from "react";
import { Box, Typography, Select, MenuItem, FormControl } from "@mui/material";
import teaserOddsCalculations from "../teaserOddsCalculations.json";

function TeaserPointsDropdown({ pickState, selectedTeaserValue, setSelectedTeaserValue, teaserPointValue, setTeaserPointValue, teaserAmericanOdds, setTeaserAmericanOdds }) {
    const [teaserPointsDropdown, setTeaserPointsDropdown] = useState([]); // Initialize as an empty array
    // const [selectedTeaserValue, setSelectedTeaserValue] = useState('');
    // const [teaserPointValue, setTeaserPointValue] = useState('');
    // const [teaserAmericanOdds, setTeaserAmericanOdds] = useState(0);
    const [teaserDecimalOdds, setTeaserDecimalOdds] = useState(0);

    console.log("Selected Teaser Value: ", selectedTeaserValue, "Teaser Point Value: ", teaserPointValue, "American Odds: ", teaserAmericanOdds);

    const handleChange = (event) => {
        const newValue = event.target.value;
        setSelectedTeaserValue(newValue);
        const teaserAmericanOdds = newValue.match(/([-+]\d+)/)[0];
        setTeaserAmericanOdds(teaserAmericanOdds);
        setTeaserPointValue(newValue.split('-')[0]);
    };

    useEffect(() => {
        function findTeaserOdds() {
            const teams = pickState?.length;
            const sports = pickState?.map(pick => pick.sport);
            const uniqueSports = [...new Set(sports)];
            console.log("Unique Sports: ", uniqueSports, "Teams Count:  ", teams);

            if (typeof teaserOddsCalculations === 'object' && teaserOddsCalculations !== null) {
                const sportData = teaserOddsCalculations[uniqueSports];
                console.log("Sport Data: ", sportData);

                if (Array.isArray(sportData)) {
                    const results = sportData.map(teaserObject => {
                        const specificInfo = teaserObject.Info.find(info => info.Teams === teams);

                        if (specificInfo) {
                            return {
                                Points: teaserObject.Points,
                                Teams: specificInfo.Teams,
                                AmericanOdds: specificInfo.AmericanOdds,
                                DecimalOdds: specificInfo.DecimalOdds
                            };
                        } else {
                            console.error(`Error: No Info entry found for ${teams} teams in Points: ${teaserObject.Points}.`);
                            return null;
                        }
                    }).filter(entry => entry !== null);

                    return results.length > 0 ? results : null;
                } else {
                    console.error("Error: Sport data is not an array.");
                    return null;
                }
            } else {
                console.error("Error: teaserOddsCalculations is not an object.");
                return null;
            }
        }

        const results = findTeaserOdds();
        console.log("Teaser Odds Results: ", results);

        if (results) {
            const teasersForDropdown = results.map(teaser => 
                `${teaser.Points}-Points (${teaser.AmericanOdds > 0 ? `+${teaser.AmericanOdds}` : teaser.AmericanOdds})`
            );
            console.log("Teasers for Dropdown: ", teasersForDropdown);

            setTeaserPointsDropdown(teasersForDropdown);

            // Check if it's the first load or a change in `pickState`
            if (!teaserPointValue) {
                const initialTeaser = teasersForDropdown[0];
                setSelectedTeaserValue(initialTeaser);
                const initialTeaserPointValue = initialTeaser.split('-')[0];
                setTeaserPointValue(initialTeaserPointValue);
                const initialTeaserAmericanOdds = initialTeaser.match(/([-+]\d+)/)[0];
                setTeaserAmericanOdds(initialTeaserAmericanOdds);
            } else {
                // Find the matching dropdown item based on the current teaserPointValue
                const matchingTeaser = teasersForDropdown.find(teaser => 
                    teaser.startsWith(teaserPointValue)
                );

                if (matchingTeaser) {
                    setSelectedTeaserValue(matchingTeaser);
                    const matchingTeaserAmericanOdds = matchingTeaser.match(/([-+]\d+)/)[0];
                    setTeaserAmericanOdds(matchingTeaserAmericanOdds);
                } else {
                    const fallbackTeaser = teasersForDropdown[0];
                    setSelectedTeaserValue(fallbackTeaser); // Fallback to the first available option
                    const fallbackTeaserPointValue = fallbackTeaser.split('-')[0];
                    setTeaserPointValue(fallbackTeaserPointValue);
                    const fallbackTeaserAmericanOdds = fallbackTeaser.match(/([-+]\d+)/)[0];
                    setTeaserAmericanOdds(fallbackTeaserAmericanOdds);
                }
            }
        }
    }, [pickState, teaserPointValue]);

    return (
        <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%', margin: "0 auto", padding: 1, borderBottom: "1px solid #002129", backgroundColor: '#fff' }}
        >
            <Typography 
                sx={{
                    fontSize: '12px',
                    fontWeight: '500',
                    marginRight: '15px',
                }}
            >
                Teaser Points:
            </Typography>
            <FormControl>
                <Select
                    value={selectedTeaserValue}
                    onChange={handleChange}
                    variant="outlined"
                    sx={{
                        height: '30px',
                        fontSize: '12px',
                        fontWeight: '500',
                        width: '150px',
                    }}
                    inputProps={{
                        sx: {
                            fontSize: '12px',
                            fontWeight: '500',
                        },
                    }}
                >
                    {teaserPointsDropdown?.map((value, index) => (
                        <MenuItem 
                            key={index} 
                            value={value}
                            sx={{
                                fontSize: '12px',
                                fontWeight: '500',
                            }}
                        >
                            {value}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </Box>
    );
}

export default TeaserPointsDropdown;
