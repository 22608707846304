import React, { useState, useEffect } from "react";
import { Box, Typography, Button, TextField } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import InfoIcon from '@mui/icons-material/Info';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import BetSlipHeader from "./BetSlipComponents/BetSlipHeader";
import BetTypeBar from "./BetSlipComponents/BetTypeBar";
import WhatIsABet from "./BetSlipComponents/WhatIsABet";
import RemoveBets from "./BetSlipComponents/RemoveBets";
import ParlayTeaserInfo from "./BetSlipComponents/ParlayTeaserInfo";
import BetUnavailableMessage from "./BetSlipComponents/BetUnavailableMessage";
import SingleBetBox from "./BetSlipComponents/SingleBetBox";
import ParlayBetBox from "./BetSlipComponents/ParlayBetBox";
import TeaserBetBox from "./BetSlipComponents/TeaserBetBox";
import BetSlipSubmitButton from "./BetSlipComponents/BetSlipSubmitButton";
import AcceptAllLineChangesCheckbox from "./AcceptAllChangesCheckbox";

const BetSlip = ({ pickState, setPickState, currentGameInterval }) => {

    const [betSlipOpen, setBetSlipOpen] = useState(true);
    const [chosenBetStyle, setChosenBetStyle] = useState('straight');
    const [submitBetActive, setSubmitBetActive] = useState(false);
    const [bankrollExceeded, setBankrollExceeded] = useState(false);
    const [parlayBankrollExceeded, setParlayBankrollExceeded] = useState(false);
    const [teaserBankrollExceeded, setTeaserBankrollExceeded] = useState(false);
    const [parlayWager, setParlayWager] = useState('');
    const [parlayToWin, setParlayToWin] = useState('');
    const [parlayOdds, setParlayOdds] = useState('');
    const [teaserWager, setTeaserWager] = useState('');
    const [teaserToWin, setTeaserToWin] = useState('');
    const [selectedTeaserValue, setSelectedTeaserValue] = useState('');
    const [teaserPointValue, setTeaserPointValue] = useState('');
    const [teaserAmericanOdds, setTeaserAmericanOdds] = useState(0);
    const [parlayAvailable, setParlayAvailable] = useState(false);
    const [parlayUnavailableHeader, setParlayUnavailableHeader] = useState('More Legs Required');
    const [parlayUnavailableMessage, setParlayUnavailableMessage] = useState(`A parlay needs to have 2 or more legs. \nAdd selections to build your parlay!`);
    const [teaserAvailable, setTeaserAvailable] = useState(false);
    const [teaserUnavailableHeader, setTeaserUnavailableHeader] = useState('More Legs Required');
    const [teaserUnavailableMessage, setTeaserUnavailableMessage] = useState(`A teaser needs to have 2 or more legs. \nAdd selections to build your teaser!`);
    const [acceptAllChanges, setAcceptAllChanges] = useState(false);
    //console.log("Parlay Wager", parlayWager, "Parlay To Win", parlayToWin, "Parlay Odds", parlayOdds);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
    const isNotDesktop = useMediaQuery('(max-width:959px)');

    const bankroll = 1000;

    const totalWager = pickState?.reduce((acc, bet) => {
        // Convert to number and handle NaN
        const wager = parseFloat(bet.wager) || 0;
        return acc + wager;
    }, 0).toFixed(2);
        //console.log("Total Wager", totalWager);

    // Check if the total wagers on straight bets exceed the bankroll    
    useEffect(() => {
        if (totalWager > bankroll) {
            setBankrollExceeded(true);
        } else {
            setBankrollExceeded(false);
        }
    } , [totalWager, bankroll]);

    // Check if the parlay wager exceeds the bankroll
    useEffect(() => {
        if (parlayWager > bankroll) {
            setParlayBankrollExceeded(true);
        } else {
            setParlayBankrollExceeded(false);
        }
    } , [parlayWager, bankroll]);

    // Check if the teaser wager exceeds the bankroll
    useEffect(() => {
        if (teaserWager > bankroll) {
            setTeaserBankrollExceeded(true);
        } else {
            setTeaserBankrollExceeded(false);
        }
    } , [teaserWager, bankroll]);

    const totalPayout = pickState?.reduce((acc, bet) => {
        // Convert to number and handle NaN
        const toWin = parseFloat(bet.toWin) || 0;
        return acc + toWin;
    }, 0).toFixed(2);
        //console.log("Total Payout", totalPayout);
    
    useEffect(() => {
        if (
            (chosenBetStyle === "straight" && totalWager > 0 && !bankrollExceeded) ||
            (chosenBetStyle === "parlay" && parlayWager > 0 && !parlayBankrollExceeded) ||
            (chosenBetStyle === "teaser" && teaserWager > 0 && !teaserBankrollExceeded)
        ) {
            setSubmitBetActive(true);
        } else {
            setSubmitBetActive(false);
        }
    }, [chosenBetStyle, totalWager, bankrollExceeded, parlayWager, parlayBankrollExceeded, teaserWager, teaserBankrollExceeded]);
        
    //console.log("Submit Bet Active", submitBetActive, "Chosen Bet Type", chosenBetStyle, "ParlayWager", parlayWager, "Parlay Bankroll Exceeded", parlayBankrollExceeded, "Teaser Wager", teaserWager, "Teaser Bankroll Exceeded", teaserBankrollExceeded);

    // Checking for Parlay Availability
    useEffect(() => {
        // Function to check for conflicting bets
        const hasConflictingBets = (bets) => {
            const groupedBets = bets.reduce((acc, bet) => {
                const { gameId, betType, betChoice, line, juice } = bet;
    
                if (!acc[gameId]) acc[gameId] = { Spread: [], Moneyline: [], Total: [] };
    
                if (betType === "Spread") {
                    acc[gameId].Spread.push({ betChoice, line });
                } else if (betType === "Moneyline") {
                    acc[gameId].Moneyline.push({ betChoice, juice });
                } else if (betType === "Total") {
                    acc[gameId].Total.push({ betChoice });
                }
    
                return acc;
            }, {});
    
            return Object.values(groupedBets).some(({ Spread, Moneyline, Total }) => {
                // Check for conflicting betChoice
                const hasConflictingSpread = Spread.length > 1;
                const hasConflictingMoneyline = Moneyline.length > 1;
                const hasConflictingOverUnder = Total.length > 1;
    
                // Check for line and juice conflicts
                const hasConflictingLineAndJuice = Spread.some(spread =>
                    Moneyline.some(moneyline =>
                        spread.line < 0 && moneyline.juice > 0
                    )
                );
    
                // Check for conflicting Spread and Moneyline on the same betChoice
                const hasSameChoiceConflict = Spread.some(spread =>
                    Moneyline.some(moneyline =>
                        spread.betChoice === moneyline.betChoice
                    )
                );
    
                return hasConflictingSpread ||
                       hasConflictingMoneyline ||
                       hasConflictingOverUnder ||
                       hasConflictingLineAndJuice ||
                       hasSameChoiceConflict;
            });
        };
    
        // Set parlay availability based on the conditions
        if (pickState?.length >= 2 && !hasConflictingBets(pickState)) {
            setParlayAvailable(true);
        } else {
            setParlayAvailable(false);
            if (pickState?.length < 2) {
                setParlayUnavailableHeader('More Legs Required');
                setParlayUnavailableMessage(`A parlay needs to have 2 or more legs. \nAdd selections to build your parlay!`);
            }
            else {
                setParlayUnavailableHeader('Parlay Ineligible Picks');
                setParlayUnavailableMessage(`Your current selections cannot be parlayed.`);
            }
        }
    }, [pickState]);
    
    // Checking for Teaser Availability 
    useEffect(() => {
        // Function to check for conflicting bets
        const hasValidBets = pickState?.every(bet => 
            bet.betType !== "Moneyline" &&
            (bet.sport === "Football" || bet.sport === "Basketball")
        );
    
        // Function to check if there are conflicting Spread or Total bets from the same game
        const hasConflictingBets = (bets) => {
            const groupedBets = bets.reduce((acc, bet) => {
                const { gameId, betType } = bet;
    
                if (!acc[gameId]) acc[gameId] = { Spread: 0, Total: 0 };
    
                if (betType === "Spread") {
                    acc[gameId].Spread += 1;
                } else if (betType === "Total") {
                    acc[gameId].Total += 1;
                }
    
                return acc;
            }, {});
    
            return Object.values(groupedBets).some(({ Spread, Total }) => 
                Spread > 1 || Total > 1
            );
        };
    
        // Set teaser availability based on the conditions
        if (pickState?.length >= 2 && hasValidBets && !hasConflictingBets(pickState)) {
            setTeaserAvailable(true);
        } else {
            setTeaserAvailable(false);
            if (pickState?.length < 2) {
                setTeaserUnavailableHeader('More Legs Required');
                setTeaserUnavailableMessage(`A teaser needs to have 2 or more legs. \nAdd selections to build your teaser!`);
            } else if (!hasValidBets) {
                setTeaserUnavailableHeader('Teaser Ineligible Picks');
                setTeaserUnavailableMessage(`Your current selections cannot be placed in a teaser.`);
            }
        }
    }, [pickState]);

    //console.log("Parlay Available", parlayAvailable, "Teaser Available", teaserAvailable);



    const removeBet = (gameId, betType, betChoice) => {
        // Implement the logic to remove the bet from the state
        setPickState(prevState => 
            prevState.filter(bet => 
                !(bet.gameId === gameId && bet.betType === betType && bet.betChoice === betChoice)
            )
        );
    };

    const calculateParlayOdds = (pickState) => {
        // Function to convert American odds to decimal odds
        const toDecimal = (odds) => {
            console.log("Odds", odds);
            if (odds < 0) {
                //console.log("Odds < 0", 1 + (100 / Math.abs(odds)));
                return 1 + (100 / Math.abs(odds));
            } else {
                //console.log("Odds > 0", 1 + (odds / 100));
                return 1 + (odds / 100);
            }
        };
    
        // Function to convert decimal odds back to American odds
        const toAmerican = (decimal) => {
            if (decimal >= 2) {
                return Math.round((decimal - 1) * 100);
            } else {
                return Math.round(-100 / (decimal - 1));
            }
        };
    
        // Map through pickState and calculate the combined decimal odds
        const combinedDecimalOdds = pickState
            .map(key => toDecimal(key.juice))
            .reduce((acc, decimal) => acc * decimal, 1);
    
        // Convert the combined decimal odds back to American odds (optional)
        const parlayOdds = toAmerican(combinedDecimalOdds);
    
        return parlayOdds;
    };
 
    useEffect(() => {
        if (chosenBetStyle === 'parlay') {
            setParlayOdds(calculateParlayOdds(pickState));
        }
    }, [pickState, chosenBetStyle]);

    return (
        <>
            {isNotDesktop && betSlipOpen && pickState?.length > 0 && (
                <Box
                    className="sportsbook-bet-slip-mobile-transparent-overlay"
                    onClick={() => setBetSlipOpen(false)}
                />
            )}

            <Box className="sportsbook-bet-slip-container">
                {(!isNotDesktop || pickState?.length > 0) && (
                    <>
                        <BetSlipHeader isNotDesktop={isNotDesktop} betSlipOpen={betSlipOpen} setBetSlipOpen={setBetSlipOpen} pickState={pickState}/>
                        {pickState?.length === 0 && (<BetUnavailableMessage emptyBetSlip={true} />)}
                    </>
                )}
                

            {betSlipOpen && (
                <>
                    {pickState?.length > 0 && (
                        <>
                            <BetTypeBar chosenBetStyle={chosenBetStyle} setChosenBetStyle={setChosenBetStyle} />
                            <WhatIsABet chosenBetStyle={chosenBetStyle} />

                            {/* Scrollable Bet Container */}
                            <Box className="scrollable-bet-container">
                                {chosenBetStyle === 'straight' && (
                                    pickState?.map((bet, index) => (
                                        <SingleBetBox
                                            key={index}
                                            bet={bet}
                                            removeBet={removeBet}
                                            pickState={pickState}
                                            setPickState={setPickState}
                                            bankrollExceeded={bankrollExceeded}
                                        />
                                    ))
                                )}
                                {chosenBetStyle === 'parlay' && (
                                    !parlayAvailable ? (
                                        <BetUnavailableMessage titleMessage={parlayUnavailableHeader} subtitleMessage={parlayUnavailableMessage} />
                                    ) : (
                                        <ParlayBetBox
                                            pickState={pickState}
                                            setPickState={setPickState}
                                            removeBet={removeBet}
                                            bankrollExceeded={parlayBankrollExceeded}
                                            setParlayWager={setParlayWager}
                                            wagerState={parlayWager}
                                            setWagerState={setParlayWager}
                                            toWinState={parlayToWin}
                                            setToWinState={setParlayToWin}
                                            chosenBetStyle={chosenBetStyle}
                                            betOdds={parlayOdds}
                                        />
                                    )
                                )}

                                {chosenBetStyle === 'teaser' && (
                                    !teaserAvailable ? (
                                        <BetUnavailableMessage
                                            titleMessage={teaserUnavailableHeader}
                                            subtitleMessage={teaserUnavailableMessage}
                                        />
                                    ) : (
                                        <TeaserBetBox
                                            pickState={pickState}
                                            setPickState={setPickState}
                                            removeBet={removeBet}
                                            bankrollExceeded={parlayBankrollExceeded}
                                            setParlayWager={setParlayWager}
                                            wagerState={teaserWager}
                                            setWagerState={setTeaserWager}
                                            toWinState={teaserToWin}
                                            setToWinState={setTeaserToWin}
                                            chosenBetStyle={chosenBetStyle}
                                            betOdds={parlayOdds}
                                            selectedTeaserValue={selectedTeaserValue}
                                            setSelectedTeaserValue={setSelectedTeaserValue}
                                            teaserPointValue={teaserPointValue}
                                            setTeaserPointValue={setTeaserPointValue}
                                            teaserAmericanOdds={teaserAmericanOdds}
                                            setTeaserAmericanOdds={setTeaserAmericanOdds}
                                        />
                                    )
                                )}

                            </Box>
                            {/* End Scrollable Bet Container */}


                            <AcceptAllLineChangesCheckbox
                                acceptAllChanges={acceptAllChanges}
                                setAcceptAllChanges={setAcceptAllChanges}
                            />

                            <RemoveBets 
                                setPickState={setPickState} 
                                setChosenBetStyle={setChosenBetStyle}
                            />

                            <BetSlipSubmitButton
                                submitBetActive={submitBetActive}
                                pickState={pickState}
                                chosenBetStyle={chosenBetStyle}
                                totalPayout={totalPayout}
                                parlayToWin={parlayToWin}
                                teaserToWin={teaserToWin}
                                totalWager={totalWager}
                                parlayWager={parlayWager}
                                parlayOdds={parlayOdds}
                                teaserWager={teaserWager}
                                bankroll={bankroll}
                                selectedTeaserValue={selectedTeaserValue}
                                setSelectedTeaserValue={setSelectedTeaserValue}
                                teaserPointValue={teaserPointValue}
                                setTeaserPointValue={setTeaserPointValue}
                                teaserAmericanOdds={teaserAmericanOdds}
                                setTeaserAmericanOdds={setTeaserAmericanOdds}
                                acceptAllChanges={acceptAllChanges}
                                currentGameInterval={currentGameInterval}
                            />
                        </>
                    )}





                </>
            )}
            </Box>
        </>    
      );
      
}

export default BetSlip;
