import React from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../MyPoolsPage.scss';
import { useMyPoolsPageContext } from '../context/MyPoolsPageContext';
import PickEmPick5Display from "../GameInfoDisplays/PickEmPick5Display";
import BirdiePoolDisplay from "../GameInfoDisplays/BirdiePoolDisplay";
import NFLSurvivorDisplay from "../GameInfoDisplays/NFLSurvivorDisplay";
import CFBSurvivorDisplay from "../GameInfoDisplays/CFBSurvivorDisplay";

const MyPoolsCard = ({ gameName, partyInfo, league, gameType, logo, currentWeek, userPicks, gameAddress, gameId, headerDisplayName }) => {


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const navigateToTop = useNavigateToTop();
    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleEnterClick = () => {
        if (gameType === 'BirdiePool') {
            navigateToTop(`/birdie-pool/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(headerDisplayName)}`);
            //console.log(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/SCORE_CARD/${uriEncoded(headerDisplayName)}`);
        } else {
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`);
        }
    }
    

    const { myUserId } = useMyPoolsPageContext();

    return (
        <Box
            sx={{
                display: 'flex',
                padding: '16px 16px 24px 16px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: isMobile ? '325px' : '500px',
                height: 'auto',
                gap: '24px',
                alignSelf: 'stretch',
                borderRadius: '8px', // Using a static value as CSS variables aren't directly supported
                border: '2px solid #E8E8E8',
                background: '#FFF',
                margin: '0 auto',
                marginBottom: '16px'
            }}
        >
            <Box
                sx={{
                display: 'flex',
                height: '80px',
                width: '100%', // Set width to 100% or specific value if needed
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '16px', // Gap between image and text box
                }}
            >
                <Box className="game-name-and-parties-container">
                <Typography className="game-name">
                    {gameName}
                </Typography>
                <Box className="parties-container">
                    <Typography component="a" className="parties">
                    {partyInfo?.length} {partyInfo?.length !== 1 ? "Parties" : "Party"}
                    </Typography>
                </Box>
                </Box>
                <img
                src={logo}
                alt="Game Logo"
                style={{
                    width: '64px',
                    height: '80px',
                }}
                />
            </Box>
            {gameType === 'Pickem' && (
            <PickEmPick5Display
                currentWeek={currentWeek}
                userPicks={userPicks}
            />
            )}
            {gameType === 'BirdiePool' && (
            <BirdiePoolDisplay
                userPicks={userPicks}
            />
            )}
            {gameType === 'Survivor' && league === 'NFL' && (
            <NFLSurvivorDisplay
                gameName={gameName}
                partyInfo={partyInfo}
                league={league}
                gameType={gameType}
                logo={logo}
                currentWeek={currentWeek}
                userPicks={userPicks}
            />
            )}
            {gameType === 'Survivor' && league === 'NCAA' && (
            <CFBSurvivorDisplay
                gameName={gameName}
                partyInfo={partyInfo}
                league={league}
                gameType={gameType}
                logo={logo}
                currentWeek={currentWeek}
                userPicks={userPicks}
            />
            )}
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
                >
                <Button
                    variant="contained"
                    color="primary"
                    onClick = {handleEnterClick}
                    sx={{
                    width: '62px',
                    height: '32px',
                    }}
                >
                    ENTER
                </Button>
            </Box>

        </Box>
    );
}

export default MyPoolsCard;