import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import { useCFBSurvivorContext } from '../context/CFBSurvivorContext';
import CFBSurvivorUserStatsModal from '../../GameModals/SurvivorModals/CFBSurvivorUserStats/CFBSurvivorUserStatsModal';

const CFBSurvivorOverallTable = ({ leaderboardData, currentWeeksDeadlineDateTime }) => {
    const { 
      startingWeekState,
      myUserId,
      CFBTeams,
      CFBNames,
      userPicksFromDB,
      teamFilter,
      myUsername,
     } = useCFBSurvivorContext();

     //console.log("Leaderboard Data: ", leaderboardData);
  
    const [sortBy, setSortBy] = useState('rank'); // Default to 'username' for initial highlight
    const [sortOrder, setSortOrder] = useState('asc'); // Default to 'asc' for initial sort order
    const [userStatsOpen, setUserStatsOpen] = useState(false);
    const [userStatsInfo, setUserStatsInfo] = useState(null);
    const [leaderboardWithRank, setLeaderboardWithRank] = useState([]);
  
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleSort = (columnName) => {
        if (columnName === 'username') {
          // Reset to default sorting by 'username'
          setSortBy('username');
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc'); // Toggle sort order for 'username'
        } else {
          // Handle other column sorts
          if (sortBy === columnName) {
            // Toggle sort order for the current column
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          } else {
            // Set default sorting order for new column
            setSortBy(columnName);
            // Default to descending order for numeric columns
            setSortOrder('asc');
          }
        }
      };
      
    useEffect(() => {
      // Set the default sort on initial load
      setSortBy('rank');
      setSortOrder('asc');
    }, [leaderboardData]);


   // Calculate the number of true values in the specified keys
   const calculateTrueCount = (user) => {
    const trueCount = [
      user.doesUserHaveACCLoss,
      user.doesUserHaveBig12Loss,
      user.doesUserHaveBigTenLoss,
      user.doesUserHaveSECLoss
    ].filter(Boolean).length;
    return 4 - trueCount; // Subtract from 4 to get the desired sorting score
  };

  useEffect(() => {
    const rankData = [...leaderboardData]?.sort((a, b) => {
      // Primary sort by `isUserEliminated`
      if (a.isUserEliminated !== b.isUserEliminated) {
        return a.isUserEliminated ? 1 : -1; // Eliminated players should come after non-eliminated
      }
      // Secondary sort by `totalWins`
      if (a.totalWins !== b.totalWins) {
        return sortOrder === 'asc' ? b.totalWins - a.totalWins : a.totalWins - b.totalWins;
      }
      // Tertiary sort by the calculated true count (4 - number of trues)
      if (calculateTrueCount(a) !== calculateTrueCount(b)) {
        return sortOrder === 'asc' ? calculateTrueCount(b) - calculateTrueCount(a) : calculateTrueCount(a) - calculateTrueCount(b);
      }
      // Final sort by `totalMargin`
      return sortOrder === 'asc' ? b.totalMargin - a.totalMargin : a.totalMargin - b.totalMargin;
    });

    // Insert rank for each user
    rankData.forEach((user, index) => {
      if (index === 0) {
        user.rank = 1;
        user.conferencesRemaining = calculateTrueCount(user);
      } else {
        const previousUser = rankData[index - 1];
        user.conferencesRemaining = calculateTrueCount(user);
        
        // Compare current user with the previous one to determine if they should have the same rank
        if (
          user.isUserEliminated === previousUser.isUserEliminated &&
          user.totalWins === previousUser.totalWins &&
          calculateTrueCount(user) === calculateTrueCount(previousUser) &&
          user.totalMargin === previousUser.totalMargin
        ) {
          user.rank = previousUser.rank;
        } else {
          user.rank = index + 1; // Assign rank based on the index
        }
      }
    });

    setLeaderboardWithRank(rankData);

  }, [leaderboardData]); // Empty dependency array ensures this effect runs only once on component mount

  //console.log("Ranked Data", leaderboardWithRank);
    
  
    const sortedData = [...leaderboardData].sort((a, b) => {
      // Calculate the number of true values in the specified keys
      const calculateTrueCount = (user) => {
        const trueCount = [
            user.doesUserHaveACCLoss,
            user.doesUserHaveBig12Loss,
            user.doesUserHaveBigTenLoss,
            user.doesUserHaveSECLoss
        ].filter(Boolean).length;
        return 4 - trueCount; // Subtract from 4 to get the desired sorting score
      };
      if (sortBy === 'username') {
        // Sort alphabetically by username
        if (a.username.toLowerCase() < b.username.toLowerCase()) return sortOrder === 'asc' ? -1 : 1;
        if (a.username.toLowerCase() > b.username.toLowerCase()) return sortOrder === 'asc' ? 1 : -1;
    } else if (sortBy === 'rank') {
        // Sort by rank
        if (a.rank !== b.rank) {
            return sortOrder === 'asc' ? a.rank - b.rank : b.rank - a.rank;
        }
    }
  
      if (sortBy === 'totalWins') {
        // Primary sort by `totalWins`
        const winComparison = sortOrder === 'asc' ? b.totalWins - a.totalWins : a.totalWins - b.totalWins;
        
        if (winComparison === 0) {
            // If `totalWins` is the same, sort by `calculateTrueCount`
            const trueCountComparison = sortOrder === 'asc' ? calculateTrueCount(b) - calculateTrueCount(a) : calculateTrueCount(a) - calculateTrueCount(b);
            
            if (trueCountComparison === 0) {
                // If `trueCount` is also the same, sort by `totalMargin`
                return sortOrder === 'asc' ? b.totalMargin - a.totalMargin : a.totalMargin - b.totalMargin;
            }
            return trueCountComparison;
        }
        return winComparison;
     }
  
      // Sorting by `ACCWins`
      if (sortBy === 'ACCWins') {
          const winComparison = sortOrder === 'asc' ? b.ACCWins - a.ACCWins : a.ACCWins - b.ACCWins;
          if (winComparison === 0) {
              return sortOrder === 'asc' ? b.ACCMargin - a.ACCMargin : a.ACCMargin - b.ACCMargin;
          }
          return winComparison;
      }
  
      // Sorting by `Big12Wins`
      if (sortBy === 'Big12Wins') {
          const winComparison = sortOrder === 'asc' ? b.Big12Wins - a.Big12Wins : a.Big12Wins - b.Big12Wins;
          if (winComparison === 0) {
              return sortOrder === 'asc' ? b.Big12Margin - a.Big12Margin : a.Big12Margin - b.Big12Margin;
          }
          return winComparison;
      }
  
      // Sorting by `BigTenWins`
      if (sortBy === 'BigTenWins') {
          const winComparison = sortOrder === 'asc' ? b.BigTenWins - a.BigTenWins : a.BigTenWins - b.BigTenWins;
          if (winComparison === 0) {
              return sortOrder === 'asc' ? b.BigTenMargin - a.BigTenMargin : a.BigTenMargin - b.BigTenMargin;
          }
          return winComparison;
      }
  
      // Sorting by `SECWins`
      if (sortBy === 'SECWins') {
          const winComparison = sortOrder === 'asc' ? b.SECWins - a.SECWins : a.SECWins - b.SECWins;
          if (winComparison === 0) {
              return sortOrder === 'asc' ? b.SECMargin - a.SECMargin : a.SECMargin - b.SECMargin;
          }
          return winComparison;
      }
  
      // Default return if no conditions match
      return 0;
  });
  

  const playerHeaderColor = sortBy === 'rank' ? '#FFC60A' : '#fff';
  const totalWinsHeaderColor = sortBy === 'totalWins' ? '#FFC60A' : '#fff';
  const accHeaderColor = sortBy === 'ACCWins' ? '#FFC60A' : '#fff';
  const big12HeaderColor = sortBy === 'Big12Wins' ? '#FFC60A' : '#fff';
  const bigTenHeaderColor = sortBy === 'BigTenWins' ? '#FFC60A' : '#fff';
  const secHeaderColor = sortBy === 'SECWins' ? '#FFC60A' : '#fff';
  // const rankHeaderColor = sortBy === 'rank' ? '#FFC60A' : '#fff';

  const usersRow = sortedData?.find((user) => user.userId === myUserId);
  //console.log("USERS ROW!!!!!", usersRow);

  return (
    <Box>
      <TableContainer component={Paper} style={{ overflowX: 'clip', maxWidth: '950px', margin: '0 auto', marginBottom: '20px', overflow: 'scroll' }}>
        <Table>
          <TableHead>
            {usersRow && (
                <TableRow
                    sx={{
                        backgroundColor: '#F0F1F1', // Gray background for the user's row
                    }}
                >
                    {/* <TableCell
                          className="pickem-leaderboard-table-cells"
                          sx={{ color: usersRow.isUserEliminated ? '#CC293C' : '#002129', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                      >
                          {usersRow.rank}
                    </TableCell> */}
                    <TableCell sx={{ color: usersRow.isUserEliminated ? '#CC293C' : '#002129', textAlign: 'left', fontSize: isMobile ? '12px' : '16px', padding: '0px 16px' }}>
                        <Box sx={{ lineHeight: '1.4'}}>
                            <Box
                              sx={{ width: isMobile ? '140px' : '250px', textDecoration: 'underline', cursor: 'pointer', fontWeight: '700', marginBottom: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                              onClick={() => {
                                  setUserStatsOpen(true);
                                  setUserStatsInfo(usersRow);
                              }}
                            >{usersRow.username}</Box>
                            <Box>Conf Alive: {usersRow.conferencesRemaining}</Box>
                            <Box>Win Margin: {usersRow.totalMargin || 0}</Box>
                        </Box>
                    </TableCell>
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        sx={{ color: usersRow.isUserEliminated ? '#CC293C' : '#002129', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                    >
                        {usersRow.totalWins || 0}
                    </TableCell>
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        sx={{ color: usersRow.doesUserHaveACCLoss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                    >
                        {usersRow.ACCWins || 0}
                    </TableCell>
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        sx={{ color: usersRow.doesUserHaveBig12Loss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                    >
                        {usersRow.Big12Wins || 0}
                    </TableCell>
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        sx={{ color: usersRow.doesUserHaveBigTenLoss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                    >
                        {usersRow.BigTenWins || 0}
                    </TableCell>
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        sx={{ color: usersRow.doesUserHaveSECLoss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                    >
                        {usersRow.SECWins || 0}
                    </TableCell>
                </TableRow>
            )}
          </TableHead>
          <TableHead sx={{ backgroundColor: '#002129' }}>
            <TableRow>
            {/* <TableCell
                onClick={() => handleSort('rank')}
                sx={{ width: isMobile ? '43px' : '170px', color: rankHeaderColor, fontWeight: 700, textAlign: 'center', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline', padding: '0' }}
              >
                RANK
              </TableCell> */}
              <TableCell
                onClick={() => handleSort('rank')}
                sx={{ width: isMobile ? '140px' : '250px', color: playerHeaderColor, fontWeight: 700, textAlign: 'left', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline' }}
              >
                STANDINGS
              </TableCell>
              <TableCell
                onClick={() => handleSort('totalWins')}
                sx={{ width: isMobile ? '43px' : '170px', color: totalWinsHeaderColor, fontWeight: 700, textAlign: 'center', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline', padding: '0' }}
              >
                {isMobile ? 'TOT WINS' : 'TOTAL WINS'}
              </TableCell>
              <TableCell
                onClick={() => handleSort('ACCWins')}
                sx={{ width: isMobile ? '43px' : '100px', color: accHeaderColor, fontWeight: 700, textAlign: 'center', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline', padding: '0' }}
              >
                ACC
              </TableCell>
              <TableCell
                onClick={() => handleSort('Big12Wins')}
                sx={{ width: isMobile ? '43px' : '100px', color: big12HeaderColor, fontWeight: 700, textAlign: 'center', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline', padding: '0' }}
              >
                B12
              </TableCell>
              <TableCell
                onClick={() => handleSort('BigTenWins')}
                sx={{ width: isMobile ? '43px' : '100px', color: bigTenHeaderColor, fontWeight: 700, textAlign: 'center', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline', padding: '0' }}
              >
                B10
              </TableCell>
              <TableCell
                onClick={() => handleSort('SECWins')}
                sx={{ width: isMobile ? '43px' : '100px', color: secHeaderColor, fontWeight: 700, textAlign: 'center', cursor: 'pointer', fontSize: isMobile ? '14px' : '22px', textDecoration: 'underline', padding: '0' }}
              >
                SEC
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedData?.map((user, index) => (
              <TableRow
                  key={user.userId}
                  sx={{
                      backgroundColor:
                          user.userId === myUserId
                              ? '#FFF6DA' // Special color for the logged-in user
                              : index % 2 === 0
                              ? '#FFF'    // Color for even rows
                              : '#F0F1F1',// Color for odd rows
                  }}
              >
                 {/* <TableCell
                  className="pickem-leaderboard-table-cells"
                  sx={{ color: user.isUserEliminated ? '#CC293C' : '#002129', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                >
                  {user.rank}
                </TableCell> */}
                <TableCell sx={{ color: user.isUserEliminated ? '#CC293C' : '#002129', textAlign: 'left', fontSize: isMobile ? '12px' : '16px', padding: '0px 16px' }}>
                <Box>
                    <Box
                      sx={{ width: isMobile ? '140px' : '250px', textDecoration: 'underline', cursor: 'pointer', fontWeight: '700', marginBottom: '0px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}                      onClick={() => {
                          setUserStatsOpen(true);
                          setUserStatsInfo(user);
                      }}
                    >{user.username}</Box>
                    <Box>Conf Alive: {user.conferencesRemaining}</Box>
                    <Box>
                      Win Margin:&nbsp; 
                      {(() => {
                        if (sortBy === 'ACCWins') {
                          return user.ACCMargin || 0;
                        } else if (sortBy === 'Big12Wins') {
                          return user.Big12Margin || 0;
                        } else if (sortBy === 'BigTenWins') {
                          return user.BigTenMargin || 0;
                        } else if (sortBy === 'SECWins') {
                          return user.SECMargin || 0;
                        } else {
                          return user.totalMargin || 0;
                        }
                      })()}
                    </Box>

                </Box>
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  sx={{ color: user.isUserEliminated ? '#CC293C' : '#002129', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                >
                  {user.totalWins || 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  sx={{ color: user.doesUserHaveACCLoss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                >
                  {user.ACCWins || 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  sx={{ color: user.doesUserHaveBig12Loss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                >
                  {user.Big12Wins || 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  sx={{ color: user.doesUserHaveBigTenLoss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                >
                  {user.BigTenWins || 0}
                </TableCell>
                <TableCell
                  className="pickem-leaderboard-table-cells"
                  sx={{ color: user.doesUserHaveSECLoss ? '#CC293C' : '#00AA72', textAlign: 'center', fontSize: isMobile ? '14px' : '16px' }}
                >
                  {user.SECWins || 0}
                </TableCell>

              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {userStatsOpen && (
                <CFBSurvivorUserStatsModal 
                    statsModalOpen={userStatsOpen}
                    setStatsModalOpen={setUserStatsOpen}
                    myUsername={userStatsInfo.username}
                    startingWeekState={startingWeekState}
                    currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}
                    CFBTeams={CFBTeams}
                    CFBNames={CFBNames}
                    areTheseMyStats={userStatsInfo.username === myUsername}
                    usersPicks={userStatsInfo.username === myUsername ? userPicksFromDB : userStatsInfo.picks}
                    teamFilter={teamFilter}
                    doesUserHaveACCLoss={userStatsInfo.doesUserHaveACCLoss}
                    lossWeekACC={userStatsInfo.lossWeekACC}
                    doesUserHaveBig12Loss={userStatsInfo.doesUserHaveBig12Loss}
                    lossWeekBig12={userStatsInfo.lossWeekBig12}
                    doesUserHaveBigTenLoss={userStatsInfo.doesUserHaveBigTenLoss}
                    lossWeekBigTen={userStatsInfo.lossWeekBigTen}
                    doesUserHaveSECLoss={userStatsInfo.doesUserHaveSECLoss}
                    lossWeekSEC={userStatsInfo.lossWeekSEC}
                />
            )}
    </Box>
  );
};

export default CFBSurvivorOverallTable;
