import React from "react"; 
import { Box, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { DateTime } from 'luxon';
import "./PickEmUserStatsModal.scss";

const PickEmUserStatsTeamsTable = ({ usersPicks, areTheseMyPicks, NFLTeams }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const timeNowInNewYork = DateTime.now().setZone('America/New_York');

    //const dummyTimeNowInNewYork = DateTime.fromObject({ year: 2024, month: 9, day: 18, hour: 12, minute: 30 }, { zone: 'America/New_York' });
    
    // Initialize filteredPicks with default empty array
    let filteredPicks = (usersPicks && usersPicks.filter(pick => pick.teamId !== "No Pick")) || [];

    if (!areTheseMyPicks) {
        filteredPicks = (usersPicks && usersPicks.filter(pick => DateTime.fromISO(pick.dateTime).setZone('America/New_York') < timeNowInNewYork)) || [];
    }

    function getTeamStats(teamId) {
        let wins = 0;
        let losses = 0;
        let pushes = 0;
        let timesPicked = 0;
        const logo = NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
        const teamKey = NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.Key;
        const teamNickname = NFLTeams?.find((team) => team.GlobalTeamID === teamId)?.Name;
        const displayName = isMobile ? `${teamKey}` : `${teamKey} ${teamNickname}`;

        filteredPicks.forEach(pick => {
            if (pick.teamId === teamId) {
                timesPicked++;
                if (pick.result === 'Win') wins++;
                if (pick.result === 'Loss') losses++;
                if (pick.result === 'Push') pushes++;
            }
        });

        const percentage = (wins + (pushes * 0.5)) / (wins + losses + pushes) * 100;

        return { wins, losses, pushes, logo, percentage, timesPicked, displayName };
    }

    // Get unique team IDs and calculate stats
    const teamStats = [...new Set(filteredPicks.map(pick => pick.teamId))].map(teamId => getTeamStats(teamId));

    // Sort teams first by timesPicked, then by percentage
    teamStats.sort((a, b) => {
        // First, compare by timesPicked in descending order
        if (b.timesPicked !== a.timesPicked) {
            return b.timesPicked - a.timesPicked;
        }
        
        // If timesPicked are equal, compare by percentage
        // Treat NaN as the lowest possible value
        const percentageA = isNaN(a.percentage) ? -Infinity : a.percentage;
        const percentageB = isNaN(b.percentage) ? -Infinity : b.percentage;
        
        return percentageB - percentageA; // Sort percentage in descending order
    });

    //console.log("Team Stats:", teamStats);

    return (
        <Box className="user-stats-table-container" sx={{ maxWidth: isMobile ? '360px' : '839px', padding: '0' }}>
            <TableContainer component={Paper} sx={{ borderRadius: '0', maxHeight: '285px', overflowX: 'hidden' }}>
                <Table>
                    <TableHead sx={{ backgroundColor: '#002129', position: 'sticky', top: 0, zIndex: 1 }}>
                        <TableRow>
                            <TableCell key="team" sx={{ width: isMobile ? '80px' : '309px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px' }}>TEAM</TableCell>
                            <TableCell key="picked" sx={{ width: isMobile ? '75px' : '210px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px' }}>PICKED</TableCell>
                            <TableCell key="record" sx={{ width: isMobile ? '75px' : '160px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px' }}>RECORD</TableCell>
                            <TableCell key="win%" sx={{ width: isMobile ? '76px' : '160px', color: '#FFF', fontWeight: 700, textAlign: 'center', fontSize: isMobile ? '12px' : '24px', whiteSpace: 'nowrap' }}>WIN %</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {teamStats.length > 0 ? (
                            teamStats.map(({ teamId, wins, losses, pushes, logo, percentage, timesPicked, displayName }) => (
                                <TableRow key={teamId}>
                                    <TableCell>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'flex-start',
                                                justifyContent: 'flex-start',
                                                padding: '0 12px 0 17px',
                                                backgroundColor: '#fff',
                                                width: '100%',
                                                overflow: 'hidden',
                                                gap: '8px',
                                                lineHeight: 'normal',
                                                letterSpacing: 'normal',
                                                textAlign: 'left',
                                                fontSize: '12px',
                                                color: '#002129',
                                                fontFamily: 'Inter',
                                                flexShrink: 0,
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    height: isMobile ? '32px' : '48px',
                                                    width: isMobile ? '32px' : '48px',
                                                    overflow: 'hidden',
                                                    flexShrink: 0,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start',
                                                }}
                                            >
                                                <img
                                                    alt=""
                                                    src={logo}
                                                    style={{
                                                        height: isMobile ? '32px' : '48px',
                                                        width: isMobile ? '32px' : '48px',
                                                        position: 'relative',
                                                        objectFit: 'contain',
                                                    }}
                                                />
                                            </Box>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'flex-start',
                                                    padding: '8.5px 0 0',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        textDecoration: 'none',
                                                        position: 'relative',
                                                        fontSize: isMobile ? '12px' : '22px',
                                                        fontWeight: 500,
                                                        color: 'inherit',
                                                        display: 'inline-block',
                                                        minWidth: '28px',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                >
                                                    {displayName}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            sx={{
                                                color: '#002129',
                                                fontWeight: 500,
                                                fontSize: isMobile ? '12px' : '22px',
                                                textAlign: 'center',
                                            }}
                                        >{timesPicked}</Typography>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#002129',
                                            fontWeight: 500,
                                            fontSize: isMobile ? '12px' : '22px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {`${wins}-${losses}-${pushes}`}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            color: '#002129',
                                            fontWeight: 500,
                                            fontSize: isMobile ? '12px' : '22px',
                                            textAlign: 'center',
                                        }}
                                    >
                                        {!isNaN(percentage) ? `${percentage}%` : '-'}
                                    </TableCell>
                                </TableRow>
                            ))
                        ) : (
                            <TableRow>
                                <TableCell colSpan={4} sx={{ textAlign: 'center', padding: '16px' }}>
                                    <Typography sx={{ color: '#002129', fontSize: isMobile ? '12px' : '22px', fontWeight: 500 }}>
                                        No picks have been made yet.
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default PickEmUserStatsTeamsTable;
