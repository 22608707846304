import React from 'react';
import { Box, Button } from '@mui/material'; // Assuming you're using Material UI
import '../BetSlip.scss';
import { v4 as uuidv4 } from 'uuid';
import { DateTime } from 'luxon';
import { useSaveSportsbookPicks } from '../../../../hooks/pools/useSaveSportsbookPicks';

const BetSlipSubmitButton = (
  { submitBetActive, 
    pickState, 
    chosenBetStyle, 
    totalPayout, 
    parlayToWin, 
    teaserToWin,
    totalWager,
    parlayWager,
    parlayOdds,
    teaserWager, 
    bankroll,
    selectedTeaserValue,
    teaserPointValue,
    teaserAmericanOdds,
    acceptAllChanges,
    currentGameInterval,
  }) => {

  const { mutate, isLoading, isError, error, isSuccess } = useSaveSportsbookPicks();
  

  const submitPicks = async (data) => {
    try {
      await mutate(data, {
        onSuccess: async (response) => {
          console.log('Picks submitted successfully:', response);
        },
        onError: (error) => {
          console.error('Error submitting picks:', error);
        }
      });
    } catch (error) {
      console.error('Error submitting picks:', error);
    }
  };

  // Handle the bet submission
  const handleBetSubmit = () => {
    console.log('Submit Bet Clicked', pickState, "Chosen Bet Type: ", chosenBetStyle, "Total Payout: ", totalPayout, "Parlay To Win: ", parlayToWin, "Teaser To Win: ", teaserToWin, "Total Wager: ", totalWager, "Parlay Wager: ", parlayWager, "Teaser Wager: ", teaserWager);
    if (chosenBetStyle === "straight") {
      console.log("Inside chosenBetStyle condition");
      const sumOfWagers = pickState?.reduce((acc, pick) => acc + Number(pick.wager).toFixed(2), 0);
      console.log("Sum of Wagers:", sumOfWagers);
    
      if (sumOfWagers > bankroll) {
        console.log("You do not have sufficient funds for these wagers");
      } else {
        console.log("Sufficient funds, proceeding...");
        const betsWithWager = pickState?.filter(entry => entry.hasOwnProperty('wager') && entry.wager > 0);
    
        // Ensure wager and toWin are numbers
        const updatedBetsWithWager = betsWithWager?.map(entry => ({
          ...entry,
          betTime: DateTime.now().setZone('America/New_York').toISO(),
          wager: Number(entry.wager),
          //toWin: Number(entry.toWin) // Handle 'toWin' similarly
          toWin: Number(9999)
      }));
      
    
        const sumOfWagers = updatedBetsWithWager.reduce((sum, entry) => sum + entry.wager, 0);
        console.log("Bets With A Wager:", updatedBetsWithWager, "Sum of Wagers: ", sumOfWagers, "Sum of Wagers Less than Bankroll: ", sumOfWagers <= bankroll);
    
        const singleBets = {
            totalWager: Number(sumOfWagers),
            bets: updatedBetsWithWager,
        };
    
        const betInfoToSubmit = {
            betStyle: "straight",
            userId: "84erf8e4-8f4e-8f4e-8f4e-8f4e8f4e8f4e",
            gameId: "12345",
            gameType: "Sportsbook",
            gameInterval: currentGameInterval,
            acceptAllChanges: acceptAllChanges,
            betInfo: singleBets,
        };
    
        console.log("Single Bet Info: ", betInfoToSubmit); // Check if this line is reached
        submitPicks(betInfoToSubmit);
      }
    }
    

    if (chosenBetStyle === "parlay") {
      if (parlayWager > bankroll) {
        console.log("You do not have sufficient funds for this wager");
      } else {
        const updatedPickState = pickState.map(({ wager, toWin, homeName, awayName, ...rest }) => rest);
        const groupBetId = uuidv4();

        const parlayInfo = {
          betTime: DateTime.now().setZone('America/New_York').toISO(),
          groupBetId: groupBetId,
          totalWager: Number(parlayWager),
          overallToWin: Number(parlayToWin),
          overallBetOdds: parlayOdds,
          bets: updatedPickState, // updated pickState without wager and toWin
        };

        const betInfoToSubmit = {
          betStyle: "parlay",
          userId: "84erf8e4-8f4e-8f4e-8f4e-8f4e8f4e8f4e",
          gameId: "12345",
          gameType: "Sportsbook",
          acceptAllChanges: acceptAllChanges,
          gameInterval: currentGameInterval,
          betInfo: parlayInfo,
        };
    
        console.log("Parlay Info: ", betInfoToSubmit);
      }
    }

    if (chosenBetStyle === "teaser") {
      if (teaserWager > bankroll) {
        console.log("You do not have sufficient funds for this wager");
      } else {
        const updatedPickState = pickState.map(({ wager, toWin, awayName, homeName, ...rest }) => rest);
        const groupBetId = uuidv4();

        const teaserInfo = {
          betTime: DateTime.now().setZone('America/New_York').toISO(),
          groupBetId: groupBetId,
          totalWager: Number(teaserWager), // Convert string to number
          overallToWin: Number(teaserToWin), // Convert string to number
          overallBetOdds: teaserAmericanOdds.startsWith('+') 
            ? Number(teaserAmericanOdds.slice(1))  // Remove + and convert to number
            : Number(teaserAmericanOdds),          // Convert to number (already negative or plain number)
          teaserPoints: Number(teaserPointValue),  // Convert string to number
          bets: updatedPickState, // updated pickState without wager and toWin
        };

        const betInfoToSubmit = {
          betStyle: "teaser",
          userId: "84erf8e4-8f4e-8f4e-8f4e-8f4e8f4e8f4e",
          gameId: "12345",
          gameType: "Sportsbook",
          acceptAllChanges: acceptAllChanges,
          gameInterval: currentGameInterval,
          betInfo: teaserInfo,
        };
        
        console.log("Teaser Info: ", betInfoToSubmit);
        
      }
    }
    
  };

  return (
    <Box className="sportsbook-submit-button-container">
      <Button
        className={`sportsbook-submit-button ${submitBetActive ? 'active' : 'inactive'}`}
        onClick={handleBetSubmit}
        disabled={!submitBetActive}
      >
        <div className="sportsbook-submit-button-text-top">PLACE BET</div>
        <div className="sportsbook-submit-button-text-bottom">
          {submitBetActive
            ? `To Win: ${
                chosenBetStyle === 'straight'
                  ? totalPayout
                  : chosenBetStyle === 'parlay'
                  ? parlayToWin
                  : teaserToWin
              }`
            : 'Enter a Wager'}
        </div>
      </Button>
    </Box>
  );
};

export default BetSlipSubmitButton;
