import React, { useState, useEffect } from "react";
import { Typography, Box } from "@mui/material";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import { useGetPublicPools } from "./hooks/useGetPublicPools";
import PoolHallList from "./PoolHallList";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import StaticPoolHeaderBar from "../PoolHeaderBar/StaticPoolHeaderBar";

const PoolHallContainer = () => {

    const { data, error, isLoading, refetch } = useGetPublicPools();
    //console.log("Public Pools", data);
    
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [page, setPage] = useState('All');
    const [filteredPools, setFilteredPools] = useState([]);

    useEffect(() => {
        if (page === 'All') {
            setFilteredPools(data);
        } else {
            setFilteredPools(data.filter(pool => pool.league === page));
        }
    }, [page, data]);

    if (isLoading) {
        return <LoadingScreen />;
    }

    
    return (
        <div>
            <StaticPoolHeaderBar navigationText="Home" headerText="Pool Hall" linkForNavigation="/" />
            
             <Box
                sx={{
                    marginBottom: '32px',
                }}
             >
                <div style={{display:'flex', justifyContent:'space-evenly', maxWidth: isMobile ? '300px' : '350px', margin:'15px auto'}}>
                    <span
                    style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: page === 'Send Invites' ? 'underline' : 'none', backgroundColor: page === 'All' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px', whiteSpace: 'nowrap'  }}
                    onClick={() => setPage('All')}
                    >
                        All
                    </span>
                    <span
                        style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: page === 'Manage Members' ? 'underline' : 'none', backgroundColor: page === 'NFL' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', marginRight: isMobile ? '0px' : '70px', whiteSpace: 'nowrap' }}
                        onClick={() => setPage('NFL')}
                    >
                        NFL
                    </span>
                    <span
                        style={{display: 'block', padding: '12px 8px',borderRadius:'6px', textDecoration: page === 'Email Members' ? 'underline' : 'none', backgroundColor: page === 'NCAA' ? '#E8E8E8' : null, color: '#002129', fontSize: isMobile ? '12px' : '18px', fontWeight: '700', cursor: 'pointer', whiteSpace: 'nowrap' }}
                        onClick={() => setPage('NCAA')}
                    >
                        CFB
                    </span>
                </div>
            </Box>

            <Box>
                <Typography
                    sx={{
                        color: '#002129',
                        textAlign: 'center',
                        fontFamily: 'Inter',
                        fontSize: isMobile ? '16px' : '24px',
                        fontStyle: 'normal',
                        fontWeight: 700,
                        lineHeight: '150%',
                        letterSpacing: '-0.304px',
                        marginBottom: '16px'
                    }}
                >
                    FREE to enter.  $1000 prize pool.
                </Typography>
            </Box>

            <PoolHallList pools={filteredPools} />
        </div>
    );
}

export default PoolHallContainer;
    