import React, { useState, useEffect } from 'react';
import { Paper, Box, Table, TableHead, TableRow, TableCell, TableBody, TableContainer, Typography } from '@mui/material';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import '../../NFLPickEm/NFLPickEmStyles/PickEmLeaderboardStyles.scss';
import TableDropdown from '../../TableDropdown/TableDropdown';
import PercentagePillBox from '../../PercentagePillBox/PercentagePillBox';
import { useNFLSurvivorContext } from '../context/NFLSurvivorContext';
import { DateTime } from 'luxon';
import CloseIcon from '@mui/icons-material/Close';
import '../NFLSurvivorStyles/SurvivorPickStats.scss';


const SurvivorPickStats = ({ handleClickState, leaderboardData, currentWeeksDeadlineDateTime }) => {
    //console.log("LEADERBOARD DATA", leaderboardData);


  const { 
    startingWeekState, 
    NFLTeams, 
    NFLScoresBySeason,
    partyState,
    weekOptions,
    firstWeek,
    isSurvivorOver,
 } = useNFLSurvivorContext();


    console.log("WEEK OPTIONS From Pick Stats", weekOptions);
    //console.log("LEADERBOARD DATA", leaderboardData);
    const howManyLeftOnLeaderboard = leaderboardData?.filter(user => user.doesUserHaveALoss === false)?.length;
    //console.log("How Many Left on PickStats", howManyLeftOnLeaderboard);
    const lastWeekAPickWasMade = leaderboardData?.reduce((acc, user) => {
        // Filter out picks where teamId is "No Pick"
        const userPicks = (user?.picks || []).filter(pick => pick.teamId !== "No Pick");
        
        const lastPick = userPicks?.reduce((acc, pick) => {
            return pick.week > acc ? pick.week : acc;
        }, 0);
        
        return lastPick > acc ? lastPick : acc;
    }, 0);
    
    //console.log("LAST WEEK A PICK WAS MADE", lastWeekAPickWasMade);

    const isThisGroupOver = howManyLeftOnLeaderboard <= 1;
    let filteredWeekOptions = weekOptions;
    
    if (isSurvivorOver || isThisGroupOver) {
        filteredWeekOptions = weekOptions?.filter(option => {
            // Convert the key to a number before comparing
            const weekKey = Number(option?.key);
            return weekKey <= lastWeekAPickWasMade;
        });
    }
    
    //console.log("FILTERED WEEK OPTIONS", filteredWeekOptions);
    

    const [sortBy, setSortBy] = useState('timesPicked');
    const [sortOrder, setSortOrder] = useState('desc');
    const [seasonVsWeek, setSeasonVsWeek] = useState(`Week ${startingWeekState}`);
    //console.log("Season vs Week TEAM STATS", seasonVsWeek);

    const selectedWeek = seasonVsWeek?.replace(/\D/g, '');

    //console.log("filteredWeekOptions", filteredWeekOptions, "selectedWeek", selectedWeek);
    //console.log(filteredWeekOptions?.length < startingWeekState && selectedWeek > filteredWeekOptions?.length)

    useEffect(() => {
        if (filteredWeekOptions?.length < startingWeekState && selectedWeek > filteredWeekOptions?.length) {
            //console.log("SETTING SEASON VS WEEK", `${weekOptions[0]?.props?.value}`);
            setSeasonVsWeek(`${weekOptions[0]?.props?.value}`);
        }
    }, [filteredWeekOptions, startingWeekState]);

    useEffect(() => {
        setSortBy('timesPicked');
        setSortOrder('desc');
    }, [seasonVsWeek, partyState]);

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const teamLogo = (teamId) => {
        return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.WikipediaLogoURL;
    };

    const teamKey = (teamId) => {
        return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Key;
    };

    const teamName = (teamId) => {
        return NFLTeams?.find(team => team.GlobalTeamID === teamId)?.Name;
    };

    const nflOpponentInfo = (gameId, teamId) => {
        const game = NFLScoresBySeason?.find(game => game.GlobalGameID === gameId);
        const isPickHome = game?.GlobalHomeTeamID === teamId;
        const opponentKey = isPickHome ? teamKey(game?.GlobalAwayTeamID) : teamKey(game?.GlobalHomeTeamID);
        const leadingSymbol = isPickHome ? 'vs' : '@';
        const displayString = `${leadingSymbol} ${opponentKey}`;
        return displayString;
    };

    // Current time in New York timezone
    const timeInNYNow = DateTime.now().setZone('America/New_York');
        
    //const dummyDate = DateTime.fromISO('2024-12-31T12:00:00.000-05:00').setZone('America/New_York');
    //console.log("DUMMY DATE", dummyDate);
    

    function teamStatsData() {
        const teamStats = [];
        const allFilteredPicks = [];

        if (seasonVsWeek !== 'Season') {
            const selectedWeek = seasonVsWeek?.replace(/\D/g, '');
            for (let i = 0; i < leaderboardData?.length; i++) {
                const userPicks = leaderboardData[i]?.picks || [];
                const filteredPicks = userPicks?.filter(pick => {
                    // Check if the current week's deadline has passed
                    const shouldApplyTimeFilter = DateTime.fromISO(currentWeeksDeadlineDateTime) > timeInNYNow;
                
                    // Filter picks by week and whether the game has started (unless the deadline has passed) or if the team ID is 'No Pick'
                    return (
                        String(pick.week) === selectedWeek &&
                        (pick.teamId === 'No Pick' || !shouldApplyTimeFilter || DateTime.fromISO(pick.dateTime) < timeInNYNow)
                    );
                });
                //console.log("FILTERED PICKS", filteredPicks);
                allFilteredPicks?.push(...filteredPicks);
            }
        } else {
            for (let i = 0; i < leaderboardData?.length; i++) {
                const userPicks = leaderboardData[i]?.picks || [];
                const filteredPicks = userPicks?.filter(pick => String(pick.week) <= startingWeekState && DateTime.fromISO(pick.dateTime) < timeInNYNow);
                allFilteredPicks?.push(...filteredPicks);
            }
        }

        teamStats.push({ filteredPicks: allFilteredPicks });
        return teamStats;
    }
    const teamStats = teamStatsData();
    //console.log("TEAM STATS", teamStats);

    function compileTeamStats(filteredPicks) {
        const teamStats = {};

        filteredPicks.forEach(pick => {
            const { gameId, teamId, spread, dateTime, result } = pick;

            if (!teamStats[teamId]) {
                teamStats[teamId] = {
                    gameId: gameId,
                    teamId: teamId,
                    timesSelected: 0,
                    spreads: new Set(), // using Set to keep unique spreads
                    dateTime: dateTime, // assuming dateTime is the same for all picks of the same team
                    result: {
                        Win: 0,
                        Loss: 0,
                        Push: 0,
                        Pending: 0
                    }
                };
            }

            // Increment the times selected
            teamStats[teamId].timesSelected += 1;

            // Add the spread to the set of spreads (Set ensures uniqueness)
            teamStats[teamId].spreads.add(spread);

            // Increment the result count
            if (result === 'Win' || result === 'Loss' || result === 'Push' || result === 'Pending') {
                teamStats[teamId].result[result] += 1;
            } else {
                teamStats[teamId].result.Pending += 1;
            }
            // Add win percentage to the teamStats object
            const winPercentage = (teamStats[teamId].result.Win / (teamStats[teamId].result.Win + teamStats[teamId].result.Loss + teamStats[teamId].result.Push)) * 100;
            teamStats[teamId].winPercentage = isNaN(winPercentage) ? ' - ' : winPercentage.toFixed(2) + '%';

            // Add Team Key for sorting purposes
            const teamName = teamKey(teamId);
            teamStats[teamId].teamKey = teamName;

            // Calculate the Overall Result
            let overallResult;
            let poolRecordColor = '#1E1E1E';
            const winCount = teamStats[teamId].result.Win;
            const lossCount = teamStats[teamId].result.Loss;
            const pushCount = teamStats[teamId].result.Push;
            const pendingCount = teamStats[teamId].result.Pending;
            
            if (winCount > 0 && lossCount === 0 && pushCount === 0 && pendingCount === 0) {
                overallResult = "Win";
                poolRecordColor = '#00AA72';
            } else if (winCount === 0 && lossCount > 0 && pushCount === 0 && pendingCount === 0) {
                overallResult = "Loss";
                poolRecordColor = '#CC293C';
            } else if (winCount === 0 && lossCount === 0 && pushCount > 0 && pendingCount === 0) {
                overallResult = "Push";
            } else if (winCount === 0 && lossCount === 0 && pushCount === 0 && pendingCount > 0) {
                overallResult = "Pending";
            } else {
                overallResult = "Split";
            }
            teamStats[teamId].overallResult = overallResult;
            teamStats[teamId].poolRecordColor = poolRecordColor;
            
        });

        // Convert spreads Set back to an array for each team
        for (const teamId in teamStats) {
            teamStats[teamId].spreads = Array.from(teamStats[teamId].spreads);
        }

        return teamStats;
    }

        // Assuming teamStatsData returns an array with a single object as we did before
        const teamStatsArray = teamStatsData();
        const filteredPicks = teamStatsArray[0]?.filteredPicks || [];
        const compiledTeamStats = compileTeamStats(filteredPicks);

        // Convert the compiledTeamStats object to an array
        const teamStatsArrayForSorting = Object.values(compiledTeamStats);

        
        // Sort the array by timesSelected
        const sortedTeamStats = teamStatsArrayForSorting?.sort((a, b) => b.timesSelected - a.timesSelected);

        //console.log("SORTED TEAM STATS", sortedTeamStats);

        const totalWeeklySelections = sortedTeamStats?.reduce((acc, team) => acc + team.timesSelected, 0);
        //console.log("TOTAL WEEKLY SELECTIONS", totalWeeklySelections);




    const handleSort = (columnName) => {
        if (sortBy === columnName) {
        // Toggle sorting order if the same column is clicked again
        setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
        // Sort by the clicked column
        setSortBy(columnName);
        if (columnName === 'timesPicked' || columnName === 'result' || columnName === 'winPercentage') {
            // For player name, always start with ascending order
            setSortOrder('desc');
        } else {
            // For other columns, use the current sort order
            setSortOrder('asc');
        }
        }
    };

    const sortedData = [...sortedTeamStats].sort((a, b) => {
        if (sortBy === 'timesPicked') {
        return sortOrder === 'asc' ? a.timesSelected - b.timesSelected : b.timesSelected - a.timesSelected;
        } else if (sortBy === 'teamKey') {
        return sortOrder === 'asc' ? a.teamKey.localeCompare(b.teamKey) : b.teamKey.localeCompare(a.teamKey);
        } else if (sortBy === 'result') {
        return sortOrder === 'asc' ? a.overallResult.localeCompare(b.overallResult) : b.overallResult.localeCompare(a.overallResult);
        } else if (sortBy === 'winPercentage') {
        const aWinPercentage = a.winPercentage === ' - ' ? -1 : parseFloat(a.winPercentage);
        const bWinPercentage = b.winPercentage === ' - ' ? -1 : parseFloat(b.winPercentage);
        
        return sortOrder === 'asc' ? aWinPercentage - bWinPercentage : bWinPercentage - aWinPercentage;
        }    
    });
    

    //   const timesPickedColor = sortBy === 'timesPicked' ? '#FFC60A' : '#fff';
    //   const teamKeyColor = sortBy === 'teamKey' ? '#FFC60A' : '#fff';
    //   const resultColor = sortBy === 'result' ? '#FFC60A' : '#fff';
    //   const winPercentageColor = sortBy === 'winPercentage' ? '#FFC60A' : '#fff';
    const timesPickedColor = '#fff';
    const teamKeyColor = '#fff';
    const resultColor = '#fff';
    const winPercentageColor = '#fff';

    //console.log("IS MOBILE", isMobile, seasonVsWeek);

    const totalEntrants = leaderboardData?.length;
    const weekNumber = parseInt(seasonVsWeek.split(' ')[1], 10);
    const totalAlive = leaderboardData?.filter(user => user.lossWeek === undefined || user.lossWeek === null || user.lossWeek >= weekNumber).length;
    const totalEliminated = leaderboardData?.filter(user => user.lossWeek === weekNumber).length;
    //console.log("Season vs Week**************************", seasonVsWeek, totalAlive, totalEliminated);

  return (
    <Box>
        <TableDropdown seasonVsWeek={seasonVsWeek} setSeasonVsWeek={setSeasonVsWeek} weekOptions={weekOptions} startingWeekState={startingWeekState} firstWeek={firstWeek}/>
        
        <div className="survivor-entry-info-container">
            <div className="survivor-entry-info">
                <div className="status-section">
                    <Typography 
                        variant="h6" 
                        className="status-header">
                        Alive Entering Week
                    </Typography>
                    <Typography variant="body1" className="status-details">
                        {totalAlive}
                    </Typography>
                </div>
                <div className="status-section">
                    <Typography variant="h6" className="status-header">
                        Weekly Eliminations
                    </Typography>
                    <Typography variant="body1" className="status-details">
                        {totalEliminated}
                    </Typography>
                </div>
            </div>
        </div>

        <TableContainer component={Paper} style={{ overflowX: "clip", maxWidth:'930px', margin:'0 auto', marginBottom: '30px', borderRadius: '0' }}>
            <Table>
                <TableHead sx={{ backgroundColor: '#002129' }}>
                    <TableRow>
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        // onClick={() => handleSort('teamKey')}
                        sx={{ width: isMobile ? '176px' : '410px', color: timesPickedColor, fontWeight: 700, textAlign: 'center', position: 'relative', fontSize: isMobile ? '14px' : '22px'}}
                    >
                        TEAM
                    </TableCell>
                    {!isMobile  && (
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        sx={{ width: '254px', color: resultColor, fontWeight: 700, textAlign: 'center', position: 'relative', fontSize: isMobile ? '14px' : '22px'} }
                    >
                        TIMES PICKED
                    </TableCell>
                    )}
                    <TableCell
                        className="pickem-leaderboard-table-cells"
                        // onClick={() => handleSort('winPercentage')}
                        sx={{ width: isMobile ? '199px' : '377px', color: winPercentageColor, fontWeight: 700, textAlign: 'center', position: 'relative', fontSize: isMobile ? '14px' : '22px'} }
                    >
                        {isMobile ? 'SELECTED' : 'PCT'}
                    </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedTeamStats?.map((team, index) => (
                        <TableRow key={index}>
                            {isMobile && (
                                <TableCell>
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                  {team.teamId === "No Pick" ? (
                                    <>
                                      <CloseIcon
                                        sx={{
                                          color: '#CC293C', // Customize color if needed
                                          width: '24px', // Adjust size if necessary
                                          height: '24px',
                                        }}
                                      />
                                      <div style={{ display: 'flex', flexDirection: 'column'}}>
                                        <p style={{ margin: 0, color: '#CC293C' }}>NO PICK</p>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        style={{ width: '24px', height: '24px', objectFit: 'contain' }}
                                        loading="lazy"
                                        alt={`${team.teamKey} Logo`}
                                        src={teamLogo(team.teamId)}
                                      />
                                      <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <p
                                          style={{
                                            margin: 0,
                                            color: team.overallResult === "Loss" ? '#CC293C' : 
                                                   team.overallResult === "Win" ? '#00AA72' : '#002129'
                                          }}
                                        >
                                          {`${teamKey(team.teamId)} ${teamName(team.teamId)}`}
                                        </p>
                                        <p
                                          style={{
                                            margin: 0,
                                            color: team.overallResult === "Loss" ? '#CC293C' : 
                                                   team.overallResult === "Win" ? '#00AA72' : '#002129'
                                          }}
                                        >
                                          {nflOpponentInfo(team.gameId, team.teamId)}
                                        </p>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </TableCell>
                              
                            )}
                            {!isMobile && (
                                <TableCell>
                                    <div className="pick-stats-team-bar-desktop">
                                    {team.teamId === "No Pick" ? (
                                        <>
                                            <CloseIcon
                                                sx={{
                                                    color: '#CC293C', // Customize the color if needed
                                                    width: '40px', // Adjust the size if necessary
                                                    height: '40px',
                                                }}
                                            />
                                            <div className="pick-stats-team-text-container-desktop">
                                                <div className="selected-team-desktop" style={{ color: '#CC293C' }}>
                                                    NO PICK
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <img 
                                                className="team-logo-desktop-img" 
                                                loading="lazy" 
                                                alt={`${team.teamKey} Logo`} 
                                                src={teamLogo(team.teamId)} 
                                            />
                                            <div className="pick-stats-team-text-container-desktop" style={{ color: team.overallResult === "Loss" ? '#CC293C' : team.overallResult === "Win" ? '#00AA72' : '#002129' }}>
                                                <div className="selected-team-desktop">{`${teamKey(team.teamId)} ${teamName(team.teamId)} ${nflOpponentInfo(team.gameId, team.teamId)}`}</div>
                                            </div>
                                        </>
                                    )}
                                    </div>
                                </TableCell>
                            )}
                            {!isMobile && (
                                <TableCell>
                                    <Typography
                                        sx={{
                                            fontSize: '22px',
                                            textAlign: 'center',
                                            fontWeight: '500',
                                            color: team.overallResult === "Loss" ? '#CC293C' : team.overallResult === "Win" ? '#00AA72' : '#002129',
                                        }}
                                    >
                                        {team.timesSelected}
                                    </Typography>
                                </TableCell>
                            )}
                            {!isMobile && (
                                <TableCell>
                                    <PercentagePillBox
                                        percentage={team.timesSelected/totalWeeklySelections * 100}
                                        result={team.overallResult}
                                    />
                                </TableCell>
                            )}
                            {isMobile && (
                                <TableCell>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            height: '100%', // Ensures full height of the TableCell is used
                                            textAlign: 'center',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '700',
                                                color: team.overallResult === "Loss" ? '#CC293C' : team.overallResult === "Win" ? '#00AA72' : '#002129',
                                            }}
                                        >
                                            {team.timesSelected}
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: 1 // Margin top for spacing
                                            }}
                                        >
                                            <PercentagePillBox
                                                percentage={team.timesSelected/totalWeeklySelections * 100}
                                                result={team.overallResult}
                                            />
                                        </Box>
                                    </Box>
                                </TableCell>
                            )}
                        </TableRow>
                    ))}
                    {sortedTeamStats?.length === 0 && (
                        <TableRow>
                            <TableCell colSpan={4} style={{ textAlign: 'center', padding: '20px', color: '#002129' }}>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '16px' : '20px',
                                    fontWeight: 700,
                                    marginBottom: '20px'
                                }}
                            >
                                {isMobile
                                    ? (
                                        <>
                                            Pick Stats will be available upon the <br />
                                            game start for each team.
                                        </>
                                    )
                                    : 'Pick Stats will be available upon the game start for each team.'
                                }
                            </Typography>
                            <Typography
                                sx={{
                                    fontSize: isMobile ? '16px' : '20px',
                                    fontWeight: 700,
                                }}
                            >
                                {isMobile
                                    ? (
                                        <>
                                            Only teams that have been picked will <br />
                                            appear in this table.
                                        </>
                                    )
                                    : 'Only teams that have been picked will appear in this table.'
                                }
                            </Typography>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>

            </Table>
        </TableContainer>
    </Box>

  );
};

export default SurvivorPickStats;