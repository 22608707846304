import React from 'react';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';


const PickEmPick5Display = ({ currentWeek, userPicks }) => {

    // const totalWeeklyPicks = 5;
    // const seasonPoints = 87;
    // const seasonWins = 86;
    // const seasonLosses = 2;
    // const seasonTies = 2;
    // const weeklyWins = 5;
    // const weeklyLosses = 0;
    // const weeklyTies = 0;

    const seasonRecord = () => {
        let wins = 0;
        let losses = 0;
        let ties = 0;
        let points = 0;
        userPicks?.picks?.forEach((pick) => {
          if (pick.result === "Win") {
            wins++;
          } else if (pick.result === "Loss") {
            losses++;
          } else if (pick.result === "Push") {
            ties++;
          }
        });
        points = wins + (ties * 0.5);
        return { wins, losses, ties, points };
      };
    
      const { wins: seasonWins, losses: seasonLosses, ties: seasonTies, points: seasonPoints } = seasonRecord();
    
      const weeklyRecord = () => {
        let wins = 0;
        let losses = 0;
        let ties = 0;
        userPicks?.picks?.forEach((pick) => {
          if (pick.week === currentWeek) {
            if (pick.result === "Win") {
              wins++;
            } else if (pick.result === "Loss") {
              losses++;
            } else if (pick.result === "Push") {
              ties++;
            }
          }
        });
        return { wins, losses, ties };
      };
    
      const { wins: weeklyWins, losses: weeklyLosses, ties: weeklyTies } = weeklyRecord();
    
      const totalWeeklyPicks = userPicks?.picks?.filter(
        (pick) => pick.week === currentWeek
      )?.length ?? 0;
      


    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'stretch',
            maxWidth: '1102px',
            margin: '0 auto',
            width:'90%'
          }}>
            {isMobile ? (
              // These items will be displayed on mobile devices
              <>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>POINTS</Typography>
                  {/* <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonPoints}</Typography> */}
                  <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800', marginTop: '3px'}}>{seasonPoints}</Typography>
                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>SEASON</Typography>
                  {/* <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonWins}-{seasonLosses}-{seasonTies}</Typography> */}
                  <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800', marginTop: '3px'}}>{seasonWins}-{seasonLosses}-{seasonTies}</Typography>

                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {currentWeek}</Typography>
                  {/* <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800'}}>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography> */}
                  <Typography className={"pick-em-header-values"} variant='h3' sx={{ fontWeight: '800', marginTop: '3px'}}>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography>

                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {currentWeek} STATUS</Typography>
                  <Box className="pick-em-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {totalWeeklyPicks === 5 ? (
                      <>
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '23px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalWeeklyPicks}/5)
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '23px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalWeeklyPicks}/5)
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              // These items will be displayed on desktop devices
              <>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>POINTS</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4'>{seasonPoints}</Typography>
                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'>SEASON</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4'>{seasonWins}-{seasonLosses}-{seasonTies}</Typography>
                </Box>
                <Box>
                  <Typography className={"pick-em-header-headings"} variant='h4'> WEEK {currentWeek}</Typography>
                  <Typography className={"pick-em-header-values"} variant='h4'>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                  <Typography className={"pick-em-header-headings"} variant='h4'>WEEK {currentWeek} STATUS</Typography>
                  <Box className="pick-em-header-values" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {totalWeeklyPicks === 5 ? (
                      <>
                        <CheckIcon sx={{ color: '#00AA72', fontSize: '22px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#00AA72', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalWeeklyPicks}/5)
                        </Typography>
                      </>
                    ) : (
                      <>
                        <CloseIcon sx={{ color: '#cc293c', fontSize: '22px' }} />
                        <Typography className="pick-em-header-values" variant='h3' sx={{ color: '#CC293C', fontWeight: '500', marginLeft: '8px' }}>
                          ({totalWeeklyPicks}/5)
                        </Typography>
                      </>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Box>
    );

}

export default PickEmPick5Display;