import React, { useState, useEffect } from "react";
import MyPicks from "./MyPicks/MyPicks";
import SurvivorNav from "./Headers/SurvivorNav";
import SurvivorHeader from "./Headers/SurvivorHeader";
import SurvivorUserDash from "./Headers/SurvivorUserDash";
import SurvivorHome from "./SurvivorHome/SurvivorHome";
import MyParty from "../MyParty/MyParty";
import PartyDropdown from "../PartyDropdown/MyPartyDropdown";
import SurvivorLeaderboard from "./Leaderboard/SurvivorLeaderboard";
import SurvivorPickStats from "./PickStats/SurvivorPickStats";
import NFLSurvivorRules from "./Rules/NFLSurvivorRules";
import { useNFLSurvivorContext } from "./context/NFLSurvivorContext";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { DateTime } from "luxon";

const NFLSurvivorContainer = () => {
  const { component, groupStateCheck } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    gameData,
    myUserId,
    mainGameId,
    gameAddress,
    handleCreateSubgroup,
    userSubgroups, 
    signUpState,
    setSignUpState,
    NFLScoresBySeason,
    setWeek,
    startingWeekState, 
    clickState,
    setClickState,
    partyState,
    setPartyState,
    partyId,
    setPartyId,
    defaultPartyId,
    defaultPartyName,
    firstSubgroupId,
    firstSubgroupName,
    leaderboardInfo,
    doesUserBelong,
    joinOpen,
    setJoinOpen 
  } = useNFLSurvivorContext();
  
  //console.log("PartyState in Container: ", partyState, "Party Id in Container: ", partyId);
  useEffect(() => {
    // Update the clickState whenever the URL changes
    if (component) {
      setClickState(component);
    }
  }, [component, location]);

  const uriEncoded = (link) => {
    return encodeURI(`${encodeURIComponent(link)}`);
  }

  // Getting the current week's Sunday 1:00 PM dealine
  const currentWeeksSchedule = NFLScoresBySeason?.filter(game => game.Week === startingWeekState);
  const currentWeeksDeadline = currentWeeksSchedule?.find(game => {
      const gameDateTime = DateTime.fromISO(game.DateTime, { zone: 'America/New_York' });
      return gameDateTime.weekday === 7 && gameDateTime.hour === 13;
  })?.DateTime;
  //console.log("Leaderboard Current Week's Deadline:", currentWeeksDeadline);

  const currentWeeksDeadlineDateTime = currentWeeksDeadline 
  ? DateTime.fromISO(currentWeeksDeadline, { zone: 'America/New_York' }) 
  : null;

  let fullLeaderboard = leaderboardInfo ? leaderboardInfo : [];
  fullLeaderboard?.sort((a, b) => (b.points || 0) - (a.points || 0));

  //console.log("Sorted Leaderboard in Container: ", fullLeaderboard);

  const [leaderboardData, setLeaderboardData] = useState(fullLeaderboard);

  useEffect(() => {
    if (partyId !== mainGameId) {
      //console.log("PartyId in Container: ", partyId, gameData);
      const subgroups = gameData?.subGroups
      const filteredSubgroups = subgroups?.filter(subgroup => subgroup.groupID === partyId);
      const filteredUserIds = filteredSubgroups?.[0]?.groupMembers;
      //console.log("Chosen Subgroup in Container: ", subgroups, filteredSubgroups, filteredUserIds);
      const filteredLeaderboard = fullLeaderboard?.filter(user => filteredUserIds?.includes(user.userId));
      //console.log("Filtered Leaderboard in Container: ", filteredLeaderboard);
      setLeaderboardData(filteredLeaderboard);
    } else {
      setLeaderboardData(fullLeaderboard);
    }
    }, [partyId, partyState, fullLeaderboard]);

  const navigateToTop = useNavigateToTop();

  const handleClickState = (click) => {
    setClickState(click);
    let pageStateToUse = partyId;
    if (click === 'MY_PICKS') {
      pageStateToUse = 'CURRENT';
      setWeek(startingWeekState);
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    } else if (click === 'HOME') {
      pageStateToUse = 'HOME';
      setPartyState(defaultPartyName);
      setPartyId(defaultPartyId);
    }
    if (click === 'MY_PARTY' && userSubgroups.length === 0) {
      pageStateToUse = 'NO_PARTY';
    } else if (click === 'MY_PARTY' && partyId === mainGameId) {
      if (defaultPartyName !== 'Pool Party') {
        pageStateToUse = defaultPartyId;
        setPartyState(defaultPartyName);
        setPartyId(defaultPartyId);
      } else {
        pageStateToUse = firstSubgroupId;
        setPartyState(firstSubgroupName);
        setPartyId(firstSubgroupId);
      }
    }
    navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(mainGameId)}/${uriEncoded(click)}/${uriEncoded(pageStateToUse)}`);
  }

  return (
    <div>
      <SurvivorHeader />

      {myUserId && <SurvivorUserDash currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      <SurvivorNav 
        setClickState={handleClickState} 
        clickState={clickState} 
        setSignUpState={setSignUpState}
        doesUserBelong={doesUserBelong}/>

      {(userSubgroups?.length > 0 && ((clickState === 'MY_PARTY' && !signUpState) || clickState === 'LEADERBOARD' || clickState === 'PICK_STATS')) ?
      <PartyDropdown 
        userSubgroups={userSubgroups} 
        gameAddress={gameAddress}
        partyState={partyState}
        setPartyState={setPartyState}
        clickState={clickState}
        setPartyId={setPartyId}
      /> 
        : 
      null}
      
      {clickState === 'MY_PICKS' && <MyPicks currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      {clickState === 'HOME' && <SurvivorHome />}

      {clickState === 'RULES' && <NFLSurvivorRules />}

      {clickState === 'LEADERBOARD' && <SurvivorLeaderboard leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime}/>}

      {clickState === 'PICK_STATS' && <SurvivorPickStats leaderboardData={leaderboardData} currentWeeksDeadlineDateTime={currentWeeksDeadlineDateTime} />}

      {clickState === 'MY_PARTY' && <MyParty 
          gameData={gameData}
          myUserId={myUserId}
          handleCreateSubgroup={handleCreateSubgroup}
          userSubgroups={userSubgroups} 
          signUpState={signUpState}
          setSignUpState={setSignUpState}
          gameAddress={gameAddress}
          leaderboardData={leaderboardData}
          partyState={partyState}
          partyId={partyId}
          defaultPartyId={defaultPartyId}
          fullLeaderboard={fullLeaderboard}
          doesUserBelong={doesUserBelong}
          joinOpen={joinOpen}
          setJoinOpen={setJoinOpen}
      />}
      
    </div>
  );
};

export default NFLSurvivorContainer;
