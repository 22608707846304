import React, { useState } from "react";
import { useNFLPickEmContext } from "../context/NFLPickEmContext";
import { Typography, Button, Box, Modal } from "@mui/material";
import CreatePartyInfo from "../../MyParty/CreatePartyInfo";
import PoolRankingsTable from "./PoolRankingsTable";
import { useJoinPublicPool } from "../../PublicPools/hooks/useJoinPublicPools";
import { useParams } from "react-router-dom";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import JoinGameModal from "../../GameModals/JoinGameModal/JoinGameModal";
import { CardMedia } from '@mui/material';

const PickEmHome = () => {
    const { 
        setSignUpState, 
        gameAddress, 
        userData, 
        gameData, 
        setWeek, 
        startingWeekState, 
        userSubgroups, 
        doesUserBelong,
        mainGameId, 
        myUserId, 
        partyState, 
        setPartyState, 
        partyId, 
        setPartyId, 
        setDefaultPartyId, 
        setDefaultPartyName,
        joinOpen,
        setJoinOpen,
        leaderboardInfo
     } = useNFLPickEmContext();

    const { gameId } = useParams();
    const gameType = "Pickem";
    const gameName = gameData?.gameName;
    //console.log("gameId", gameId, "Game Data", gameData);
    const logo = gameData?.clubLogo;
    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const [showModal, setShowModal] = useState(false);
    const [modalImage, setModalImage] = useState(null);

    const handleCloseModal = () => {
        setShowModal(false);
        setModalImage(null);
    }

    const handleOpenModal = (image) => {
        setShowModal(true);
        setModalImage(image);
    }

    

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    }

    const handleRulesClick = () => {
        navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/RULES/RULES`);
    }

    const handleJoinClick = () => {
        if (!userData) {
            navigateToTop(`/login/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/HOME/HOME`);
        } else {
            setJoinOpen(true);
        }
    }


    return (
        <div>
            <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        height: '160px', // 160px height + 32px margin top + 32px margin bottom
                        marginBottom: doesUserBelong ? '0px' : '32px',
                        marginTop: '32px'
                    }}
                >
                    <img 
                        src={logo} 
                        alt="Logo" 
                        style={{ 
                            height: '160px', 
                            width: '128px', 
                            objectFit: 'contain' 
                        }} 
                    />
                </Box>

                <Typography
                style={{
                    color: '#002129',
                    textAlign: 'center',
                    fontSize: isMobile ? '16px' : '29px',
                    fontWeight: isMobile ? '700' : '800',
                    marginTop: doesUserBelong ? '32px' : '0px',
                    marginBottom: '16px'
                }}
                >
                The most strategic NFL Pick 'Em competition.
            </Typography>
            <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: isMobile ? 'flex-start' : 'center',
                    justifyContent: 'center',
                    width: isMobile ? '311px' : '1000px',
                    margin: '0 auto' // This centers the box horizontally
                }}
            >
                <Box 
                sx={{ 
                    display: 'flex', 
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: isMobile ? '311px' : '1000px',
                    margin: '0 auto',
                    marginBottom: '8px'
                }}
            >
                <Typography 
                    variant="body1" 
                    component="span" 
                    sx={{ 
                        fontSize: isMobile ? '12px' : '14px',
                        textAlign: isMobile ? 'left' : 'center' 
                    }}
                >
                    <span style={{ fontWeight: 700 }}>Favorite 5 </span>
                    - Analyze and choose your 5 most confident picks from the full weekly slate.
                </Typography>
                </Box>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: isMobile ? '311px' : '1000px',
                        margin: '0 auto' // This centers the box horizontally
                    }}
                >
                    <Typography 
                        variant="body1" 
                        component="span" 
                        sx={{ 
                            fontSize: isMobile ? '12px' : '14px',
                            textAlign: isMobile ? 'left' : 'center', // Ensure left alignment for text
                            marginBottom: '16px'
                        }}
                    >
                        <span style={{ fontWeight: 700 }}>Live Lines </span>
                        - Live lines create opportunities to lock in or wait for the best odds.
                    </Typography>
                </Box>
            </Box>


            {doesUserBelong && (
                <>
                    <Box 
                        sx={{ 
                            display: 'flex', 
                            flexDirection: isMobile ? 'column' : 'row', 
                            alignItems: 'center',
                            justifyContent: 'center', // Center buttons horizontally on desktop
                            gap: isMobile ? 0 : '40px', // Add space between buttons on desktop
                            mt: isMobile ? 0 : '32px' // Add top margin on desktop
                        }}
                    >
                        <Button 
                            variant="contained" 
                            sx={{ 
                                fontSize: '14px', 
                                fontWeight: 700, 
                                width: '182px', 
                                height: '36px', 
                                mb: isMobile ? '8px' : 0 // Adjust margin bottom on mobile
                            }}
                            onClick={() => {
                                setWeek(startingWeekState);
                                navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded("MY_PICKS")}/${uriEncoded(startingWeekState)}`);
                            }}
                        >
                            MY PICKS
                        </Button>
                        <Button 
                            variant="contained" 
                            onClick={() => navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/RULES/RULES`)}
                            sx={{ 
                                fontSize: '14px', 
                                fontWeight: 700, 
                                width: '182px', 
                                height: '36px'
                            }}
                        >
                            POOL RULES
                        </Button>
                    </Box>
                    {userSubgroups?.length > 0 && (
                        <>
                            <Box
                                sx={{
                                    marginBottom: '16px', 
                                }}
                            >
                                <PoolRankingsTable />
                            </Box>
                            <CreatePartyInfo 
                                setSignUpState={setSignUpState} 
                                gameAddress={gameAddress}
                                doesUserBelong={doesUserBelong}
                                gameName={gameName}
                                setJoinOpen={setJoinOpen}
                            />
                        </>
                    )}
                </>
            )}

            {(doesUserBelong && userSubgroups?.length === 0) && (
                <>
                    <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '24px',
                            fontWeight: 700,
                            textDecorationLine: 'underline',
                            marginTop: '24px',
                            marginBottom: '8px'
                        }}
                        >
                        My Party
                    </Typography>
                    <Typography
                        style={{
                            color: '#002129',
                            textAlign: 'center',
                            fontSize: '16px',
                            fontWeight: 600,
                            marginBottom: '16px'
                        }}
                        >
                        Create an NFL Pick ‘Em - Fav 5 competition <br/>with your custom group.
                    </Typography>
                    
                    <CreatePartyInfo 
                        joinOpen={joinOpen}
                        setJoinOpen={setJoinOpen}
                        gameName={gameName}
                        gameAddress={gameAddress}
                        gameId={mainGameId}
                        gameType={gameType}
                        logo={logo}
                        myUserId={myUserId}
                        setSignUpState={setSignUpState}
                        doesUserBelong={doesUserBelong}
                    />
                </>
            )}

            {!doesUserBelong && (
                <Box 
                    sx={{ 
                        display: 'flex', 
                        justifyContent: 'center', 
                        alignItems: 'center', 
                        gap: '16px', 
                        marginTop: '8px',
                    }}
                >
                    <Button 
                        variant="contained" 
                        onClick={() => handleJoinClick()}
                        sx={{ 
                            fontSize: '14px', 
                            fontWeight: '700', 
                            width: '100px', 
                            height: '36px', 
                            whiteSpace: 'nowrap' 
                        }}
                    >
                        JOIN POOL
                    </Button>
                    <Button 
                        variant="contained"
                        onClick={() => navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/RULES/RULES`)} 
                        sx={{ 
                            fontSize: '14px', 
                            fontWeight: '700', 
                            width: '100px', 
                            height: '36px' 
                        }}
                    >
                        RULES
                    </Button>
                </Box>
            )}

            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr',
                    gap: '40px',
                    alignItems: 'start',
                    justifyItems: 'center',
                    width: isMobile ? '100%' : '1000px',
                    margin: '0 auto', // This centers the grid container horizontally
                    mt: '32px'
                }}
            >
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'centere',
                        justifyContent: 'center',
                        width: isMobile ? '311px' : '450px',
                        margin: isMobile ? '0 auto' : '0',
                    }}
                >
                    {!doesUserBelong && (
                    <>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: '#002129', 
                                textAlign: 'center', 
                                fontSize: isMobile ? '16px' : '20px',
                                fontWeight: isMobile ? '700' : '800', 
                                textDecoration: 'underline',
                                margin: isMobile ? '0 auto' : '0',
                                marginBottom: '16px',
                                marginTop: '16px'
                            }}
                        >
                            Pool Entry
                        </Typography>
                        <Typography 
                            variant="body1" 
                            sx={{ 
                                color: '#002129', 
                                textAlign: 'center', 
                                fontSize: isMobile ? '12px' : '18px',
                                fontWeight: '700',
                                marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                            }}
                        >
                            FREE!
                        </Typography>
                    </>
                    )}
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'center', 
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: isMobile ? '700' : '800', 
                            textDecoration: 'underline',
                            margin: isMobile ? '0 auto' : '0',
                            marginBottom: '16px',
                            marginTop: '16px'
                        }}
                    >
                        Pool Prizes
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'center', 
                            fontSize: isMobile ? '12px' : '18px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                        }}
                    >
                         <span style={{ display: 'block',  textAlign: 'center' }}>
                         <span style={{ fontWeight: 700 }}>1st Place</span> - $250
                            </span>
                            <span style={{ display: 'block', textAlign: 'center' }}>
                            <span style={{ fontWeight: 700 }}>2nd Place</span> - $100
                            </span>
                            <span style={{ display: 'block', textAlign: 'center' }}>
                            <span style={{ fontWeight: 700 }}>3rd Place</span> - $50
                            </span>
                            <span style={{ display: 'block', textAlign: 'center', marginTop: '20px' }}>
                            <span style={{ fontWeight: 700 }}>Last Place*</span> - $100
                            </span>
                            <span style={{ display: 'block', textAlign: 'center'}}>
                            * To be eligible, you must have 0 missed picks through all 18 weeks.
                            </span>
                    </Typography>
                </Box>
            </Box>
            


            


            <Box
                sx={{
                    display: 'grid',
                    gridTemplateColumns: isMobile ? '1fr' : '1fr 1fr',
                    gap: '40px',
                    alignItems: 'start',
                    justifyItems: 'center',
                    width: isMobile ? '100%' : '1000px',
                    margin: '0 auto', // This centers the grid container horizontally
                    mt: '32px'
                }}
            >
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'center',
                        width: isMobile ? '311px' : '289px',
                        margin: isMobile ? '0 auto' : '0',
                    }}
                >
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'center', 
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: isMobile ? '700' : '800', 
                            textDecoration: isMobile ? 'underline' : 'none',
                            margin: isMobile ? '0 auto' : '0',
                            marginBottom: '16px',
                            marginTop: '16px'
                        }}
                    >
                        How to Play
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                        }}
                    >
                        Each week during the NFL regular season, pool members will select 
                        five (5) NFL games against the spread (ATS) from the full weekly NFL 
                        schedule. The goal is to accumulate the most points from your picks at the end of week 18.
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                        }}
                    >
                        <span style={{ fontWeight: 700 }}>NEW:</span> The point spreads for each matchup are dynamic, meaning they can 
                        change at any time leading up to kickoff. Factors like injuries, breaking 
                        news, and overall betting trends can cause spreads to shift.
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px'  // Add margin to separate sentences
                        }}
                    >
                        Multiple pool members may select the same team but with different point spreads, potentially leading to different outcomes.
                    </Typography>
                    
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px' // Add margin to separate sentences
                        }}
                    >
                        <span style={{ fontWeight: 700 }}>NEW:</span> Along with the current week, points spreads for the following week may be available. 
                        This will allow pool members to lock in picks for upcoming weeks early.
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px'  // Add margin to separate sentences
                        }}
                    >
                        Once a pick is made, it is final and cannot be changed.
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px'  // Add margin to separate sentences
                        }}
                    >
                        Winning picks earn 1 point, pushes earn 0.5 points, and losses earn 0 points.
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '20px'  // Add margin to separate sentences
                        }}
                    >
                        The player with the highest point total at the end of the regular season is crowned the champion.
                    </Typography>
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: 'left', 
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: isMobile ? '10px' : '195px'  // Add margin to separate sentences
                        }}
                    >
                        See the {' '}
                        <span 
                            style={{ 
                                color: '#00AAD1', 
                                textDecoration: 'underline', 
                                cursor: 'pointer'
                            }} 
                            onClick={handleRulesClick}
                        >
                            Rules
                        </span> 
                       {' '} for full details!
                    </Typography>
                </Box>
                <Box 
                    sx={{ 
                        display: 'flex', 
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography 
                        variant="body1" 
                        sx={{ 
                            color: '#002129', 
                            textAlign: isMobile ? 'center' : 'left', 
                            fontSize: isMobile ? '16px' : '20px',
                            fontWeight: isMobile ? '700' : '800', 
                            marginTop: isMobile ? '0px' : '16px',
                            marginBottom: '16px',
                            textDecoration: isMobile ? 'underline' : 'none',
                            alignSelf: isMobile ? 'center' : 'flex-start' 
                        }}
                    >
                        Pool Preview
                    </Typography>
                    <Typography
                        variant="body1"
                        sx={{
                            color: '#002129',
                            textAlign: 'left',
                            fontSize: isMobile ? '12px' : '14px',
                            fontWeight: '500',
                            marginBottom: '16px',
                            width: '311px'
                        }}
                    >
                        Learn how to start playing by watching this easy to follow video tutorial..
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            padding: '16px 0px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '10px',
                            alignSelf: 'stretch',
                            width: isMobile ? '192px' : '321px',
                            height: isMobile ? '294px' : '220px',
                            border: '1px solid #000',
                            background: '#FFF',
                            margin: '0 auto'
                        }}
                    >
                        <CardMedia
                            component="video"
                            src={"https://poolpartyfilestorage.blob.core.windows.net/media/poolPartyNFLPick5Video.mov"}
                            allow="autoPlay"
                            controls
                            sx={{
                                width: '100%',
                                height: '100%', // This ensures it fills the height of the Box
                                objectFit: isMobile ? 'fill' : 'contain',
                            }}
                        />
                    </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: isMobile ? '10px' : '60px',
                                marginTop: '32px'
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                    }}
                                >
                                    My Picks
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmMyPicks.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmMyPicks.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                        marginTop: isMobile ? '24px' : '0px'
                                    }}
                                >
                                    Leaderboard
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmLeaderboard.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmLeaderboard.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: isMobile ? '10px' : '60px',
                                marginTop: '32px'
                            }}
                        >
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                        marginTop: isMobile ? '24px' : '0px'
                                    }}
                                >
                                    Pool Picks
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmPoolPicks.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmPoolPicks.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            <Box>
                                <Typography
                                    variant="body1"
                                    sx={{
                                        color: '#002129',
                                        textAlign: 'center',
                                        fontSize: isMobile ? '16px' : '16px',
                                        fontWeight: isMobile ? '700' : '800',
                                        marginBottom: isMobile ? '8px' : '16px',
                                        marginTop: isMobile ? '24px' : '0px'
                                    }}
                                >
                                    Team Stats
                                </Typography>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        //padding: '16px 0px',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '10px',
                                        alignSelf: 'stretch',
                                        width: '122px',
                                        height: '235px',
                                        //border: '1px solid #000',
                                        background: '#FFF',
                                        margin: '0 auto',
                                        marginBottom: isMobile ? '32px' : '0',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => handleOpenModal("https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmTeamStats.webp")}
                                >
                                    <img 
                                        src="https://poolpartyfilestorage.blob.core.windows.net/media/nflPickEmTeamStats.webp" 
                                        alt="Image placeholder" 
                                        style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'fill' }} 
                                    />
                                </Box>       
                            </Box>
                            
                        </Box>
                </Box>
            </Box>
            {joinOpen && (
                <JoinGameModal
                    joinOpen={joinOpen}
                    setJoinOpen={setJoinOpen}
                    gameName={gameName}
                    gameAddress={gameAddress}
                    gameId={gameId}
                    gameType={gameType}
                    logo={logo}
                    myUserId={myUserId}
                    setSignUpState={setSignUpState}
                />
            )}
            {showModal && (
                <Modal
                    open={showModal}
                    onClose={handleCloseModal}
                    sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '0 1rem',
                    }}
                >
                    <Box
                    sx={{
                        width: isMobile ? '300px' : '600px',
                        height: isMobile ? '500px' : '750px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        backgroundColor: 'white',
                        borderRadius: '4px',
                        overflow: 'hidden',
                        boxSizing: 'border-box', // Ensure padding is included in the dimensions
                    }}
                    >
                    <Box
                        sx={{
                        width: '100%',
                        height: '95%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflowY: 'auto',
                        }}
                    >
                        <img
                        src={modalImage}
                        alt="Image placeholder"
                        style={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            maxHeight: 'calc(100% - 50px)', // Ensure button space
                        }}
                        />
                        <Button 
                            variant="contained"
                            onClick={handleCloseModal}
                            sx={{
                                marginTop: '10px',
                            }}
                        >
                            CLOSE
                        </Button>
                    </Box>
                    </Box>
                </Modal>
            )}
        </div>
    )
}

export default PickEmHome;