import React from "react";
import { Typography, Box, Modal, Button } from "@mui/material";
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Pool } from "@mui/icons-material";

const DynamicPoolHeaderBar = ({ clickState, navigationText, headerText, gameAddress, gameId}) => {
   
    const navigateToTop = useNavigateToTop();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    const uriEncoded = (link) => {
        return encodeURI(`${encodeURIComponent(link)}`);
    };

    const handleNavigateClick = () => {
        if (clickState === "HOME") {
            navigateToTop(`/pool-hall`);
        } else {
            navigateToTop(`/${uriEncoded(gameAddress)}/${uriEncoded(gameId)}/${uriEncoded("HOME")}/${uriEncoded("HOME")}`);
        }
    }


    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'column',
                    backgroundColor: '#002129',
                }}
                className="pickem-header"
            >
                <Box
                    sx={{
                        display: 'flex',
                        width: '100%',
                        padding: '0px 20px',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        height: isMobile ? '32px' : '68px',
                    }}
                >
                    <Box
                        onClick={handleNavigateClick}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'pointer', // Change cursor to pointer
                            '&:hover': {
                                '& .MuiTypography-root': {
                                    color: '#FFC60A' // Change text color on hover
                                },
                                '& .MuiSvgIcon-root': {
                                    color: '#FFC60A' // Change icon color on hover
                                }
                            }
                        }}
                    >
                        <ArrowBackIosNewIcon 
                            sx={{ 
                                color: 'white',
                                fontSize: isMobile ? '10px' : '14px', 
                                marginLeft: isMobile ? '0px' : '25px',
                            }} 
                        />
                        <Typography 
                            variant="h1" 
                            sx={{ 
                                color: 'white', 
                                fontSize: isMobile ? '12px' : '16px', 
                                fontWeight: '500', 
                                marginLeft: '8px' 
                            }}
                        >
                            {clickState === "HOME" ? "Pool Hall" : navigationText}
                        </Typography>
                    </Box>
                    <Typography 
                        variant="h1" 
                        sx={{ 
                            color: 'white', 
                            fontSize: isMobile ? '16px' : '28px', 
                            fontWeight: '700',
                            marginRight: isMobile ? '0px' : '32px',
                            whiteSpace: 'nowrap', 
                        }}
                    >
                        {headerText}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                        height: 'var(--borderRadius, 4px)',
                        flexShrink: 0,
                        backgroundColor: '#FFC60A',
                    }}
                />
            </Box>
            
        </>
    );
};

export default DynamicPoolHeaderBar;
