import React, { useState } from 'react';
import { Box, Modal, Button, Typography, Paper, Table, TableHead, TableRow, TableCell, TableBody, TableContainer } from '@mui/material';
import bracketTrophy from '../../../../assets/images/bracketTrophy.png';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import './PickEmUserStatsModal.scss';
import PickEmUserStatslWeklyPicksTable from './PickEmUserStatsWeeklyPicksTable';
import PickEmUserStatsTeamsTable from './PickEmUserStatsTeamsTable';


const PickEmUserStatsModal = ({ statsModalOpen, setStatsModalOpen, myUsername, usersPicks, NFLTeams, areTheseMyPicks, startingWeekState, NFLScoresBySeason }) => {

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
    const handleClose = () => setStatsModalOpen(false);

    const [tableState, setTableState] = useState('weekly');
    const handleButtonClick = (button) => {
        setTableState(button);
        //console.log("Button Clicked", button);
    }

    function calculatePlayerStats() {
        let totalWins = 0, totalLosses = 0, totalTies = 0;
        let totalPoints = 0;
        let weeklyWins = 0, weeklyLosses = 0, weeklyTies = 0;
        let homeWins = 0, homeLosses = 0, homeTies = 0;
        let awayWins = 0, awayLosses = 0, awayTies = 0;
        let favoriteWins = 0, favoriteLosses = 0, favoriteTies = 0;
        let underdogWins = 0, underdogLosses = 0, underdogTies = 0;

        usersPicks?.forEach((pick) => {
            if (pick.result === 'Win') {
                totalWins++;
                totalPoints++;
                if (pick.week === startingWeekState) weeklyWins++;
                if (pick.isHomeSelected) homeWins++; else awayWins++;
                if (pick.spread < 0) favoriteWins++; else if (pick.spread > 0) underdogWins++;
            } else if (pick.result === 'Loss') {
                totalLosses++;
                if (pick.week === startingWeekState) weeklyLosses++;
                if (pick.isHomeSelected) homeLosses++; else awayLosses++;
                if (pick.spread < 0) favoriteLosses++; else if (pick.spread > 0) underdogLosses++;
            } else if (pick.result === 'Push') {
                totalTies++;
                totalPoints += 0.5;
                if (pick.week === startingWeekState) weeklyTies++;
                if (pick.isHomeSelected) homeTies++; else awayTies++;
                if (pick.spread < 0) favoriteTies++; else if (pick.spread > 0) underdogTies++;
            }
        });

        return { totalPoints, totalWins, totalLosses, totalTies, homeWins, homeLosses, homeTies, awayWins, awayLosses, awayTies, favoriteWins, favoriteLosses, favoriteTies, underdogWins, underdogLosses, underdogTies, weeklyWins, weeklyLosses, weeklyTies };
    }

    const { totalPoints, totalWins, totalLosses, totalTies, homeWins, homeLosses, homeTies, awayWins, awayLosses, awayTies, favoriteWins, favoriteLosses, favoriteTies, underdogWins, underdogLosses, underdogTies, weeklyWins, weeklyLosses, weeklyTies } = calculatePlayerStats();
    //console.log("Total Poinyd", totalPoints, "Toal Wins", totalWins, "Total Losses", totalLosses, "Total Ties", totalTies, "Home Wins", homeWins, "Home Losses", homeLosses, "Home Ties", homeTies, "Away Wins", awayWins, "Away Losses", awayLosses, "Away Ties", awayTies, "Favorite Wins", favoriteWins, "Favorite Losses", favoriteLosses, "Favorite Ties", favoriteTies, "Underdog Wins", underdogWins, "Underdog Losses", underdogLosses, "Underdog Ties", underdogTies, "Weekly Wins", weeklyWins, "Weekly Losses", weeklyLosses, "Weekly Ties", weeklyTies);

    
   
    return (
        <div>
        <Modal
            open={statsModalOpen}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            }}          
        >
            <Box sx={{
                display: 'flex',
                width: isMobile ? '359px' : '1115px',
                height: 'auto',
                maxHeight: isMobile ? '573px' : '997px',
                padding: '32px 16px',
                flexDirection: 'column',
                alignItems: 'center',
                gap: '16px',
                borderRadius: '8px',
                backgroundColor: '#FFF',
                boxShadow: '2px 0px 4px 0px rgba(0, 0, 0, 0.25), 0px 2px 4px 0px rgba(0, 0, 0, 0.25), 0px -2px 4px 0px rgba(0, 0, 0, 0.25), -2px 0px 4px 0px rgba(0, 0, 0, 0.25)',
                outline: 'none',
            }}>
                <Box sx={{ height: isMobile ? '90px' : '240px', width: isMobile ? '310px' : '800px' }}> {/* Replace with your height logic */}
                    <Box className="user-stats-header-trophy" sx={{ display: 'flex', alignItems: 'center', justifyItems: 'center' }}>
                        <img className="user-stats-trophy" src={bracketTrophy} alt='' />
                        <Typography className="user-stats-username" variant="h1" sx={{ display: 'flex', alignItems: 'center', marginLeft: '5px' }}>
                            {myUsername}
                        </Typography>
                    </Box>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: isMobile ? '220px' : '550px',
                            margin: '0 auto',
                            marginBottom: '12px',
                            gap: '24px',
                        }}>
                        {/* Content for mobile and desktop */}
                        {isMobile ? (
                            <>
                            <Box>
                                <Typography className={"user-stats-headings"} variant='h4'>POINTS</Typography>
                                {/* <Typography className={"user-stats-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonPoints}</Typography> */}
                                <Typography className={"user-stats-values"} variant='h3' sx={{ fontWeight: '800'}}>{totalPoints}</Typography>
                            </Box>
                            <Box>
                                <Typography className={"user-stats-headings"} variant='h4'>SEASON</Typography>
                                {/* <Typography className={"user-stats-values"} variant='h3' sx={{ fontWeight: '800'}}>{seasonWins}-{seasonLosses}-{seasonTies}</Typography> */}
                                <Typography className={"user-stats-values"} variant='h3' sx={{ fontWeight: '800'}}>{totalWins}-{totalLosses}-{totalTies}</Typography>
                            </Box>
                            <Box>
                                {/* <Typography className={"user-stats-headings"} variant='h4'>WEEK {startingWeekState}</Typography>
                                <Typography className={"user-stats-values"} variant='h3' sx={{ fontWeight: '800'}}>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography> */}
                                <Typography className={"user-stats-headings"} variant='h4'>WEEK {startingWeekState}</Typography>
                                <Typography className={"user-stats-values"} variant='h3' sx={{ fontWeight: '800'}}>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography>
                            </Box>
                            </>
                        ) : (
                            <>
                            <Box>
                                <Typography className={"user-stats-headings"} variant='h4'>POINTS</Typography>
                                {/* <Typography className={"user-stats-values"} variant='h4'>{seasonPoints}</Typography> */}
                                <Typography className={"user-stats-values"} variant='h4' sx={{ fontWeight: '500'}}>{totalPoints}</Typography>
                            </Box>
                            <Box>
                                <Typography className={"user-stats-headings"} variant='h4'>SEASON</Typography>
                                {/* <Typography className={"user-stats-values"} variant='h4'>{seasonWins}-{seasonLosses}-{seasonTies}</Typography> */}
                                <Typography className={"user-stats-values"} variant='h4' sx={{ fontWeight: '500'}}>{totalWins}-{totalLosses}-{totalTies}</Typography>
                            </Box>
                            <Box>
                                {/* <Typography className={"user-stats-headings"} variant='h4'> WEEK {startingWeekState}</Typography> */}
                                {/* <Typography className={"user-stats-values"} variant='h4'>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography> */}
                                <Typography className={"user-stats-headings"} variant='h4'> WEEK {startingWeekState}</Typography>
                                <Typography className={"user-stats-values"} variant='h4' sx={{ fontWeight: '500'}}>{weeklyWins}-{weeklyLosses}-{weeklyTies}</Typography>
                            </Box>
                            </>
                        )}
                    </Box>
                    <Box 
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            width: isMobile ? '252px' : '754px',
                            margin: '0 auto',
                            width:'100%',
                            gap: isMobile ? '30px' : '75px',
                        }}>
                        {/* Content for mobile and desktop */}
                        {isMobile ? (
                                <>
                                    <Box>
                                        <Typography className="user-stats-headings" variant='h4'>HOME</Typography>
                                        <Typography className="user-stats-values" variant='h3' sx={{ fontWeight: '800', whiteSpace: 'nowrap'}}>{`${homeWins}-${homeLosses}-${homeTies}`}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="user-stats-headings" variant='h4'>AWAY</Typography>
                                        <Typography className="user-stats-values" variant='h3' sx={{ fontWeight: '800', whiteSpace: 'nowrap'}}>{`${awayWins}-${awayLosses}-${awayTies}`}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="user-stats-headings" variant='h4'>FAVORITES</Typography>
                                        <Typography className="user-stats-values" variant='h3' sx={{ fontWeight: '800'}}>{`${favoriteWins}-${favoriteLosses}-${favoriteTies}`}</Typography>
                                    </Box>
                                    <Box>
                                        <Typography className="user-stats-headings" variant='h4'>UNDERDOGS</Typography>
                                        <Typography className="user-stats-values" variant='h3' sx={{ fontWeight: '800'}}>{`${underdogWins}-${underdogLosses}-${underdogTies}`}</Typography>
                                    </Box>
                                </>
                            ) : (
                                <>
                                    <Box 
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            alignItems: 'center',
                                            width: '100%', // Ensure the container takes full width
                                            maxWidth: '800px', // Optional: Adjust as needed
                                            margin: '0 auto', // Center the container horizontally
                                        }}
                                        >
                                        <Box 
                                            sx={{ 
                                            width: '116px', 
                                            textAlign: 'center' // Center text within each Box
                                            }}
                                        >
                                            <Typography className="user-stats-headings" variant='h4'>HOME</Typography>
                                            <Typography className="user-stats-values" variant='h4' sx={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                                                {`${homeWins}-${homeLosses}-${homeTies}`}
                                            </Typography>
                                        </Box>
                                        <Box 
                                            sx={{ 
                                            width: '116px', 
                                            textAlign: 'center' // Center text within each Box
                                            }}
                                        >
                                            <Typography className="user-stats-headings" variant='h4'>AWAY</Typography>
                                            <Typography className="user-stats-values" variant='h4' sx={{ fontWeight: '500', whiteSpace: 'nowrap' }}>
                                            {`${awayWins}-${awayLosses}-${awayTies}`}
                                            </Typography>
                                        </Box>
                                        <Box 
                                            sx={{ 
                                            width: '116px', 
                                            textAlign: 'center' // Center text within each Box
                                            }}
                                        >
                                            <Typography className="user-stats-headings" variant='h4'>FAVORITES</Typography>
                                            <Typography className="user-stats-values" variant='h4' sx={{ fontWeight: '500' }}>
                                            {`${favoriteWins}-${favoriteLosses}-${favoriteTies}`}
                                            </Typography>
                                        </Box>
                                        <Box 
                                            sx={{ 
                                            width: '116px', 
                                            textAlign: 'center' // Center text within each Box
                                            }}
                                        >
                                            <Typography className="user-stats-headings" variant='h4'>UNDERDOGS</Typography>
                                            <Typography className="user-stats-values" variant='h4' sx={{ fontWeight: '500' }}>
                                            {`${underdogWins}-${underdogLosses}-${underdogTies}`}
                                            </Typography>
                                        </Box>
                                        </Box>

                                </>
                            )}
                    </Box>
                </Box>
                <Box className="pillbox-navigation-bar">
                    <div
                        className={`user-stats-button ${tableState === 'weekly' ? 'active' : 'inactive'}`}
                        onClick={() => handleButtonClick('weekly')}
                    >
                        <Typography className="user-stats-button-text">
                        Weekly Picks
                        </Typography>
                    </div>
                    <div
                        className={`user-stats-button ${tableState === 'season' ? 'active' : 'inactive'}`}
                        onClick={() => handleButtonClick('season')}
                    >
                        <Typography className="user-stats-button-text">
                        Picks by Team
                        </Typography>
                    </div>
                </Box>

                {tableState === 'weekly' ? (
                    <PickEmUserStatslWeklyPicksTable 
                        usersPicks={usersPicks} 
                        startingWeekState={startingWeekState} 
                        NFLTeams={NFLTeams}
                        NFLScoresBySeason={NFLScoresBySeason}
                        areTheseMyPicks={areTheseMyPicks}
                    />
                ) : (
                    <Box>
                        <PickEmUserStatsTeamsTable
                            usersPicks={usersPicks}
                            areTheseMyPicks={areTheseMyPicks}
                            NFLTeams={NFLTeams}    
                    />
                    </Box>
                )}

                <Button variant="contained" onClick={handleClose}>Close</Button>
            </Box>
        </Modal>
        </div>
    );
}

export default PickEmUserStatsModal;
