import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import useNavigateToTop from "../../hooks/general/useNavigateToTop";
import { Typography, Box } from "@mui/material";
import MyBets from "./MyBetsPages/MyBets";

const FantasySportsbookContainer = () => {
 

  return (
    <MyBets />
  );
};

export default FantasySportsbookContainer;
