import React, { createContext, useContext, useEffect, useState } from "react";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import { useGetUserData } from "../../BirdiePool/hooks/index";
import { useGetPrivatePools } from "../../PrivatePools/hooks/useGetPrivatePools";
import { useGetPublicPools } from "../../PublicPools/hooks/useGetPublicPools";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";

const MyPoolsPageContext = createContext();

export const useMyPoolsPageContext = () => {
    const context = useContext(MyPoolsPageContext);
    if (!context) {
        throw new Error("useMyPoolsPageContext must be used within a MyPoolsPageProvider");
    }
    return context;
};

const MyPoolsPageProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { data, error, isLoading, refetch } = useGetPrivatePools();
    const { data: publicPoolsData, publicIsLoading, publicIsError, publicError } = useGetPublicPools();

    const [gamesData, setGamesData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (userData && data && publicPoolsData) {
            const userGameIds = userData.Games?.map(game => game.gameID) || [];
            // Combine both private and public pools data
            const allPools = [...data, ...publicPoolsData];
            const matchingGames = allPools.filter(pool => userGameIds?.includes(pool.gameID)) || publicPoolsData;
            setGamesData(matchingGames);
        }
    }, [userData, data, publicPoolsData]);

    useEffect(() => {
        if (userData && data && publicPoolsData) {
            setLoading(false);
        }
    }, [userData, data, publicPoolsData]);

    // Fetch user data when myUserId is available
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    //console.log("userData", userData);

    const MyPoolsPageContextValues = {
        myUserId,
        userData: userData,
        gamesData: gamesData,
    };

    //console.log("My Pools Page Context Values", MyPoolsPageContextValues);

    if (loading || userLoading || isLoading || publicIsLoading) {
        return(
            <LoadingScreen />
        )
    }

    return (
        <MyPoolsPageContext.Provider value={MyPoolsPageContextValues}>
            {children}
        </MyPoolsPageContext.Provider>
    );
}

export default MyPoolsPageProvider;
