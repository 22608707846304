import React, { createContext, useContext, useState, useEffect } from "react";
import { useGetUserData, useGetGameHook, useCreateSubgroup } from "../../BirdiePool/hooks/index";
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../redux/actions/loginImport";
import useNavigateToTop from "../../../hooks/general/useNavigateToTop";
import { getUserDataFromLocalStorage } from "../../../redux/actions/loginImport";
import LoadingScreen from "../../LoadingScreen/LoadingScreen";
import { useGetPoolLeaderboard } from "../../../hooks/pools/useGetPoolLeaderboard";
import { useNFLOddsByWeek, useNFLTeams } from "../../../hooks/nfl/index";
import { useNFLSportsbookOdds } from "../../../hooks/nfl/index";

const FantasySportsbookContext = createContext();

export const useFantasySportsbookContext = () => {
  const context = useContext(FantasySportsbookContext);
  if (!context) {
    throw new Error("useFantasySportsbookContext must be used within a FantasySportsbookProvider");
  }
  return context;
};

const FantasySportsbookProvider = ({ children }) => {
    const myUserId = getUserDataFromLocalStorage()?.user_id;
    const { mutate: fetchUserData, data: userData, isLoading: userLoading, isError: userError, error: userErrorData } = useGetUserData();
    const { mutate: fetchNFLGameOddsByWeek, data: NFLGameOddsByWeek, isLoading: NFLGameOddsByWeekLoading, isError: NFLGameOddsByWeekError, error: NFLGameOddsByWeekErrorData } = useNFLOddsByWeek();
    const { mutate: fetchNFLSportsbookOdds, data: NFLSportsbookOdds, isLoading: NFLSportsbookOddsLoading, isError: NFLSportsbookOddsError, error: NFLSportsbookOddsErrorData } = useNFLSportsbookOdds();
    const { mutate: fetchNFLTeams, data: NFLTeams, isLoading: NFLTeamsLoading, isError: NFLTeamsError, error: NFLTeamsErrorData } = useNFLTeams();
    
    // Fetch user data first
    useEffect(() => {
        if (myUserId) {
            fetchUserData(myUserId);
        }
    }, [fetchUserData, myUserId]);

    // Fetch NFL Game Odds by Week and Teams
    useEffect(() => {
        fetchNFLGameOddsByWeek();
        fetchNFLTeams();
        fetchNFLSportsbookOdds();
      }, [fetchNFLGameOddsByWeek, fetchNFLTeams, fetchNFLSportsbookOdds]);

    const [pickState, setPickState] = useState([]);
    console.log("BETS PICK STATE", pickState);
    console.log("NFL Game Odds By Week", NFLGameOddsByWeek, "NFL SPortsbook Odds", NFLSportsbookOdds);


    const currentGameInterval = 1; //Make this dynamic from the Game Object.  Use 1 as the default for one segement games.
  
    

  const FantasySportsbookContextValues = {
    myUserId,
    pickState,
    setPickState,
    NFLGameOddsByWeek,
    NFLSportsbookOdds,
    NFLTeams,
    currentGameInterval,
  };
  console.log("Fantasy Sportsbook Context Values", FantasySportsbookContextValues);

  // Render loading screen while fetching data
 
  if (NFLGameOddsByWeekLoading || NFLTeamsLoading || userLoading || NFLSportsbookOddsLoading) {
    return <LoadingScreen />;
  }

  return (
    <FantasySportsbookContext.Provider value={FantasySportsbookContextValues}>
      {children}
    </FantasySportsbookContext.Provider>
  );
};

export default FantasySportsbookProvider;